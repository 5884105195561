import React, { FC } from 'react';

import PickSlider from 'components/Sliders/PickSlider';
import UserSlider from 'components/Sliders/UserSlider';
import SparkSlider from 'components/Sliders/SparksSlider';
import Card from 'components/Card';
import GuestSection from 'components/Explore/GuestSection/GuestSection';

import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';

import {
  CardSchema,
  EDiscoverItemLayoutOptions,
  EGuestListEpisodeSlotTypeOptions,
  GuestListSectionSchema,
} from 'constants/graphqlTypes';
import { EFeaturedFlags } from 'types/EFeatureFlags';

import { StyledCard, StyledSliderWrap, StyledTitle } from './styled';

const { OneCard, Carousel, UserCards, GuestLists, Sparks } = EGuestListEpisodeSlotTypeOptions;

const Section: FC<{ section: GuestListSectionSchema; isDark: boolean }> = ({ section, isDark }) => {
  const isViewSparks = useFeaturedFlagEnabled(EFeaturedFlags.ViewSparks);
  const { title, cards, type } = section;

  const items =
    cards?.items.reduce((acc: CardSchema[], { card }) => (card ? acc.concat({ ...card, createdAt: null }) : acc), []) ??
    [];

  const card = items[0];

  return (
    <>
      {!!items.length && (
        <section>
          {title && (type !== Sparks || isViewSparks) && <StyledTitle>{title}</StyledTitle>}
          {type === OneCard && card && (
            <StyledCard>
              <Card card={card} layout={EDiscoverItemLayoutOptions.FirstUp} isDark={isDark} />
            </StyledCard>
          )}
          {type === Carousel && <PickSlider isDark={isDark} items={items} />}
          {type === UserCards && <UserSlider isDark={isDark} items={items} />}
          {type === GuestLists && (
            <StyledSliderWrap hasOneItem={items.length === 1}>
              <GuestSection
                title="More guest:lists"
                description="Inspiring guests share their universe of passions"
                cards={items}
              />
            </StyledSliderWrap>
          )}
          {type === Sparks && isViewSparks && <SparkSlider isDark={isDark} items={items.slice(0, 4)} />}
        </section>
      )}
    </>
  );
};

export default Section;
