import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { useUpdateListTitle } from 'graphQL/list/update/hooks';
import { transformRoute } from 'helpers/routingHelper';
import getKeywordsFromTitle from 'helpers/getKeywordsFromTitle';
import EditTextFieldModal from 'components/EditTextFieldModal';
import { ROUTE_LIST_EDIT } from 'routes';
import { LIST_NAME_LENGTH } from 'constants/common';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';

const EditListTitle: FC<{ navigateToEditList: () => void }> = ({ navigateToEditList }) => {
  const { track } = useAnalytics();

  const { listId } = useParams<{ listId: string }>();
  const { state } = useLocation<{ variable: { listCardId: string; title: string } }>();
  const { listCardId, title } = state?.variable ?? {};
  const { replace } = useHistory();
  const [titleText, setTitleText] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setTitleText(title ?? '');
  }, [title]);

  const onCompleted = () => {
    replace({
      pathname: transformRoute(ROUTE_LIST_EDIT, { listId }),
      state: {
        ...state,
        variable: { ...state?.variable, title: titleText.trim() },
      },
    });
    track(ANALYTICS_EVENTS.EditList);
    track(ANALYTICS_EVENTS.EditLIstName);

    const keywords = getKeywordsFromTitle(titleText);
    keywords.split(' ').forEach((keyword) => {
      track(ANALYTICS_EVENTS.ListNameKeyword, {
        keyword,
      });
    });
  };
  const { updateTitle, loading, titleError } = useUpdateListTitle(listId, listCardId, titleText, onCompleted);

  useEffect(() => {
    if (titleError) {
      setError(titleError);
    }
  }, [titleError]);

  return (
    <EditTextFieldModal
      text={titleText}
      setText={setTitleText}
      label="List name"
      maxLength={LIST_NAME_LENGTH}
      isSubmitDisabled={!titleText}
      handleClose={navigateToEditList}
      handleDone={() => titleText && updateTitle()}
      error={error}
      loading={loading}
      setError={setError}
      autoCapitalize={EAutoCapitalize.On}
    />
  );
};

export default EditListTitle;
