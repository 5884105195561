import React, { FC, Fragment } from 'react';
import { Waypoint } from 'react-waypoint';

import { getExploreSectionDataTestId, TEST_ID_FEATURED_START } from 'constants/aqa/explore';
import { EDiscoverSlotTypeOptions } from 'constants/graphqlTypes';
import { EFeaturedFlags } from 'types/EFeatureFlags';
import { EUiButtonType } from 'components/UI/Button/constants';

import Button from 'components/UI/Button';
import LoaderContent from 'components/UI/LoaderContent/LoaderContent';
import OnboardingAlert from 'components/UI/OnboardingAlert';
import InviteBlock from 'components/InviteBlock';
import Collection from 'components/Collection';
import UserCard from 'components/Card/UserCard';
import Card from 'components/Card';
import UniversalSlider from 'components/Sliders/UniversalSlider';

import useTheme from 'helpers/useTheme';
import getAuthUser from 'helpers/getAuthUser';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';

import { useExploreItems } from 'graphQL/cards/exploreCards/hooks';
import { StyledBlockWrap, StyledContent, StyledFeedbackWrap, StyledGuestWrap, StyledSection } from './styled';

import Item from './Item';
import Footer from './Footer';
import Heading from './Heading';
import AboutUsSection from './AboutUs';
import GuestSection from './GuestSection';
import DateSection from './DateSection';
import EditorialSection from './EditorialSection';
import TopicList from '../TopicsList/TopicList';
import SparkSection from './SparkSection';
import DynamicListSection from './DynamicList';
import ListSection from './ListSection';

const {
  AboutUs,
  Auto,
  Manual,
  GuestListSeries,
  DateSection: SectionDate,
  DynamicList,
  EditorialNote,
  SuggestedTopics,
  SuggestedUsers,
  Carousel,
  Spark,
  List,
  FeaturedLists,
} = EDiscoverSlotTypeOptions;

const ExploreTab: FC = () => {
  const isViewSparks = useFeaturedFlagEnabled(EFeaturedFlags.ViewSparks);
  const isDark = useTheme();
  const { username } = getAuthUser();

  const { sections, loading, total, onWaypointEnter } = useExploreItems();

  return (
    <>
      {sections.map((section, index) => {
        return (
          <Fragment key={section.id}>
            {(sections.length === 1 || index === sections.length - 4 || index === sections.length - 1) && (
              <Waypoint onEnter={onWaypointEnter} />
            )}
            {!((section.type === Auto || section.type === Manual) && !section.cardItems?.length) && (
              <StyledSection
                data-testid={section.type && getExploreSectionDataTestId(section.type, 'section')}
                key={section.id}
              >
                {section.type === GuestListSeries && (
                  <StyledGuestWrap hasOneItem={section.cardItems?.length === 1}>
                    <GuestSection
                      title={section.title ?? ''}
                      description={section.annotation ?? ''}
                      cards={section.cardItems?.map(({ card }) => card) ?? []}
                    />
                  </StyledGuestWrap>
                )}
                {section.type === Spark && isViewSparks && <SparkSection section={section} />}
                {section.type === AboutUs && <AboutUsSection />}
                {section.type === SectionDate && <DateSection section={section} />}
                {section.type === EditorialNote && <EditorialSection section={section} />}
                {section.type === SuggestedTopics && section.suggestedTopics && (
                  <TopicList topicListId={section.id} tags={section.suggestedTopics} title={section.title} />
                )}
                {section.type === DynamicList && <DynamicListSection section={section} isDark={isDark} />}
                {(section.type === Auto || section.type === Manual) && (
                  <>
                    <Heading section={section} />
                    <StyledContent>
                      {section.cardItems?.map(
                        (item) =>
                          item && (
                            <Item
                              dataTestId={item.layout ? getExploreSectionDataTestId(item.layout, 'pick-item') : ''}
                              key={item?.card.id}
                              item={item}
                            />
                          )
                      )}
                    </StyledContent>
                  </>
                )}
                {section.type === List && section.cardItems?.[0]?.card && (
                  <ListSection key={section?.cardItems?.[0]?.card.id} item={section?.cardItems?.[0]?.card} />
                )}
                {section.type === SuggestedUsers && section.cardItems && (
                  <UniversalSlider
                    title={section.title}
                    description={section.annotation}
                    items={section.cardItems.map((item) => ({ ...item, id: item.card.id }))}
                    isInfinite
                    itemRender={({ card }, isCurrent, imagesKey) =>
                      card.user && (
                        <UserCard
                          item={card.user}
                          enableOldBehaviour={false}
                          isFullHeight
                          imagesKey={imagesKey}
                          isCurrent={isCurrent}
                          hasAnimatedImages
                        />
                      )
                    }
                  />
                )}
                {section.type === FeaturedLists && section.cardItems && (
                  <UniversalSlider
                    title={section.title}
                    description={section.annotation}
                    items={section.cardItems.map((item) => ({ ...item, id: item.card.id }))}
                    itemRender={({ card }) => (
                      <Collection
                        showCreators
                        showListDescription
                        hidePrivateLabel
                        hideCollaborators
                        hasSmallTitle
                        item={card}
                      />
                    )}
                  />
                )}
                {section.type === Carousel && section.cardItems && (
                  <UniversalSlider
                    title={section.title}
                    description={section.annotation}
                    items={section.cardItems.map((item) => ({ ...item, id: item.card.id }))}
                    slidesPerView={2.25}
                    itemRender={({ card, highlight }) => (
                      <Card isDark={isDark} card={card} highlight={highlight} showActivity />
                    )}
                  />
                )}
              </StyledSection>
            )}
            {index === sections.length - 1 && <Waypoint onEnter={onWaypointEnter} />}
          </Fragment>
        );
      })}
      {loading && <LoaderContent isFullScreen={!sections.length} isDark={isDark} />}
      {!loading && sections.length === total && (
        <>
          <StyledSection>
            <StyledFeedbackWrap>
              <OnboardingAlert
                title="Are you enjoying the featured picks?"
                isTransparent
                titleSize={22}
                isDark={isDark}
              >
                We would love your thoughts so we can make guide:human even better.
                <Button
                  dataTestId={`${TEST_ID_FEATURED_START}_send-feedback-button`}
                  handler={() => {
                    window.location.href = `mailto:hello@guidehuman.co?subject=User feedback :${username}`;
                  }}
                  text="Send us feedback"
                  type={EUiButtonType.Primary}
                  isDark={isDark}
                />
              </OnboardingAlert>
            </StyledFeedbackWrap>
          </StyledSection>
          <Footer />
          <StyledBlockWrap>
            <InviteBlock isDark={isDark} />
          </StyledBlockWrap>
        </>
      )}
    </>
  );
};

export default ExploreTab;
