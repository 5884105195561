const {
  IS_PRODUCTION,
  REACT_APP_API_BASE_URL,
  REACT_APP_API_URL,
  REACT_MESSAGES_API_URL,
  REACT_SUBSCRIPTION_URL,
  REACT_APP_APPLE_CLIENT_ID,
  REACT_APP_APPLE_REDIRECT_URL,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_BASE_URL,
  REACT_APP_PUBLIC_POSTHOG_KEY,
  REACT_APP_PUBLIC_POSTHOG_HOST,
  REACT_APP_JOIN_US_BASE_URL,
  REACT_APP_DISABLE_INVITE_CODE,
  REACT_APP_LANDING_GSCRIPT_API,
  REACT_APP_MAILCHIMP_URL,
  REACT_APP_ENABLE_NEW_ONBOARDING,
  REACT_APP_IS_LOCAL,
  REACT_APP_SAFARI_EXT_BUNDLE_ID,
  REACT_APP_SAFARI_EXT_APPLE_TEAM_ID,
  REACT_APP_CHROME_EXT_ID,
  REACT_APP_ANALYTICS_PROVIDER,
  REACT_APP_RUDDERSTACK_WRITE_KEY,
  REACT_APP_RUDDERSTACK_DATA_PLANE_URL,
} = process.env;

export default {
  baseApiUrl: REACT_APP_API_BASE_URL,
  apiUrl: REACT_APP_API_URL || '',
  messagesApiURL: REACT_MESSAGES_API_URL || '',
  subscriptionApiUrl: REACT_SUBSCRIPTION_URL || '',
  appleClientId: REACT_APP_APPLE_CLIENT_ID || '',
  appleRedirectUrl: REACT_APP_APPLE_REDIRECT_URL || '',
  isProd: IS_PRODUCTION,
  googleClientId: REACT_APP_GOOGLE_CLIENT_ID || '',
  appBaseUrl: REACT_APP_BASE_URL || '',
  postHogKey: REACT_APP_PUBLIC_POSTHOG_KEY || '',
  postHogHost: REACT_APP_PUBLIC_POSTHOG_HOST || '',
  joinUsBaseUrl: REACT_APP_JOIN_US_BASE_URL || '',
  landingGScriptApi: REACT_APP_LANDING_GSCRIPT_API || '',
  mailchimpUrl: REACT_APP_MAILCHIMP_URL || '',
  areInviteCodesDisabled: REACT_APP_DISABLE_INVITE_CODE === 'true',
  isNewOnboardingEnabled: REACT_APP_ENABLE_NEW_ONBOARDING,
  isLocalEnv: REACT_APP_IS_LOCAL,
  safariExtBundleId: REACT_APP_SAFARI_EXT_BUNDLE_ID || '',
  safariAppleTeamId: REACT_APP_SAFARI_EXT_APPLE_TEAM_ID || '',
  chromeExtId: REACT_APP_CHROME_EXT_ID || '',
  analyticsProvider: REACT_APP_ANALYTICS_PROVIDER || '',
  rudderstackWriteKey: REACT_APP_RUDDERSTACK_WRITE_KEY || '',
  rudderstackDataPlaneUrl: REACT_APP_RUDDERSTACK_DATA_PLANE_URL || '',
};
