import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ROUTE_ABOUT, ROUTE_TERMS, ROUTE_PRIVACY } from 'routes';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';

import {
  StyledFooter,
  StyledLogo,
  StyledMainInfoContainer,
  StyledListsContainer,
  StyledListTitle,
  StyledCopyrightText,
} from './styled';

const Footer: FC = () => {
  const { track } = useAnalytics();

  return (
    <StyledFooter>
      <StyledMainInfoContainer>
        <StyledLogo src="https://ivysaur-b-gql-image.s3.amazonaws.com/logo_white_svg.svg" alt="Logo" />
        <StyledListsContainer>
          <div>
            <StyledListTitle>Company</StyledListTitle>
            <Link to={`${ROUTE_ABOUT}?welcome=true`} onClick={() => track(ANALYTICS_EVENTS.LandingAboutUs)}>
              About Us
            </Link>
            <a
              href="https://apps.apple.com/us/app/guide-human/id1570806527"
              onClick={() => track(ANALYTICS_EVENTS.LandingDownloadApp)}
            >
              Download the App
            </a>
          </div>
          <div>
            <StyledListTitle>Information</StyledListTitle>
            <a href="mailto:hello@guidehuman.co" onClick={() => track(ANALYTICS_EVENTS.LandingContactUs)}>
              Contact Us
            </a>
            <Link to={ROUTE_TERMS} onClick={() => track(ANALYTICS_EVENTS.LandingTS)}>
              Terms of Service
            </Link>
            <Link to={ROUTE_PRIVACY} onClick={() => track(ANALYTICS_EVENTS.LandingPP)}>
              Privacy Policy
            </Link>
          </div>
        </StyledListsContainer>
      </StyledMainInfoContainer>
      <StyledCopyrightText>© 2024, Guide Human Inc. All Rights Reserved.</StyledCopyrightText>
    </StyledFooter>
  );
};

export default Footer;
