import React, { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { EQueryFetchPolicy } from 'constants/common';
import { CardStyleSchema, ETutorialStatus } from 'constants/graphqlTypes';
import { TEST_LEAVE_THOUGHT_INPUT } from 'constants/aqa/addPick';

import getAuthUser from 'helpers/getAuthUser';
import getValidationError from 'helpers/getValidationError';
import useDisableScroll from 'helpers/useDisableScroll';
import useGuideTour from 'helpers/useGuideTour';
import { isIos } from 'helpers/useUserAgent';

import useGetExtractionCard from 'graphQL/addContent/addStyle/hook';
import { useProfile } from 'graphQL/profile/hooks';
import { useGetComments, useThought } from 'graphQL/card/comments/hooks';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { useValidateThought } from 'graphQL/hiddenWords/hooks';
import { useUpdateUserSettings, useUserSettings } from 'graphQL/contextualIntro/hooks';

import Avatar from 'components/UI/Avatar';
import Card from 'components/Card';
import Modal from 'components/Modal';
import TextInput from 'components/UI/TextInput';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import { SHARE_THOUGHT } from 'components/GuideTour/constants';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import Highlighter from 'components/Highlighter';
import OnboardingAlert from 'components/UI/OnboardingAlert';

import { useAddContentLinks, useFinishAddContent } from '../helpers/hooks';
import { IAddContentLocation, ListRoutingParams } from '../helpers/models';
import { StyledPreview } from '../AddStyle/styled';
import { StyledDrag, StyledFooter, StyledComment, StyledCardWrap } from './styled';
import { getThoughtSubmitText } from './helpers/helpers';

interface IAddThoughtProps {
  handleClose: () => void;
  cardId: string;
  pickId: string;
  cardStyle?: CardStyleSchema;
  listCardId?: string;
  listTitle: string;
  shouldRedirectToList?: boolean;
  listRoutingParams?: ListRoutingParams;
}

const SHARE_FIRST_THOUGHT = 'share-first-thought';

const AddThought: FC<IAddThoughtProps> = ({
  handleClose,
  cardId,
  pickId,
  cardStyle,
  listCardId,
  listTitle,
  shouldRedirectToList,
  listRoutingParams,
}) => {
  const [thought, setThought] = useState('');
  const [error, setError] = useState('');
  const [hasHiddenWords, setHasHiddenWords] = useState(false);
  const [isDoneBtnLoading, setIsDoneButtonLoading] = useState(false);

  const { userId } = getAuthUser();
  const { state } = useLocation<IAddContentLocation | undefined>();
  const { variable: stateData, isSingleModal } = state ?? {};
  const {
    isPickFlow,
    isMovePick,
    isDetail,
    listId: stateListId,
    moveListTitle,
    moveListCardId,
    tourListTitle,
    disabled,
    isSavedPick,
  } = stateData ?? {};
  const id = stateData?.pickId ?? pickId;
  const { addStyle, addToList, home } = useAddContentLinks();
  const { replace } = useHistory();
  const { card: extractionCard, loading: cardLoading, isQueryCalled } = useGetExtractionCard(id, !!isDetail);
  const { card: detailCard } = useGetDetailCard(id, null, !isDetail);
  const card = extractionCard || detailCard;
  const { userData } = useProfile(undefined, EQueryFetchPolicy.CacheOnly);
  const { comments } = useGetComments(id, { referrerUserId: userId });
  const myThought = comments?.items[0];
  const { text, id: thoughtId } = myThought ?? {};
  const refetch = useValidateThought();

  const { isGuideTour } = useGuideTour();
  const { isThoughtStepCompleted, loading: settingLoading, tutorialStatus } = useUserSettings();
  const { updateUserSettings } = useUpdateUserSettings({
    isThoughtStepCompleted: true,
  });

  const isDisplaySheet =
    !isGuideTour &&
    !settingLoading &&
    !isThoughtStepCompleted &&
    !cardLoading &&
    tutorialStatus !== ETutorialStatus.NotStarted;

  useEffect(() => {
    if (text) {
      setThought(text);
    }
  }, [text]);

  useEffect(() => {
    if (!cardLoading && !card && isQueryCalled) {
      replace(home);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardLoading, card]);

  const { handleFinish, loading } = useFinishAddContent({
    pickId: stateData?.pickId ?? pickId,
    cardId: stateData?.cardId ?? cardId,
    thought,
    thoughtId,
    isUpdateThought: !!thoughtId,
    listTitle: moveListTitle ?? listTitle,
    close: handleClose,
    listCardId: moveListCardId ?? listCardId,
    item: card && { ...card, ...(cardStyle && { cardStyle }) },
    onLockedError: () => setIsDoneButtonLoading(false),
    shouldRedirectToList,
    listRouteId: listRoutingParams?.id,
    isPrivateList: !!listRoutingParams?.isPrivateList,
    isSavedByMe: card?.isSavedByMe,
  });

  const { handleAddThought, update, loading: thoughtLoading } = useThought({
    commentId: thoughtId,
    text: thought,
    pickId: stateData?.pickId ?? pickId,
    refListId: stateListId,
    onLockedError: () => setIsDoneButtonLoading(false),
    currentUserPickId: extractionCard?.id,
  });
  const isLoading = loading || cardLoading || thoughtLoading;

  useEffect(() => {
    setError('');
  }, [thought]);

  const handleThought = () => {
    if (thoughtId && text !== thought) {
      update()
        .then(({ errors }) => !errors && handleClose())
        .catch((e) => setError(getValidationError(e)));
      return;
    }

    if (thought && !thoughtId) {
      handleAddThought()
        .then(({ errors }) => !errors && handleClose())
        .catch((e) => setError(getValidationError(e)));
      return;
    }
    handleClose();
  };

  const handlePrev = () => {
    if (isSavedPick) {
      replace(addStyle);
    } else {
      replace(isPickFlow || isDetail ? addToList : addStyle);
    }
  };

  useDisableScroll(!isIos());

  const validateThought = () => {
    refetch(thought).then((res) => {
      if (res && res?.data?.validateThought?.isSuspicious) {
        setHasHiddenWords(true);
      } else if (isSingleModal && !moveListTitle) {
        handleThought();
      } else {
        handleFinish();
      }
    });
  };

  const handleDone = () => {
    validateThought();
    setIsDoneButtonLoading(true);
  };

  const confirmationHandler = () => {
    setHasHiddenWords(false);
    setIsDoneButtonLoading(false);
  };

  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  return (
    <Modal
      fadeIn={!!(isPickFlow && isSingleModal)}
      handleClose={() => !isLoading && handleClose()}
      secondaryText={!isSingleModal && !moveListTitle ? listTitle || tourListTitle : undefined}
      secondaryHandler={() => replace(addToList)}
      handleDone={handleDone}
      handlePrev={!isSingleModal ? handlePrev : undefined}
      buttonText={getThoughtSubmitText(stateData, card)}
      loading={isLoading || isDoneBtnLoading}
      isExitConfirmed={isMovePick ? undefined : isExitConfirmed}
      setShowConfirm={isMovePick ? undefined : setShowExitWarning}
    >
      <StyledPreview bgColor={cardStyle?.bgColor?.colorValue ?? card?.cardStyle?.bgColor?.colorValue}>
        <StyledCardWrap>
          {card && <Card card={{ ...card, ...(cardStyle && { cardStyle }), isSavedByMe: false }} disableLinks />}
        </StyledCardWrap>
      </StyledPreview>
      <StyledFooter data-tour={SHARE_THOUGHT}>
        <StyledDrag />
        <StyledComment data-tour={SHARE_FIRST_THOUGHT}>
          {userData && <Avatar user={userData} />}
          <TextInput
            dataTestId={TEST_LEAVE_THOUGHT_INPUT}
            disabled={!!disabled}
            inputHeight={80}
            placeholder="Add your thought"
            setText={(value: string) => setThought(value)}
            text={thought}
            type="thought"
            error={error}
            isMultiLine
            autoCapitalize={EAutoCapitalize.On}
          />
        </StyledComment>
      </StyledFooter>
      {hasHiddenWords && (
        <ConfirmationModal
          handleCancel={isSingleModal && !moveListTitle ? handleThought : handleFinish}
          handleAccept={confirmationHandler}
          handleClickOutside={confirmationHandler}
          subTitle="Help us create a respectful community by double-checking your thought for possible sensitive or harmful language."
          cancelButtonText="Post anyway"
          buttonText="Revise"
          titleText="Are you ready to share?"
          isWarning={false}
        />
      )}
      {showExitWarning && (
        <ConfirmationModal
          handleCancel={() => setShowExitWarning(false)}
          handleClickOutside={() => setShowExitWarning(false)}
          handleAccept={() => setIsExitConfirmed(true)}
          isWarning
          buttonText="Discard"
          titleText="Discard pick?"
          subTitle="Your edits and thoughts will be discarded if you leave before posting."
        />
      )}
      {isDisplaySheet && (
        <Highlighter
          id={SHARE_FIRST_THOUGHT}
          borderRadius="10px"
          highlighterOffset={10}
          elementOffset={18}
          highlight
          handleClose={updateUserSettings}
        >
          <OnboardingAlert title="Share your thoughts" submitText="Got it" handleSubmit={updateUserSettings}>
            Your perspective is important. It brings meaning and engages curiosity in others. Share what’s stirring
            inside.
          </OnboardingAlert>
        </Highlighter>
      )}
    </Modal>
  );
};

export default AddThought;
