import React, { FC, useEffect, useRef } from 'react';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';

import TopBlock from 'components/Landing/TopBlock';
import WeeklyPicks from 'components/Landing/WeeklyPicks';
import MiddleBlock from 'components/Landing/MiddleBlock';
import SubscribeForm from 'components/Landing/SubscribeForm';
import Connect from 'components/Landing/Connect';
import Footer from 'components/Landing/Footer';

import { StyledWrapper, StyledHeader, StyledHeaderButton, StyledHeaderText } from './styled';

const LandingPage: FC = () => {
  const formRef = useRef<HTMLDivElement>(null);
  const { track } = useAnalytics();

  const scrollToForm = () => {
    if (formRef?.current) {
      formRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  const handleJoinButton = () => {
    track(ANALYTICS_EVENTS.LandingJoinButton);
    scrollToForm();
  };

  useEffect(() => {
    track(ANALYTICS_EVENTS.LandingOpen);
    track(ANALYTICS_EVENTS.LandingOpenFrom, {
      referrer: document.referrer,
    });
  }, [track]);

  return (
    <StyledWrapper>
      <StyledHeader>
        <StyledHeaderText>We’re in Beta. Join our waitlist now.</StyledHeaderText>
        <StyledHeaderButton onClick={handleJoinButton}>Join</StyledHeaderButton>
      </StyledHeader>
      <TopBlock handleGetStarted={scrollToForm} />
      <WeeklyPicks />
      <MiddleBlock />
      <Connect />
      <div ref={formRef}>
        <SubscribeForm />
      </div>
      <Footer />
    </StyledWrapper>
  );
};

export default LandingPage;
