import React, { FC } from 'react';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from 'react-share';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS, EInviteType } from 'constants/analyticsEvents';
import useToast from 'helpers/useToast';
import { isIosOrMacOs, isWindows } from 'helpers/useUserAgent';

import IconCircle from 'components/UI/IconCircle/IconCircle';
import { ReactComponent as MessagesIcon } from 'assets/icons/sharing/Messages.svg';
import { ReactComponent as WhatsappIcon } from 'assets/icons/sharing/Whatsapp.svg';
import { ReactComponent as MailIcon } from 'assets/icons/sharing/Mail.svg';
import { ReactComponent as TweetIcon } from 'assets/icons/sharing/X.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/sharing/Facebook.svg';
import { ReactComponent as LinkedinIcon } from 'assets/icons/sharing/Linkedin.svg';
import { ReactComponent as PickFromLinkIcon } from 'assets/icons/PickFromLink.svg';

import { StyledListItem, StyledShareButtons, StyledShareWrap } from './styled';
import { ESharingContentType } from '../constants';

const SharingList: FC<{ url: string; contentType?: ESharingContentType }> = ({ url, contentType }) => {
  const { track } = useAnalytics();

  const { setToast } = useToast();

  const copyLink = () =>
    navigator.clipboard?.writeText(url).then(() =>
      setToast({
        toastItemName: `Link copied to clipboard`,
        isToastOpen: true,
      })
    );

  const encodedUrl = encodeURIComponent(url);

  const handlerShare = (key: string) => {
    if (contentType) {
      track(ANALYTICS_EVENTS[`OffplatformShare${contentType}`], { key });
      track(ANALYTICS_EVENTS.OffplatformShare);
    } else {
      track(ANALYTICS_EVENTS.SocialMediaSendLinkNum, { key });
      track(ANALYTICS_EVENTS.SocialMediaSendLinkAll);
    }
  };

  return (
    <StyledShareButtons>
      <StyledListItem onClick={copyLink}>
        <StyledShareWrap>
          <IconCircle Icon={PickFromLinkIcon} isDark hasBorder />
          <span>Copy link</span>
        </StyledShareWrap>
      </StyledListItem>
      {!isWindows() && (
        <StyledListItem onClick={() => handlerShare(EInviteType.Message)}>
          <a href={`sms:${isIosOrMacOs() ? '&' : '?'}body=${encodedUrl}`}>
            <StyledShareWrap>
              <MessagesIcon />
              <span>Messages</span>
            </StyledShareWrap>
          </a>
        </StyledListItem>
      )}
      <StyledListItem onClick={() => handlerShare(EInviteType.Whatsapp)}>
        <WhatsappShareButton url={url}>
          <StyledShareWrap>
            <WhatsappIcon />
            <span>Whatsapp</span>
          </StyledShareWrap>
        </WhatsappShareButton>
      </StyledListItem>
      <StyledListItem onClick={() => handlerShare(EInviteType.Mail)}>
        <EmailShareButton url={url}>
          <StyledShareWrap>
            <MailIcon />
            <span>Mail</span>
          </StyledShareWrap>
        </EmailShareButton>
      </StyledListItem>
      <StyledListItem onClick={() => handlerShare(EInviteType.X)}>
        <TwitterShareButton url={url}>
          <StyledShareWrap>
            <TweetIcon />
            <span>X</span>
          </StyledShareWrap>
        </TwitterShareButton>
      </StyledListItem>
      <StyledListItem onClick={() => handlerShare(EInviteType.Facebook)}>
        {/* contentType === undefined for invite-friends type */}
        <FacebookShareButton url={contentType ? url : `${url}?redirect=False`}>
          <StyledShareWrap>
            <FacebookIcon />
            <span>Facebook</span>
          </StyledShareWrap>
        </FacebookShareButton>
      </StyledListItem>
      <StyledListItem onClick={() => handlerShare(EInviteType.Linkedin)}>
        <LinkedinShareButton url={contentType ? url : `${url}?redirect=False`}>
          <StyledShareWrap>
            <LinkedinIcon />
            <span>LinkedIn</span>
          </StyledShareWrap>
        </LinkedinShareButton>
      </StyledListItem>
    </StyledShareButtons>
  );
};

export default SharingList;
