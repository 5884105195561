import React, { FC, useState } from 'react';

import { CardSchema } from 'constants/graphqlTypes';
import { EFeaturedFlags } from 'types/EFeatureFlags';
import UILink from 'components/UI/Link/Link';
import { ROUTE_LIST_DETAIL, ROUTE_QUESTION_DETAIL, ROUTE_SPARK_DETAIL, ROUTE_USER_ALL_PICKS } from 'routes';
import usePageType from 'helpers/usePageType';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';
import ConnectedModal from '../ConnectedModal';

import { StyledInfo, StyledTextBtn } from './styled';
import { getAtrributionsNumber } from './helpers';

const ThoughtLastUpdate: FC<{
  lastUpdate: string;
  username: string;
  parentCard?: CardSchema | null;
  responseToQuestion?: CardSchema | null;
  responseToSpark?: CardSchema | null;
  guestListQuestion?: CardSchema | null;
}> = ({ lastUpdate, username, parentCard, responseToQuestion, responseToSpark, guestListQuestion }) => {
  const isViewSparks = useFeaturedFlagEnabled(EFeaturedFlags.ViewSparks);
  const [isConnectedOpen, setConnectedOpen] = useState(false);

  const { id: listId, title: listTitle } = parentCard ?? {};

  const { isThoughtsPage } = usePageType();

  const sparkResponceId = isThoughtsPage ? responseToSpark?.id : undefined;
  const questionResponseId = responseToQuestion?.id;
  const guestListQuestionId = guestListQuestion?.id;

  const attributionNumber = getAtrributionsNumber([sparkResponceId, questionResponseId, guestListQuestionId]);
  const showMoreAttributions = attributionNumber > 1;

  return (
    <>
      <StyledInfo>
        {lastUpdate}
        {!!listTitle && (
          <>
            &nbsp;&middot;&nbsp;in&nbsp;
            <UILink route={ROUTE_LIST_DETAIL} params={{ listId }}>
              {listTitle}
            </UILink>
          </>
        )}
        {!listTitle && (
          <>
            &nbsp;&middot;&nbsp;in&nbsp;
            <UILink route={ROUTE_USER_ALL_PICKS} params={{ profileName: username }}>
              All picks
            </UILink>
          </>
        )}
        {showMoreAttributions && (
          <>
            &nbsp;and&nbsp;
            <StyledTextBtn onClick={() => setConnectedOpen(true)}>{attributionNumber} more</StyledTextBtn>
          </>
        )}
        {questionResponseId && !showMoreAttributions && (
          <>
            &nbsp;and&nbsp;
            <UILink route={ROUTE_QUESTION_DETAIL} params={{ questionId: questionResponseId }}>
              Question of the Day
            </UILink>
          </>
        )}
        {guestListQuestionId && !showMoreAttributions && (
          <>
            &nbsp;and&nbsp;
            <UILink route={ROUTE_QUESTION_DETAIL} params={{ questionId: guestListQuestionId }}>
              The guest:list
            </UILink>
          </>
        )}
        {sparkResponceId && !showMoreAttributions && isViewSparks && (
          <>
            &nbsp;and&nbsp;
            <UILink route={ROUTE_SPARK_DETAIL} params={{ sparkId: sparkResponceId }}>
              Sparks
            </UILink>
          </>
        )}
      </StyledInfo>
      {isConnectedOpen && (
        <ConnectedModal
          handleClose={() => setConnectedOpen(false)}
          responseToQuestion={responseToQuestion}
          guestListQuestion={guestListQuestion}
          responseToSpark={responseToSpark}
        />
      )}
    </>
  );
};

export default ThoughtLastUpdate;
