import { RudderAnalytics } from '@rudderstack/analytics-js/*';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import AnalyticsData from 'types/AnalyticsData';
import AnalyticsProvider from 'types/AnalyticsProvider';

export default class RudderStackProvider implements AnalyticsProvider {
  private initialized = false;

  private analyticsInstance: RudderAnalytics;

  private userId?: string;

  private username?: string;

  private email?: string;

  constructor(analyticsInstance: RudderAnalytics) {
    this.analyticsInstance = analyticsInstance;
  }

  initialize(writeKey: string, dataPlaneUrl: string, userId?: string, username?: string, email?: string): void {
    this.userId = userId;
    this.username = username;
    this.email = email;

    this.analyticsInstance.load(writeKey, dataPlaneUrl, {
      onLoaded: () => {
        this.initialized = true;
        this.identify();
        this.track(...ANALYTICS_EVENTS.AppStart);
      },
    });
  }

  identify(): void {
    if (this.userId && this.username && this.initialized) {
      try {
        this.analyticsInstance.identify(this.userId, {
          id: this.userId,
          username: this.username,
          email: this.email,
        });
      } catch (error) {
        setTimeout(() => this.identify(), 3000);
      }
    }
  }

  track(eventName: string, data?: AnalyticsData): void {
    try {
      this.analyticsInstance.track(eventName, data);
    } catch (error) {
      // Retry the track call after a delay
      setTimeout(() => this.track(eventName, data), 3000);
    }
  }

  reset(): void {
    this.analyticsInstance.reset();
  }

  cleanup(): void {
    this.initialized = false;
  }
}
