import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAnalytics } from 'contexts/AnalyticsContext';
import useToast from 'helpers/useToast';
import useContextPusher from 'helpers/useContextPusher';
import {
  AuthTokenQuery,
  OauthTokenTypes,
  useAuthTokenLazyQuery,
  useCheckInviteCodeMutation,
  useCheckUsernameAvailableQuery,
} from 'constants/graphqlTypes';
import { ROUTE_DISCOVERY, ROUTE_WELCOME, ROUTE_ONBOARDING } from 'routes';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { GQLErrorWithStatusCode } from 'helpers/errorHandler';
import { EQueryFetchPolicy, OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY } from 'constants/common';
import { EMessagePrefixes, sendMessageToExtension } from 'helpers/browserExtension';
import { useGetSearchParam } from '../../helpers/routingHelper';

export const useProceedLogin = (): ((query: AuthTokenQuery) => void) => {
  const { startPusher } = useContextPusher();
  const history = useHistory();

  return ({ authToken }: AuthTokenQuery) => {
    localStorage.setItem('userData', JSON.stringify({ authToken }));
    startPusher();
    sendMessageToExtension(EMessagePrefixes.LOGIN, `Bearer ${authToken?.token}`);
    const sharedContentLink = localStorage.getItem(OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY);

    if (sharedContentLink && authToken?.isOnboardingCompleted) {
      localStorage.removeItem(OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY);
      return window.location.replace(sharedContentLink);
    }
    return authToken?.isOnboardingCompleted ? history.replace(ROUTE_DISCOVERY) : history.replace(ROUTE_ONBOARDING);
  };
};

export const useLogin = (): void => {
  const { track } = useAnalytics();

  const { push } = useHistory();
  const { setToast } = useToast();

  const verificationToken = useGetSearchParam('verification_token');
  const appleVerificationCode = useGetSearchParam('id_token');

  const proceedLogin = useProceedLogin();
  const [authQuery] = useAuthTokenLazyQuery({
    onCompleted: ({ authToken }) => {
      proceedLogin({ authToken });
      if (appleVerificationCode) {
        track(authToken?.isNewUser ? ANALYTICS_EVENTS.SignUpAppleID : ANALYTICS_EVENTS.LoginAppleID);
      }
      if (verificationToken && !authToken?.isNewUser) {
        track(ANALYTICS_EVENTS.LoginMagicLink);
      }
      track(authToken?.isNewUser ? ANALYTICS_EVENTS.SignUp : ANALYTICS_EVENTS.Login);
    },
    onError: () => {
      setToast({
        isToastOpen: true,
        toastItemName:
          'Hmm... Unfortunately, that link seems expired. For your security, please request another link and look for a new email.',
        toastError: true,
        linesCount: 3,
      });
      push(ROUTE_WELCOME);
    },
  });

  useEffect(() => {
    if (appleVerificationCode) {
      authQuery({
        variables: {
          token: appleVerificationCode,
          tokenType: OauthTokenTypes.Appleid,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appleVerificationCode]);

  useEffect(() => {
    if (verificationToken) {
      authQuery({
        variables: {
          token: verificationToken,
          tokenType: OauthTokenTypes.MagicLink,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationToken]);
};

interface IUseCheckInviteCode {
  checkInvite: (code: string) => void;
  isErrorShown: boolean;
  isRepeatedErrorShown: boolean;
}
interface IUseCheckInviteCodeArgs {
  onCompleted?: () => void;
  onError?: () => void;
}
export const useCheckInviteCode = (args?: IUseCheckInviteCodeArgs): IUseCheckInviteCode => {
  const { onCompleted, onError } = args ?? {};
  const [checkInviteCodeMutation] = useCheckInviteCodeMutation();

  const [isErrorShown, setIsErrorShown] = useState(false);
  const [isRepeatedErrorShown, setIsRepeatedErrorShown] = useState(false);

  const checkInvite = (code: string) =>
    checkInviteCodeMutation({
      variables: { code: code.toLocaleUpperCase() },
      onCompleted,
      onError: (error) => {
        const { statusCode }: GQLErrorWithStatusCode = error.graphQLErrors?.[0] ?? {};
        if (statusCode === 400) {
          // incorrect invite code
          setIsErrorShown(true);
        } else if (statusCode === 429) {
          // daily limit for entering invite codes has been reached
          setIsRepeatedErrorShown(true);
        }
        onError?.();
      },
    });

  return {
    checkInvite,
    isErrorShown,
    isRepeatedErrorShown,
  };
};

export const useCheckUsernameAvailable = (
  username: string,
  skip?: boolean
): { loading: boolean; errorCode?: number } => {
  const { loading, error } = useCheckUsernameAvailableQuery({
    fetchPolicy: EQueryFetchPolicy.NoCache,
    skip,
    variables: { username },
  });
  const gqlError: any = error?.graphQLErrors?.[0];

  return { loading, errorCode: gqlError?.errorCode };
};
