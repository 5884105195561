import useReferrerId from 'helpers/useReferrerId';
import usePageType from 'helpers/usePageType';

import { useGetUserCards } from 'graphQL/cards/userCards/hooks';
import { EQueryFetchPolicy } from 'constants/common';
import {
  CardSchema,
  GetUserSpotlightsDocument,
  useGetUserSpotlightsQuery,
  useUpdateSpotlightsMutation,
} from 'constants/graphqlTypes';

export interface IUseSpotlights {
  cards: CardSchema[];
  title: string;
  showSelectPicksBtn: boolean;
  titleSelectPicksBtn: string;
  hasSpotlights: boolean;
  loading: boolean;
  spotlightsCards: CardSchema[];
}

const NO_CACHE: EQueryFetchPolicy.NoCache = EQueryFetchPolicy.NoCache;

export const useGetSpotlights = (userId?: string): IUseSpotlights => {
  const referrerId = useReferrerId();
  const { isMyProfile } = usePageType();

  const { data: spotlightsData, loading } = useGetUserSpotlightsQuery({
    variables: { referrerId: userId ?? referrerId },
    skip: !referrerId && !userId,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });
  const { recentCards, totalCount } = useGetUserCards({ limit: 3, withImage: false });
  const spotlightsCards = spotlightsData?.allUsers?.items[0].spotlights ?? [];

  return {
    cards: spotlightsCards.length && totalCount > 3 ? spotlightsCards : recentCards,
    title: totalCount > 3 && spotlightsCards.length ? 'Spotlights' : 'Recent picks',
    showSelectPicksBtn: isMyProfile && totalCount > 3,
    titleSelectPicksBtn: !spotlightsCards.length ? 'Select picks' : 'Edit',
    hasSpotlights: !!spotlightsCards.length,
    loading,
    spotlightsCards,
  };
};

export const useUpdateSpotlights = (pickIds: string[], onCompleted: () => void): (() => void) => {
  const [updateSpotlights] = useUpdateSpotlightsMutation({
    variables: { pickIds },
    onCompleted,
    refetchQueries: [GetUserSpotlightsDocument],
    fetchPolicy: NO_CACHE,
  });

  return updateSpotlights;
};
