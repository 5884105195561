import React, { FC } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import getAuthUser from 'helpers/getAuthUser';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import usePageType from 'helpers/usePageType';

import { useUnpickMutation } from 'graphQL/cardOptions/hooks';
import { IAddContentLocation } from 'components/AddContent/helpers/models';
import { transformRoute } from 'helpers/routingHelper';
import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import { ROUTE_LIST_DETAIL, ROUTE_USER_PROFILE } from 'routes';
import { useRemoveCardFromList } from 'graphQL/addContent/addToList/hooks';
import { checkIsAdmin } from 'components/Navigation/RightMenu/CardButton/helpers';
import { useGetUserCards } from 'graphQL/cards/userCards/hooks';
import { ETypeOfCard, IRemovePickProps } from './models';
import { handleTypeOfCard } from './helpers';

const RemovePick: FC<IRemovePickProps> = ({
  card,
  handleCancel,
  handleClose,
  listCardId,
  listCardsIds,
  typeOfCard,
  setTypeOfCard,
}) => {
  const { replace, goBack, push } = useHistory();
  const { listId } = useParams<{ listId: string }>();
  const { isCardDetail, isAllPicksItem, isListItem, isThoughtsPage } = usePageType();
  const { username } = getAuthUser();
  const { state } = useLocation<IAddContentLocation | undefined>();
  const { prevPath, variable } = state ?? {};
  const { removeCardFromList } = useRemoveCardFromList(listId, card.id);
  const { cardId, isCardPickedByCollaborators, permissions, pickedByUsers, id } = card;
  const isDeletedPicker = !!pickedByUsers?.items.find((picker) => picker.id === REF_PLACEHOLDER);
  const { totalCount } = useGetUserCards();
  const unpickCard = useUnpickMutation(id, variable?.sparkId, variable?.sparkCardId);
  const isAdmin = checkIsAdmin(permissions);
  const hasMyPick = !!pickedByUsers?.items.find((user) => user.username === username);
  const pickersCount = (pickedByUsers?.totalCount && pickedByUsers.totalCount - (hasMyPick ? 1 : 0)) ?? 0;

  const handleRedirect = () => {
    if (prevPath && !isAllPicksItem && !isThoughtsPage) {
      goBack();
    } else if (isThoughtsPage && prevPath) {
      push(prevPath);
    } else if (isCardDetail) {
      replace(transformRoute(ROUTE_USER_PROFILE, { profileName: username }));
    } else if (isAllPicksItem) {
      if (totalCount <= 4) {
        replace(transformRoute(ROUTE_USER_PROFILE, { profileName: username }));
      } else {
        goBack();
      }
    } else if (isListItem) {
      replace(transformRoute(ROUTE_LIST_DETAIL, { listId }));
    }
  };

  const handleRemoveFromAllPicks = () => {
    handleClose();
    unpickCard();
    handleRedirect();
  };

  const handleRemoveFromList = (isRemoveFromAllPicks: boolean, isRemovePickFully: boolean, hasOtherPicks: boolean) => {
    handleClose();
    removeCardFromList({
      cardId,
      listCardId: listCardId || listCardsIds[0],
      isCardPickedByCollaborators: hasOtherPicks,
      removeFromAllPicks: isRemoveFromAllPicks,
      removePickFully: isRemovePickFully,
    });
    handleRedirect();
  };

  const {
    subTitle,
    title,
    handleRemove,
    handleRemoveSecondOption,
    buttonText,
    secondButtonText,
    cancelButtonText,
  } = handleTypeOfCard({
    handleRemoveFromAllPicks,
    handleRemoveFromList,
    isAdmin,
    pickersCount,
    typeOfCard,
    isCardPickedByCollaborators: !!isCardPickedByCollaborators,
    setTypeOfCard,
    isDeletedPicker,
    hasMyPick,
  });

  return (
    <>
      {(typeOfCard === ETypeOfCard.IsAllOptions || typeOfCard === ETypeOfCard.IsCollabListWithOptions) && (
        <ConfirmationModal
          handleCancel={handleCancel}
          handleAccept={handleRemove}
          handleAcceptSecondOption={handleRemoveSecondOption}
          subTitle={subTitle}
          buttonText={buttonText}
          secondButtonText={secondButtonText}
        />
      )}
      {(typeOfCard === ETypeOfCard.IsAllPicks ||
        typeOfCard === ETypeOfCard.IsCollabList ||
        typeOfCard === ETypeOfCard.IsPrivateList) && (
        <ConfirmationModal
          buttonText={buttonText}
          cancelButtonText={cancelButtonText}
          handleAccept={handleRemove}
          handleCancel={handleCancel}
          subTitle={subTitle}
          titleText={title}
        />
      )}
    </>
  );
};

export default RemovePick;
