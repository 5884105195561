import { useLocation } from 'react-router-dom';
import { MutationFunction } from '@apollo/client';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import getAuthUser from 'helpers/getAuthUser';
import useToast from 'helpers/useToast';

import { IAddContentLocation } from 'components/AddContent/helpers/models';
import { EMPTY_ITEMS, EQueryFetchPolicy } from 'constants/common';
import {
  useGetListsQuery,
  useRemoveCardFromListMutation,
  useMoveToListMutation,
  CardTypeOptions,
  GetDetailCardDocument,
  GetUserCardsDocument,
  GetListItemsDocument,
  GetAllPicksCoverDocument,
  GetExploreEveryoneDocument,
  GetDetailListDocument,
  MoveToListMutation,
  MoveToListMutationVariables,
  GetUserProfileDocument,
} from 'constants/graphqlTypes';
import { getNotifications, removeFromItems } from 'graphQL/cardOptions/helpers';
import { updateUserCards } from 'graphQL/cards/userCards/helpers';
import { IRemoveCardFromListParams, IUseGetLists, IUseMoveToList } from './models';
import { addItemToList, removeItemFromList } from './helpers';
import { IRefItems } from '../../models';

export const useGetLists = (cardId: string): IUseGetLists => {
  const { userId } = getAuthUser();

  const { data, loading, fetchMore } = useGetListsQuery({
    variables: { userId, offset: 0, cardId },
    skip: !cardId,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { items, totalCount } = data?.allUsers?.items[0]?.pickedCards ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateUserCards(prev, fetchMoreResult),
    });

  return { items, loading, loadMore };
};

export const useMoveToList = ({
  pickId,
  cardId,
  listTitle,
  image,
  listCardId,
  onCompleted,
  onLockedError,
}: IUseMoveToList): {
  moveToList: MutationFunction<MoveToListMutation, MoveToListMutationVariables>;
  loading: boolean;
} => {
  const { track } = useAnalytics();

  const { state } = useLocation<IAddContentLocation | undefined>();
  const { variable, fallbackRefId } = state ?? {};
  const isFromList = state?.prevPath?.includes('list');

  const { currentListId, userPickSchemaRef } = variable ?? {};
  const { setToast } = useToast();

  const [moveToList, { loading }] = useMoveToListMutation({
    ...getNotifications({
      action: 'Moved to',
      message: listTitle,
      image,
      type: CardTypeOptions.Collection,
      onCompleted: () => {
        track(ANALYTICS_EVENTS.MoveToList);
        track(ANALYTICS_EVENTS.CardAction);
        onCompleted?.();
      },
      onLockedError,
    }),
    variables: {
      pickId,
      listCardId,
      currentListId,
    },
    refetchQueries: [
      GetUserCardsDocument,
      GetAllPicksCoverDocument,
      GetListItemsDocument,
      {
        query: GetDetailCardDocument,
        variables: {
          id: pickId,
          ...(fallbackRefId && { fallbackReferrerId: fallbackRefId }),
          ...(isFromList && { refListId: currentListId }),
        },
      },
      listCardId
        ? {
            query: GetDetailListDocument,
            variables: {
              id: listCardId,
              coverLimit: undefined,
              isDetail: true,
            },
          }
        : '',
    ],
    update: (cache, result, { variables }) => {
      const { listCardId: newListId, pickId: variableCardId } = variables ?? {};
      const isPickedByCollabsInOriginList = result.data?.changeCardCollection?.isPickedByCollabsInOriginList;
      const isPickedByCollaboratorsInTargetList = result.data?.changeCardCollection?.isCardPickedByCollaborators;
      if (!variableCardId || !newListId) {
        return;
      }

      // deleting userPick from card's ExtraData (userFallback or userCircle)
      cache.evict({ id: cache.identify({ __typename: 'CardPickSchema', id: userPickSchemaRef }) });
      cache.gc();

      // add card to targetList if there no this card picked by other users
      if (!isPickedByCollaboratorsInTargetList) {
        addItemToList({ cache, listId: newListId, pickId });
        setToast({ toastId: newListId });
      }

      // remove card in origin list if it was not picked by collaborators in originList
      if (currentListId && !isPickedByCollabsInOriginList) {
        removeItemFromList({ cache, listId: currentListId, pickId });
      }
    },
  });

  return { moveToList, loading };
};

export const useRemoveCardFromList = (
  listId: string,
  pickId: string
): { removeCardFromList: (variables: IRemoveCardFromListParams) => void } => {
  const { track } = useAnalytics();
  const { userId } = getAuthUser();
  const [mutation] = useRemoveCardFromListMutation({
    ...getNotifications({ isToastOpen: true, message: `Pick Removed` }),
  });

  const removeCardFromList = (variables: IRemoveCardFromListParams) => {
    mutation({
      variables: { ...variables },
      refetchQueries: [
        { query: GetExploreEveryoneDocument, variables: {} },
        { query: GetUserProfileDocument, variables: { id: userId } },
        ...(listId
          ? [
              {
                query: GetListItemsDocument,
                variables: {
                  id: listId,
                  refListId: listId,
                  offset: 0,
                },
              },
            ]
          : []),
      ],
      update: (cache) => {
        if (!variables.isCardPickedByCollaborators) {
          removeItemFromList({ cache, listId, pickId });
        }
        if (variables.removeFromAllPicks) {
          cache.modify({
            id: cache.identify({ id: userId, __typename: 'UserObjectType' }),
            fields: {
              pickedCards: (prev: IRefItems) => ({
                ...prev,
                items: removeFromItems(prev.items, pickId),
                totalCount: prev.totalCount - 1,
              }),
            },
          });
        }
      },
      onCompleted: () => {
        if (variables.removeFromAllPicks) {
          track(ANALYTICS_EVENTS.CardUnpick);
          track(ANALYTICS_EVENTS.CardAction);
        }
      },
    });
  };

  return { removeCardFromList };
};
