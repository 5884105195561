import React, { FC, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useClickOutside from 'helpers/useClickOutside';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { useCollaborators } from 'graphQL/card/creators/hook';
import { ECardRoleTypeOptions, UserCardRoleSchema } from 'constants/graphqlTypes';
import { ReactComponent as CrossIcon } from 'assets/icons/Cross.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import ConfirmationModal from 'components/UI/ConfirmationModal';

import { StyledButton, StyledSelect } from './styled';

import RolesList from './List';

interface IRoleSelectProps {
  userId: string;
  username: string;
  role: ECardRoleTypeOptions;
  users: UserCardRoleSchema[];
  isCurrentUser: boolean;
  isUserAnAdmin: boolean;
  handleClose: () => void;
  listUserId?: string | null;
}

const RoleSelect: FC<IRoleSelectProps> = ({
  userId,
  username,
  role,
  users,
  isCurrentUser,
  handleClose,
  isUserAnAdmin,
  listUserId,
}) => {
  const { track } = useAnalytics();

  const [isRolesListOpen, setIsRolesListOpen] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);
  const [isCollab, setIsCollab] = useState(false);
  const { state } = useLocation<{ variable: { listCardId: string } }>();
  const { listCardId } = state?.variable ?? {};
  const ref = useRef<HTMLDivElement | null>(null);
  const { handleRemove } = useCollaborators(userId, listCardId);
  useClickOutside(ref, () => setIsRolesListOpen(false));

  const noAnotherAdmin = users.filter(({ roleName }) => roleName === ECardRoleTypeOptions.Owner).length < 2;
  const allowLeave = isLeaveModalOpen && !(noAnotherAdmin && isUserAnAdmin);
  const handleLeave = () => {
    handleRemove();

    if (!isUserAnAdmin) {
      track(ANALYTICS_EVENTS.CollaborativeList);
      track(ANALYTICS_EVENTS.CollabLeaveList);
    }

    handleClose();
  };

  return (
    <StyledSelect ref={ref}>
      {isUserAnAdmin && (
        <StyledButton
          isOpen={isRolesListOpen}
          onClick={() => setIsRolesListOpen((prev) => !prev)}
          disabled={role === ECardRoleTypeOptions.Invited}
        >
          {role === ECardRoleTypeOptions.Owner ? 'admin' : role.toLowerCase()}
          {role !== ECardRoleTypeOptions.Invited && <ChevronLeft viewBox="0 0 24 24" height="14" width="14" />}
        </StyledButton>
      )}
      {!isUserAnAdmin && isCurrentUser && (
        <StyledButton onClick={() => setIsLeaveModalOpen(true)}>
          Leave
          <CrossIcon />
        </StyledButton>
      )}
      {isRolesListOpen && (
        <RolesList
          role={role}
          isCurrentUser={isCurrentUser}
          userId={userId}
          username={username}
          closeList={() => setIsRolesListOpen(false)}
          noAnotherAdmin={noAnotherAdmin}
          openAlert={(shouldOpenLeaveModal) => (shouldOpenLeaveModal ? setIsLeaveModalOpen(true) : setIsCollab(true))}
          listUserId={listUserId}
          setIsCollab={setIsCollab}
        />
      )}
      {(isCollab || isLeaveModalOpen) && (
        <ConfirmationModal
          cancelButtonText={noAnotherAdmin && isUserAnAdmin ? 'got it' : 'cancel'}
          handleCancel={() => (isLeaveModalOpen ? setIsLeaveModalOpen(false) : setIsCollab(false))}
          handleAccept={handleLeave}
          buttonText={allowLeave ? 'leave' : undefined}
          titleText={
            allowLeave
              ? 'leave list'
              : `you need to assign a new admin before ${isCollab ? 'changing role' : 'leaving'}`
          }
          subTitle={allowLeave ? 'Are you sure you want to leave this list?' : 'Assign at least 1 admin'}
        />
      )}
    </StyledSelect>
  );
};

export default RoleSelect;
