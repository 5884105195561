import { styled } from 'linaria/react';

import { BREAKPOINTS, COLORS, FONTS } from '../../../styles/constants';

export const StyledWrap = styled.div<{ isNiceFind?: boolean }>`
  .modal {
    padding: 24px 0px;
  }
  .modal--header {
    padding: 0 24px 0 12px;
    flex-direction: row;

    #modal__close-button {
      margin-right: ${({ isNiceFind }) => (isNiceFind ? '0' : 'auto')};
    }
  }
  .modal--content {
    padding: 0;
  }
`;

export const StyledContentContainer = styled.div`
  flex: 1;
  overflow: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
  }
  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 436px;
  }
  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    max-height: calc(436px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledModalHeader = styled.div`
  align-items: end;
  border-bottom: 1px solid ${COLORS.white[16]};
  display: flex;
  gap: 10px;
  padding: 15px 24px;
  width: 100%;
  > span {
    padding-bottom: 12px;
    font: 400 14px/140% ${FONTS.secondary};
    color: ${COLORS.white[100]};
  }
`;

export const StyledHeading = styled.p`
  margin: 0;
  font: 400 22px/130% ${FONTS.primary};
  color: ${COLORS.white[100]};
  padding: 32px 24px 16px;
  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(22px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledList = styled.ul<{ isOrganizeLater?: boolean }>`
  list-style: none;
  margin: 0;
  padding: 0 24px 40px;
  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    padding: 0 0 calc(40px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledMenuButtonContainer = styled.div`
  padding: 0 24px;
`;

export const StyledListWrap = styled.div`
  flex: 1;
  margin-top: 24px;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (min-width: ${BREAKPOINTS.s}px) {
    max-height: 436px;
  }

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    max-height: calc(436px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledWrapListTitle = styled.div`
  padding-bottom: 8px;
`;

export const StyledListTitle = styled.span`
  font-family: ${FONTS.secondary};
  font-size: 14px;

  @media screen and (min-width: ${BREAKPOINTS.xxl}px) {
    font-size: calc(14px * ${BREAKPOINTS.retinaScale});
  }
`;

export const StyledListItem = styled.li`
  display: block;
`;

export const StyledHr = styled.hr`
  background-color: ${COLORS.white[30]};
  border: none;
  height: 1px;
  margin: 8px 0 0;
`;

export const StyledSavedPreview = styled.div`
  align-items: center;
  display: flex;
  font-family: ${FONTS.secondary};
  justify-content: flex-start;
  padding: 16px 0px;
`;

export const StyledSavedImage = styled.img`
  height: 40px;
  width: 40px;
  object-fit: cover;
  border-radius: 2px;
`;

export const StyledSavedImageWrap = styled.div<{ bgColor: string }>`
  background: ${({ bgColor }) => bgColor};
  padding: 4px 2.5px;
  border-radius: 4px;
  margin-right: 10px;
  height: 47px;
  display: flex;
  align-items: center;
`;
