import React, { FC } from 'react';

import { ETutorialStatus } from 'constants/graphqlTypes';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';

import OnboardingAlert from 'components/UI/OnboardingAlert';

import { useAnalytics } from 'contexts/AnalyticsContext';
import useGuideTour from 'helpers/useGuideTour';
import useDisableScroll from 'helpers/useDisableScroll';

import { useUpdateUserSettings } from 'graphQL/contextualIntro/hooks';

import { StyledTutorialWrapper } from './styled';

const AppTour: FC = () => {
  const { track } = useAnalytics();
  const { setIsGuideTour } = useGuideTour();
  const { updateUserSettings } = useUpdateUserSettings({ tutorialStatus: ETutorialStatus.Delayed });

  useDisableScroll();

  return (
    <StyledTutorialWrapper>
      <OnboardingAlert
        title="Welcome! Can we show you around?"
        submitText="Take the tour"
        cancelText="I’ll figure it out"
        titleSize={22}
        handleSubmit={() => {
          setIsGuideTour?.(true);
          track(ANALYTICS_EVENTS.Onboarding);
          track(ANALYTICS_EVENTS.StartTourFeaturedTab);
        }}
        handleCancel={() => updateUserSettings()}
      >
        It’ll be a brief but helpful introduction on how to get the most out of this app.
      </OnboardingAlert>
    </StyledTutorialWrapper>
  );
};

export default AppTour;
