import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Avatar from 'components/UI/Avatar';
import Card from 'components/Card';
import Modal from 'components/Modal';
import TextInput from 'components/UI/TextInput';
import useGetExtractionCard from 'graphQL/addContent/addStyle/hook';
import getAuthUser from 'helpers/getAuthUser';
import useDisableScroll from 'helpers/useDisableScroll';
import { isIos } from 'helpers/useUserAgent';
import { SHARE_THOUGHT } from 'components/GuideTour/constants';
import { useProfile } from 'graphQL/profile/hooks';
import { EQueryFetchPolicy } from 'constants/common';
import { useGetComments } from 'graphQL/card/comments/hooks';
import { CardSchema, CardStyleSchema } from 'constants/graphqlTypes';
import { useGetDetailCard } from 'graphQL/card/helpers/hooks';
import { useFinishAddContent } from 'components/AddContent/helpers/hooks';
import { useAddResponseToSpark } from 'graphQL/sparks/hooks';
import ConfirmationModal from 'components/UI/ConfirmationModal';

import { StyledPreview } from 'components/AddContent/AddStyle/styled';
import { StyledDrag, StyledFooter, StyledComment } from 'components/AddContent/AddThought/styled';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { useSparkResponseLinks } from '../hooks';

interface IAddThoughtProps {
  handleClose: () => void;
  spark: CardSchema;
  cardId: string;
  pickId: string;
  isPicked: boolean;
  cardStyle?: CardStyleSchema;
  listCardId?: string;
  listTitle: string;
}

const AddThought: FC<IAddThoughtProps> = ({
  handleClose,
  spark,
  cardId,
  pickId,
  cardStyle,
  listCardId,
  isPicked,
  listTitle,
}) => {
  const [thought, setThought] = useState('');
  const [error, setError] = useState('');
  const [isDoneBtnLoading, setIsDoneButtonLoading] = useState(false);

  const { addResponsePick, addResponseStyle } = useSparkResponseLinks();

  const { userId } = getAuthUser();

  const { replace } = useHistory();
  const { card: extractionCard, loading: cardLoading, isQueryCalled } = useGetExtractionCard(pickId);
  const { card: detailCard } = useGetDetailCard(pickId);
  const card = extractionCard || detailCard;
  const { userData } = useProfile(undefined, EQueryFetchPolicy.CacheOnly);
  const { comments } = useGetComments(cardId, { referrerUserId: userId });
  const myThought = comments?.items[0];
  const { text, id: thoughtId } = myThought ?? {};

  useEffect(() => {
    if (text) {
      setThought(text);
    }
  }, [text]);

  useEffect(() => {
    if (!cardLoading && !card && isQueryCalled) {
      replace(addResponsePick);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardLoading, card]);

  const { loading: addResponseLoading, addResponse } = useAddResponseToSpark({ spark });

  const handleAddSparkResponse = (cardPickId?: string) => {
    addResponse(cardPickId ?? pickId).then(() => handleClose());
  };

  const { handleFinish, loading } = useFinishAddContent({
    pickId: card?.id ?? pickId,
    cardId,
    thought,
    thoughtId,
    isUpdateThought: !!thoughtId,
    listTitle,
    close: handleAddSparkResponse,
    listCardId,
    item: card && { ...card, ...(cardStyle && { cardStyle }) },
    onLockedError: () => setIsDoneButtonLoading(false),
  });

  const isLoading = loading || cardLoading || addResponseLoading;

  useEffect(() => {
    setError('');
  }, [thought]);

  useDisableScroll(!isIos());

  const handleDone = () => {
    setIsDoneButtonLoading(true);
    handleFinish();
  };

  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);

  return (
    <Modal
      fadeIn={false}
      handleClose={() => !isLoading && handleClose()}
      handleDone={handleDone}
      handlePrev={() => replace(cardId && isPicked ? addResponsePick : addResponseStyle)}
      buttonText="Add and create"
      loading={isDoneBtnLoading || isLoading}
      isExitConfirmed={isExitConfirmed}
      setShowConfirm={setShowExitWarning}
    >
      <StyledPreview bgColor={cardStyle?.bgColor?.colorValue ?? card?.cardStyle?.bgColor?.colorValue}>
        {card && <Card card={{ ...card, ...(cardStyle && { cardStyle }) }} disableLinks />}
      </StyledPreview>
      <StyledFooter data-tour={SHARE_THOUGHT}>
        <StyledDrag />
        <StyledComment>
          {userData && <Avatar user={userData} />}
          <TextInput
            inputHeight={80}
            placeholder="Add your thought"
            setText={(value: string) => setThought(value)}
            text={thought}
            type="thought"
            error={error}
            isMultiLine
            autoCapitalize={EAutoCapitalize.On}
          />
        </StyledComment>
      </StyledFooter>
      {showExitWarning && (
        <ConfirmationModal
          handleCancel={() => setShowExitWarning(false)}
          handleClickOutside={() => setShowExitWarning(false)}
          handleAccept={() => setIsExitConfirmed(true)}
          isWarning
          buttonText="Discard"
          titleText="Discard pick?"
          subTitle="Your edits and thoughts will be discarded if you leave before posting."
        />
      )}
    </Modal>
  );
};

export default AddThought;
