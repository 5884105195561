import React, { FC, useState } from 'react';

import BlockButton from 'components/Blocking/UserButton';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { useNavigateToReport } from 'graphQL/cardOptions/hooks';
import { useHandleInvite } from 'graphQL/list/invite/hooks';
import { UserObjectType } from 'constants/graphqlTypes';
import { EReportTypes } from 'components/Report/constants';
import {
  StyledBottom,
  StyledBtnWrap,
  StyledButton,
  StyledCloseButton,
  StyledAcceptButton,
  StyledDeclineButton,
} from './styled';

interface IButtonsProps {
  isAcceptInvite: boolean;
  isDark: boolean;
  pickId: string;
  cardId: string;
  user: UserObjectType;
  inviteId: string;
  handleClose: () => void;
}

const Buttons: FC<IButtonsProps> = ({ isAcceptInvite, isDark, pickId, cardId, user, inviteId, handleClose }) => {
  const { track } = useAnalytics();
  const [isAccept, setIsAccept] = useState(isAcceptInvite);
  const { isBlockedByMe, id } = user;

  const handleInvite = useHandleInvite(inviteId, pickId);

  const handleReport = useNavigateToReport({
    inviteId,
    cardId,
    isBlockedByMe,
    id,
    type: EReportTypes.Invite,
  });

  const handleAccept = () => {
    handleInvite(true);
    track(ANALYTICS_EVENTS.CollaborativeList);
    track(ANALYTICS_EVENTS.DetailPageAccept);
  };

  const handleDecline = () => {
    setIsAccept(false);
    handleInvite(false);
  };

  return (
    <StyledBottom>
      {isAccept ? (
        <StyledBtnWrap isColumn>
          <StyledAcceptButton onClick={handleAccept}>Accept invite</StyledAcceptButton>
          <StyledDeclineButton isDark={isDark} onClick={handleDecline}>
            Decline
          </StyledDeclineButton>
        </StyledBtnWrap>
      ) : (
        <>
          <StyledBtnWrap>
            {!isBlockedByMe && user && (
              <BlockButton userId={id} isBlockedByMe={!!isBlockedByMe} handler={handleClose}>
                <StyledButton isDark={isDark}>Block</StyledButton>
              </BlockButton>
            )}
            <StyledButton isDark={isDark} onClick={handleReport}>
              Report
            </StyledButton>
          </StyledBtnWrap>
          <StyledCloseButton isDark={isDark} onClick={handleClose}>
            Close
          </StyledCloseButton>
        </>
      )}
    </StyledBottom>
  );
};

export default Buttons;
