import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { UserObjectType } from 'constants/graphqlTypes';

import { useGetUsersForOnboarding } from 'graphQL/onboarding/hooks';

import { useAnalytics } from 'contexts/AnalyticsContext';
import useTheme from 'helpers/useTheme';

import UserCard from 'components/Card/UserCard';
import Layout from 'components/Onboarding/Layout';
import UniversalSlider from 'components/Sliders/UniversalSlider';

import { ROUTE_ONBOARDING_NETWORKING_CONFIRMATION } from 'routes';

const UserSuggestion: FC = () => {
  const { track } = useAnalytics();

  const items = useGetUsersForOnboarding();
  const isDark = useTheme();
  const { push } = useHistory();

  const users = items?.reduce((acc: UserObjectType[], item) => (item?.user ? acc.concat(item.user) : acc), []) ?? [];

  const goToConfirmationPage = () => push(ROUTE_ONBOARDING_NETWORKING_CONFIRMATION);

  useEffect(() => {
    track(ANALYTICS_EVENTS.OnboardingNetworkingSuggestions);
  }, [track]);

  return (
    <Layout
      heading="Follow some interesting people"
      text="This month’s most influential pickers"
      primaryBtnText={users.find((user) => user.isFollowedByMe) ? 'Done' : undefined}
      handlePrimaryBtn={goToConfirmationPage}
      secondaryBtnText={users.find((user) => user.isFollowedByMe) ? undefined : 'Skip'}
      handleSecondaryBtn={goToConfirmationPage}
      customHeaderGap={10}
      hasUserCardsSlider
      blockScroll={false}
      isSecondaryBtnTransparent
      secondaryBtnIsDark={isDark}
      isPageScrollable
      maxWidth={528}
    >
      <UniversalSlider
        items={users}
        itemRender={(user, isCurrent, imagesKey) =>
          user && (
            <UserCard
              item={user}
              enableOldBehaviour={false}
              isFullHeight
              isCurrent={isCurrent}
              imagesKey={imagesKey}
              hasAnimatedImages
              isOnboardingView
              disableLinks
              disableBadgeInPreview
            />
          )
        }
        isInfinite
      />
    </Layout>
  );
};

export default UserSuggestion;
