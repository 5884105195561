import React, { FC, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import validUrl from 'valid-url';

import Modal from 'components/Modal';
import ExtractionError from 'components/ExtractionError/ExtractionError';
import TextInput from 'components/UI/TextInput';
import Button from 'components/UI/MenuButton';
import ContentTitle from 'components/UI/ContentTitle';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import getValidationError from 'helpers/getValidationError';
import useDisableScroll from 'helpers/useDisableScroll';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import {
  TEST_EXTRACT_FROM_LINK_INPUT,
  TEST_EXTRACT_FROM_LINK_PASTE_LINK_BTN,
  TEST_EXTRACT_FROM_LINK_TITLE,
} from 'constants/aqa/addPick';

import { useExtraction } from 'graphQL/addContent/extraction/hook';
import { ReactComponent as PickFromLinkIcon } from 'assets/icons/PickFromLink.svg';
import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_ADD_TO_LIST, ROUTE_CARD_DETAIL } from 'routes';
import { useLazyGetCardId } from 'graphQL/card/helpers/hooks';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { searchFileInUrl } from '../helpers/helpers';
import { useAddContentLinks } from '../helpers/hooks';
import { IAddContentLocation, ISetExtractData } from '../helpers/models';
import { INVALID_URL_ERROR_MESSAGE } from '../helpers/constants';
import { useCheckPickUrl } from './hooks';

import { StyledFooter, StyledInputWrapper } from './styled';

interface IAddPickProps {
  url: string;
  listId?: string;
  handleClose: () => void;
  setExtractData: ({ extractPickId, extractCardId, extractTitle, extractImage }: ISetExtractData) => void;
  setUrl: Dispatch<SetStateAction<string>>;
  fadeIn: boolean;
}

export interface IExtractionError {
  errorType?: string | null;
  isPrivateSource?: boolean | null;
}

export interface IBlockedByAdminReason {
  reasonTitle?: string | null;
  reasonDescription?: string | null;
}

const AddPick: FC<IAddPickProps> = ({ url, listId, handleClose, setExtractData, setUrl, fadeIn }) => {
  const { track } = useAnalytics();

  const [error, setError] = useState('');
  const [reason, setReason] = useState<IBlockedByAdminReason>({});
  const [extractionError, setExtractionError] = useState<IExtractionError | null>(null);
  const [isPicked, setIsPicked] = useState(false);
  const [showExitWarning, setShowExitWarning] = useState(false);
  const [isExitConfirmed, setIsExitConfirmed] = useState(false);
  const [isExtractionStarted, setIsExtractionStarted] = useState(false);

  const { replace } = useHistory();
  const { state } = useLocation<IAddContentLocation | undefined>();
  const { variable: stateData } = state ?? {};
  const { url: newPickUrl } = stateData ?? {};
  const { addToList, home, addStyle } = useAddContentLinks();
  const { extract, id, cardId, title, image, loading, source, mediaTag } = useExtraction(url);

  const validationError = useCheckPickUrl(url);

  const extractCallback = () => {
    extract()
      .then(({ data }) => {
        if (data && data.startExtractCard?.__typename === 'ExtractionErrorSchema') {
          const { errorType, reasonTitle, reasonDescription } = data?.startExtractCard;
          setReason({ reasonTitle, reasonDescription });
          return setExtractionError({ errorType });
        }

        if (
          data?.startExtractCard?.__typename === 'PreExtractCardSchema' ||
          data?.startExtractCard?.__typename === 'CardSchema'
        ) {
          const { isPrivateSource } = data?.startExtractCard;
          if (isPrivateSource) {
            return setExtractionError({ isPrivateSource });
          }
        }

        if (!showExitWarning) {
          return replace(listId ? addStyle : addToList);
        }
        return null;
      })
      .catch((e) => setError(getValidationError(e) || e.message));
  };

  const {
    checkPickExist,
    pickId: existPickId,
    cardId: existCardId,
    isSavedByMe,
    loading: getCardIdLoading,
  } = useLazyGetCardId(extractCallback);
  const file = searchFileInUrl(url);

  const handleViewPick = () => {
    setIsPicked(false);
    replace(transformRoute(ROUTE_CARD_DETAIL, { pickId: existPickId }));
  };

  const handleMovePick = () => {
    replace({
      pathname: ROUTE_ADD_TO_LIST,
      state: {
        ...state,
        isSingleModal: false,
        variable: {
          pickId: existPickId,
          cardId: existCardId,
          isMovePick: !isSavedByMe,
          isDetail: true,
          isSavedPick: !!isSavedByMe,
        },
      },
    });
  };

  useEffect(() => {
    if (newPickUrl) {
      setUrl(newPickUrl);
    }
  }, [newPickUrl, setUrl]);

  useEffect(() => {
    if (getCardIdLoading) {
      setIsExtractionStarted(true);
    }
  }, [getCardIdLoading]);

  useEffect(() => {
    setExtractData({ extractCardId: cardId, extractPickId: id, extractImage: image, extractTitle: title });

    if (cardId) {
      track(ANALYTICS_EVENTS.AddCard);
      track(ANALYTICS_EVENTS.AddLink, {
        media_tag: mediaTag,
        source,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, title, image]);

  useEffect(() => {
    if (isSavedByMe) {
      handleMovePick();
    } else {
      setIsPicked(!!existPickId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existPickId, isSavedByMe]);

  useEffect(() => {
    setError(!validUrl.isUri(url) && url.length ? INVALID_URL_ERROR_MESSAGE : '');
  }, [url]);

  useDisableScroll();

  return (
    <Modal
      fadeIn={!!listId && fadeIn}
      handleClose={handleClose}
      handleDone={() => checkPickExist(url)}
      handlePrev={listId ? undefined : () => replace(home)}
      buttonText="Next"
      disabled={!!validationError || !!error || !url}
      loading={loading}
      isExitConfirmed={isExitConfirmed}
      setShowConfirm={isExtractionStarted ? setShowExitWarning : handleClose}
    >
      <StyledInputWrapper>
        <ContentTitle dataTestId={TEST_EXTRACT_FROM_LINK_TITLE} isDark>
          Add your pick
        </ContentTitle>
        <TextInput
          dataTestId={TEST_EXTRACT_FROM_LINK_INPUT}
          inputHeight={143}
          placeholder="https://www.eg.com"
          setText={setUrl}
          text={url}
          error={validationError || error}
          longDash={false}
        />
      </StyledInputWrapper>
      {!url && (
        <StyledFooter>
          <Button
            dataTestId={TEST_EXTRACT_FROM_LINK_PASTE_LINK_BTN}
            text="Paste link from clipboard"
            Icon={PickFromLinkIcon}
            handler={() => navigator.clipboard?.readText().then(setUrl)}
          />
        </StyledFooter>
      )}
      {isPicked && (
        <ConfirmationModal
          handleCancel={handleViewPick}
          handleAccept={handleMovePick}
          handleClickOutside={() => setIsPicked(false)}
          subTitle="You have picked that URL before. Move the pick or leave it where it is."
          cancelButtonText="view pick"
          buttonText="move pick"
          titleText="already picked"
          isWarning={false}
        />
      )}
      {!!file && (
        <ConfirmationModal
          handleAccept={() => setUrl('')}
          buttonText="Add another pick"
          handleClickOutside={() => setUrl('')}
          subTitle={`Oops, uploading ${file} files isn't supported. Please add a link to a webpage.`}
          titleText={`.${file} blocked`}
          isWarning={false}
          isFirstLetterBig={false}
        />
      )}
      {showExitWarning && (
        <ConfirmationModal
          handleCancel={() => setShowExitWarning(false)}
          handleClickOutside={() => setShowExitWarning(false)}
          handleAccept={() => setIsExitConfirmed(true)}
          isWarning
          buttonText="Discard"
          titleText="Discard pick?"
          subTitle="Your edits and thoughts will be discarded if you leave before posting."
        />
      )}
      {extractionError && (
        <ExtractionError setUrl={setUrl} extractionError={extractionError} reason={reason} listId={listId} />
      )}
    </Modal>
  );
};

export default AddPick;
