import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useAdjustTopicsMutation,
  useGetUserFollowersQuery,
  useGetUserFollowingsQuery,
  useGetUserTopicsQuery,
  useFollowUserMutation,
  useGetUserConnectionsQuery,
  GetExploreFollowingsDocument,
  UserFilter,
  useUnfollowUserMutation,
  UserObjectType,
  useCheckUserTopicsLazyQuery,
  GetUserFollowersQuery,
  GetUserFollowingsQuery,
  GetUserCountersDocument,
} from 'constants/graphqlTypes';
import { EMPTY_ITEMS, EQueryFetchPolicy } from 'constants/common';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { useGetUserId } from 'graphQL/profile/hooks';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { useOpenTopics } from 'helpers/useTopicsModal';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { getNotifications } from 'graphQL/cardOptions/helpers';
import useToast from 'helpers/useToast';
import getAuthUser from 'helpers/getAuthUser';
import { IUseGetUserTopics, IUseUserFollows, IUseUserFollowings, IUseCheckUserTopics } from './models';
import {
  getCachedFollowingsOrFollowers,
  getCachedUser,
  getPeopleItems,
  getPeopleQueryParams,
  updateCachedFollowersWithUser,
  updateCachedFollowingsWithUser,
  updatePeopleQuery,
  updateUserTopicsQuery,
} from './helpers';
import { EPeopleField } from './constants';

export const useGetFollowings = (userId: string, filters?: UserFilter, feedId?: string): IUseUserFollowings => {
  const { data, fetchMore, loading } = useGetUserFollowingsQuery({
    ...getPeopleQueryParams(userId, filters, feedId),
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: !userId,
  });
  const { items, totalCount } = getPeopleItems(EPeopleField.Followings, data);

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updatePeopleQuery(EPeopleField.Followings, prev, fetchMoreResult),
    });

  return { items, loadMore, totalCount, loading };
};

export const useGetFollowers = (userId: string): IUseUserFollowings => {
  const { data, fetchMore, loading } = useGetUserFollowersQuery(getPeopleQueryParams(userId));
  const { items, totalCount } = getPeopleItems(EPeopleField.Followers, data);

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updatePeopleQuery(EPeopleField.Followers, prev, fetchMoreResult),
    });

  return { items, loadMore, totalCount, loading };
};

export const useGetConnections = (userId: string, filters?: UserFilter): IUseUserFollowings => {
  const { data, fetchMore, loading } = useGetUserConnectionsQuery({
    ...getPeopleQueryParams(userId, filters),
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });
  const { items, totalCount } = getPeopleItems(EPeopleField.Connections, data);

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updatePeopleQuery(EPeopleField.Connections, prev, fetchMoreResult),
    });

  return { items, loadMore, totalCount, loading };
};

export const useUserFollows = (tabIndex: number): IUseUserFollows => {
  const [currentTab, setCurrentTab] = useState(tabIndex);

  const { profileName } = useParams<{ profileName: string }>();
  const { userId } = useGetUserId(profileName);

  const { items, loading, loadMore, totalCount } = useGetFollowers(userId ?? '');
  const {
    items: followings,
    loading: loadingFollowings,
    loadMore: loadMoreFollowing,
    totalCount: totalFollowings,
  } = useGetFollowings(userId ?? '');

  const tabs: string[] = [
    `${totalCount} follower${totalCount === 1 ? '' : 's'}`,
    `${totalFollowings} following${totalFollowings === 1 ? '' : 's'}`,
  ];
  const isFollowers = currentTab === 0;

  useEffect(() => {
    setCurrentTab(currentTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, totalFollowings]);

  return {
    items: isFollowers ? items : followings,
    loading: isFollowers ? loading : loadingFollowings,
    loadMore: isFollowers ? loadMore : loadMoreFollowing,
    setCurrentTab,
    currentTab,
    tabs,
    totalFollowings,
    totalFollowers: totalCount,
  };
};

export const useFollowUserByTopics = (followingId: string) => {
  const { setToast } = useToast();

  const [adjustTopicsMutation] = useAdjustTopicsMutation({
    onCompleted: () => {
      setToast({
        toastItemName: `Topics updated `,
        isToastOpen: true,
      });
    },
  });

  const adjustTopics = (followTagIds: string[], unfollowTagIds: string[]) =>
    adjustTopicsMutation({
      variables: {
        userId: followingId,
        followTagIds,
        unfollowTagIds,
      },
    });

  return {
    adjustTopics,
  };
};

export const useUnfollowUser = (user: UserObjectType, handleNext: () => void) => {
  const { track } = useAnalytics();
  const { id: userId, username } = user;
  const name = generateFullName(user);

  const { userId: accountOwnerId, username: accountOwnerUsername } = getAuthUser();

  const [unfollowUser, { loading }] = useUnfollowUserMutation({
    variables: { userId },
    ...getNotifications({
      isToastOpen: true,
      message: `You unfollowed ${name}`,
      onCompleted: () => {
        track(ANALYTICS_EVENTS.UserAction);
        track(ANALYTICS_EVENTS.UserUnfollow, {
          following_id: userId,
          username,
        });
        handleNext();
      },
    }),
    update: (cache) => {
      // remove unfollowUser from accountOwner's followings
      const cachedOwnerFollowingsQuery = getCachedFollowingsOrFollowers<GetUserFollowingsQuery>(
        cache,
        accountOwnerId,
        true
      );
      updateCachedFollowingsWithUser({
        cache,
        cachedTargetQuery: cachedOwnerFollowingsQuery,
        userToAddRemove: user,
        followingsOwnerId: accountOwnerId,
      });

      // remove accountOwner from unfollowUser's followers
      const cachedUnfollowUserFollowersQuery = getCachedFollowingsOrFollowers<GetUserFollowersQuery>(
        cache,
        userId,
        false
      );
      const cachedAccountOwner = getCachedUser(cache, accountOwnerId, accountOwnerUsername);
      updateCachedFollowersWithUser({
        cache,
        cachedTargetQuery: cachedUnfollowUserFollowersQuery,
        userToAddRemove: cachedAccountOwner,
        followersOwnerId: userId,
      });

      cache.modify({
        id: cache.identify({ id: userId, __typename: 'UserObjectType' }),
        fields: {
          isNotificationsEnable: () => false,
          isFollowedByMe: () => false,
        },
      });
    },
    refetchQueries: [
      { query: GetExploreFollowingsDocument, variables: {} },
      { query: GetUserCountersDocument, variables: { userId: accountOwnerId } },
    ],
  });

  return { unfollowUser, loading };
};

export const useGetUserTopics = (id?: string): IUseGetUserTopics => {
  const { data, loading, fetchMore } = useGetUserTopicsQuery({
    variables: { id, offset: 0 },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { items, totalCount } = data?.allUsers?.items[0].followingTags ?? EMPTY_ITEMS;

  const loadMore = () =>
    items &&
    items?.length < totalCount &&
    fetchMore({
      variables: { offset: items?.length },
      updateQuery: (prev, { fetchMoreResult }) => updateUserTopicsQuery(prev, fetchMoreResult),
    });

  return { topics: items ?? [], loading, loadMore };
};

export const useFollowUserOpenTopics = (user: UserObjectType): IUseCheckUserTopics => {
  const { track } = useAnalytics();

  const { isFollowedByMe, id, username } = user;
  const { username: accountOwnerUsername, userId: accountOwnerId } = getAuthUser();

  const [followUser, { loading: followLoading }] = useFollowUserMutation({
    variables: { userId: id },
    ...getNotifications({
      isToastOpen: true,
      message: `You followed ${username}`,
      onCompleted: () => {
        track(ANALYTICS_EVENTS.UserAction);
        track(ANALYTICS_EVENTS.UserFollow, {
          following_id: id,
          username,
        });
      },
    }),
    update: (cache) => {
      // update of accountOwner's followings with user they just followed
      const cachedOwnerFollowingsQuery = getCachedFollowingsOrFollowers<GetUserFollowingsQuery>(
        cache,
        accountOwnerId,
        true
      );
      updateCachedFollowingsWithUser({
        cache,
        cachedTargetQuery: cachedOwnerFollowingsQuery,
        userToAddRemove: user,
        followingsOwnerId: accountOwnerId,
      });

      // update of user's followers with accountOwner
      const cachedUserFollowersQuery = getCachedFollowingsOrFollowers<GetUserFollowersQuery>(cache, id, false);
      const cachedAccountOwner = getCachedUser(cache, accountOwnerId, accountOwnerUsername);
      updateCachedFollowersWithUser({
        cache,
        cachedTargetQuery: cachedUserFollowersQuery,
        userToAddRemove: cachedAccountOwner,
        followersOwnerId: id,
      });

      cache.modify({
        id: cache.identify({ id, __typename: 'UserObjectType' }),
        fields: {
          isNotificationsEnable: () => true,
          isFollowedByMe: () => true,
        },
      });
    },
    refetchQueries: [
      { query: GetExploreFollowingsDocument, variables: {} },
      { query: GetUserCountersDocument, variables: { userId: accountOwnerId } },
    ],
  });

  const openTopics = useOpenTopics();

  const handeFollowUser = ({ isFollowedByUser, hasTopics }: { isFollowedByUser: boolean; hasTopics?: boolean }) => {
    if (isFollowedByUser) {
      openTopics(user);
    } else if (!isFollowedByUser && hasTopics) {
      followUser({ variables: { userId: id } }).then(() => {
        openTopics({ ...user, isFollowedByMe: true }, true);
      });
    } else {
      followUser({ variables: { userId: id } });
    }
  };

  const [checkTopicsQuery, { loading: checkTopicsLoading }] = useCheckUserTopicsLazyQuery({
    variables: { id },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    onCompleted: (data) => {
      const totalCount = data?.allUsers?.items[0].followingTags?.totalCount;
      const hasTopics = !checkTopicsLoading && !!totalCount && totalCount > 0;
      handeFollowUser({ isFollowedByUser: !!isFollowedByMe, hasTopics });
    },
  });

  const handleFollowWithChecks = () => {
    if (isFollowedByMe) {
      handeFollowUser({ isFollowedByUser: isFollowedByMe });
    } else {
      checkTopicsQuery();
    }
  };

  return { handleFollowWithChecks, loading: checkTopicsLoading || followLoading };
};
