import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/UI/MenuButton';

import {
  TEST_ADD_FROM_LINK_ADD_PICK_BTN,
  TEST_ADD_FROM_LINK_CREATE_LIST_BTN,
  TEST_ADD_FROM_LINK_START_SPARK_BTN,
} from 'constants/aqa/addPick';
import { EFeaturedFlags } from 'types/EFeatureFlags';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';
import { ReactComponent as ListIcon } from 'assets/icons/List.svg';
import { ReactComponent as PickFromLinkIcon } from 'assets/icons/PickFromLink.svg';
import { ReactComponent as SparkIcon } from 'assets/icons/AddSpark.svg';
import { useAddContentLinks } from '../helpers/hooks';
import { StyledItem, StyledList } from './styled';

const IContentMenuButtons: FC = () => {
  const { createList, addPick, addSpark } = useAddContentLinks();
  const { replace } = useHistory();

  const isCreateSparkEnabled = useFeaturedFlagEnabled(EFeaturedFlags.CreateSpark);

  return (
    <StyledList>
      <StyledItem>
        <Button
          dataTestId={TEST_ADD_FROM_LINK_ADD_PICK_BTN}
          text="Share a pick"
          Icon={PickFromLinkIcon}
          handler={() => replace(addPick)}
        />
      </StyledItem>
      <StyledItem>
        <Button
          dataTestId={TEST_ADD_FROM_LINK_CREATE_LIST_BTN}
          text="Create a list"
          Icon={ListIcon}
          handler={() => replace(createList)}
        />
      </StyledItem>
      {isCreateSparkEnabled && (
        <StyledItem>
          <Button
            dataTestId={TEST_ADD_FROM_LINK_START_SPARK_BTN}
            text="Start a spark"
            Icon={SparkIcon}
            handler={() => replace(addSpark)}
          />
        </StyledItem>
      )}
    </StyledList>
  );
};

export default IContentMenuButtons;
