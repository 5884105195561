import React, { FC, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { useHistory, useParams } from 'react-router-dom';

import useTheme from 'helpers/useTheme';
import { ROUTE_DISCOVERY } from 'routes';
import { useFeaturedFlagEnabled } from 'helpers/posthogHooks';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { EFeaturedFlags } from 'types/EFeatureFlags';

import Card from 'components/Sparks/Views/Card';
import Badge from 'components/UI/Badge';
import LoaderContent from 'components/UI/LoaderContent';

import { useGetUserId, useProfile } from 'graphQL/profile/hooks';
import { useGetUserSparks } from 'graphQL/sparks/hooks';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { StyledDescription, StyledList, StyledListWrap, StyledTitle, StyledWrap } from './styled';

const AllSparks: FC = () => {
  const { track } = useAnalytics();

  const isViewSparks = useFeaturedFlagEnabled(EFeaturedFlags.ViewSparks);
  const isDark = useTheme();

  const { profileName } = useParams<{ profileName: string }>();
  const { userId } = useGetUserId(profileName);
  const { userData, loading: profileLoading, isQueryCalled } = useProfile(userId);
  const { replace } = useHistory();

  const { items, totalCount, loadMore, loading } = useGetUserSparks();

  useEffect(() => {
    if (!profileLoading && isQueryCalled && !userData) {
      replace({ pathname: ROUTE_DISCOVERY });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileLoading, isQueryCalled, userData]);

  useEffect(() => {
    track(ANALYTICS_EVENTS.AllSparks);
    track(ANALYTICS_EVENTS.SparkAction);
  }, [track]);

  return (
    <StyledWrap>
      {items.length && userData && isViewSparks ? (
        <>
          <StyledDescription>
            <StyledTitle isDark={isDark} total={totalCount}>
              All sparks
            </StyledTitle>
            <Badge isDark={isDark} user={userData} />
          </StyledDescription>
          <StyledListWrap>
            <StyledList>
              {items.map((card) => (
                <li key={card.cardId}>
                  <Card card={card} isDark={isDark} />
                </li>
              ))}
              {!loading && <Waypoint onEnter={loadMore} />}
            </StyledList>
          </StyledListWrap>
        </>
      ) : (
        <LoaderContent isDark={isDark} isFullScreen={!items.length} />
      )}
    </StyledWrap>
  );
};

export default AllSparks;
