import React, { Dispatch, FC, SetStateAction } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { useCollaborators } from 'graphQL/card/creators/hook';
import { ECardRoleTypeOptions } from 'constants/graphqlTypes';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { StyledRoleItem, StyledRoleList, StyledText } from './styled';
import { ROLES_LIST_OPTIONS } from './constants';
import { getRedirectUrl } from './helpers';

interface IListProps {
  role: ECardRoleTypeOptions;
  isCurrentUser: boolean;
  userId: string;
  username: string;
  closeList: () => void;
  noAnotherAdmin: boolean;
  openAlert: (isLeave: boolean) => void;
  setIsCollab: Dispatch<SetStateAction<boolean>>;
  listUserId?: string | null;
}

const List: FC<IListProps> = ({
  role: currentRole,
  userId,
  username,
  closeList,
  isCurrentUser,
  noAnotherAdmin,
  openAlert,
  setIsCollab,
  listUserId,
}) => {
  const { replace } = useHistory();
  const { track } = useAnalytics();

  const { state } = useLocation<{ prevPath: string; variable: { listCardId: string } }>();
  const { prevPath } = state;
  const { listCardId } = state?.variable ?? {};
  const isAdmin = currentRole === ECardRoleTypeOptions.Owner;
  const { handleRemove, handleChange } = useCollaborators(userId, listCardId);

  const handleRole = (newRole: ECardRoleTypeOptions) => {
    if (currentRole !== newRole) {
      if (noAnotherAdmin && isAdmin) {
        setIsCollab(true);
      } else {
        handleChange(newRole);
      }
    }

    closeList();
  };

  const handleCollaborator = (newRole: ECardRoleTypeOptions) =>
    isCurrentUser && noAnotherAdmin ? openAlert(false) : handleRole(newRole);

  const handleLeaveOrRemove = () => {
    if (isAdmin) {
      track(ANALYTICS_EVENTS.CollaborativeList);
      track(noAnotherAdmin ? ANALYTICS_EVENTS.LastAdminLeaveList : ANALYTICS_EVENTS.OneAdminLeaveList);
    }

    if (isCurrentUser) {
      openAlert(true);
    } else {
      handleRemove();
      closeList();
      // case when user whose pick id is used as a list id is removed from the list
      if (userId === listUserId) {
        const url = getRedirectUrl(prevPath, username);
        replace(url);
      }
    }
  };

  return (
    <StyledRoleList>
      {ROLES_LIST_OPTIONS.map(({ title, subtitle, role }) => (
        <StyledRoleItem
          key={role}
          isActive={currentRole === role}
          onClick={() => (isAdmin ? handleRole(role) : handleCollaborator(role))}
        >
          <StyledText>
            {title}
            <br />
            <i>{subtitle}</i>
          </StyledText>
        </StyledRoleItem>
      ))}
      <StyledRoleItem onClick={handleLeaveOrRemove}>
        <StyledText isRed>{isCurrentUser ? 'Leave list' : 'Remove user'}</StyledText>
      </StyledRoleItem>
    </StyledRoleList>
  );
};

export default List;
