import React, { FC, useState, useEffect } from 'react';
import swiper from 'swiper';

import Layout from 'components/Onboarding/Layout';
import Grain from 'components/UI/Grain';

import {
  CheckOnboardingStatusQuery,
  useUpdateUserMutation,
  EOnboardingStepOptions,
  CheckOnboardingStatusDocument,
  UserSettingsSchema,
} from 'constants/graphqlTypes';
import getAuthUser from 'helpers/getAuthUser';
import { useApolloClient } from '@apollo/client';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { EQueryFetchPolicy, OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY } from 'constants/common';
import { TEST_USER_INFO_SUCCESS_TITLE } from 'constants/aqa/signup';

import { StyledBg, StyledHeading } from './styled';
import { getButtonText, getHeadingText } from './helpers/helpers';
import { BG_COLORS } from './helpers/constants';
import Slider from './Slider';

const Success: FC = () => {
  const { track } = useAnalytics();

  const [index, setIndex] = useState(0);
  const [slider, setSlider] = useState<swiper>();
  const [isFirstScreen, setIsFirstScreen] = useState(true);
  const [hideHeading, setHideHeading] = useState(false);
  const [hasSlider, setHasSlider] = useState(false);
  const [bg, setBg] = useState(BG_COLORS[index]);

  const heading = getHeadingText(index, isFirstScreen);

  const [updateUser] = useUpdateUserMutation();

  const client = useApolloClient();
  const { userId } = getAuthUser();

  const cachedData: CheckOnboardingStatusQuery | null = client.readQuery({
    query: CheckOnboardingStatusDocument,
    variables: { id: userId },
  });

  const step = cachedData?.allUsers?.items[0]?.userSettings?.onboardingStep;
  const shouldEndOnboarding = !!step && step === EOnboardingStepOptions.Notifications;

  useEffect(() => {
    if (shouldEndOnboarding) {
      updateUser({
        variables: {
          isOnboardingCompleted: true,
        },
        fetchPolicy: EQueryFetchPolicy.NoCache,
      });
      track(ANALYTICS_EVENTS.isOnboardingTourDone);
    }
  }, [shouldEndOnboarding, updateUser, track]);

  const handler = () => {
    if (isFirstScreen) {
      setHideHeading(true);
      setTimeout(() => setIsFirstScreen(false), 400);
      return;
    }

    if (index > 1) {
      if (shouldEndOnboarding) {
        client.cache.modify({
          id: client.cache.identify({ __typename: 'UserObjectType', id: userId }),
          fields: {
            userSettings: (prev: UserSettingsSchema) => ({
              ...prev,
              isOnboardingCompleted: true,
            }),
          },
        });
      }

      const sharedLink = localStorage.getItem(OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY);
      if (sharedLink) {
        localStorage.removeItem(OFF_PLATFORM_SHARING_REDIRECT_STORAGE_KEY);
        window.location.replace(sharedLink);
      }
    }

    slider?.slideNext();
  };

  const handleSwipeEnd = (newIndex: number) => {
    setIndex(newIndex);
    setHideHeading(false);
  };

  const handleSwipe = (newIndex: number) => {
    setHideHeading(true);
    setBg(BG_COLORS[newIndex]);
  };

  const handleInit = (ev: swiper) => {
    setSlider(ev);
    setHideHeading(false);
    setHasSlider(true);
  };

  return (
    <StyledBg bg={bg} hasSlider={hasSlider}>
      <Grain />
      <Layout secondaryBtnText={getButtonText(index, isFirstScreen)} handleSecondaryBtn={handler}>
        <StyledHeading
          data-testid={TEST_USER_INFO_SUCCESS_TITLE}
          hasPadding={isFirstScreen}
          isHidden={hideHeading}
          isDark
        >
          {heading[0]}
          <br />
          {heading[1]}
          <br />
          {heading[2]}
        </StyledHeading>
        {!isFirstScreen && <Slider handleInit={handleInit} handleSwipe={handleSwipe} handleSwipeEnd={handleSwipeEnd} />}
      </Layout>
    </StyledBg>
  );
};

export default Success;
