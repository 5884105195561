import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { QuestionDetailDocument, useAddAnswerToQotdMutation } from 'constants/graphqlTypes';

export const useAddAnswerToQOTD = (questionId: string, cardId: string) => {
  const { track } = useAnalytics();

  const [addAnswer, { loading }] = useAddAnswerToQotdMutation({
    variables: {
      questionId,
      cardId,
    },
    refetchQueries: [QuestionDetailDocument],
    onCompleted: () => {
      track(ANALYTICS_EVENTS.AnswerQuestion);
      track(ANALYTICS_EVENTS.SparkAction);
    },
    update: (cache, { data }) => {
      const { id, cardStyle, myAnswers } = data?.addCardToQuestion ?? {};
      if (id) {
        cache.modify({
          id: cache.identify({ __typename: 'CardSchema', id }),
          fields: { cardStyle: () => cardStyle, myAnswers: () => myAnswers },
        });
      }
    },
  });

  return {
    addAnswer,
    loading,
  };
};
