import React, { FC, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { Waypoint } from 'react-waypoint';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';

import { Mousewheel, Navigation } from 'swiper';
import { SwiperSlide, Swiper } from 'swiper/react';

import LoaderContent from 'components/UI/LoaderContent';
import QuestionCard from 'components/Card/QuestionCard/QuestionCard';

import useTheme from 'helpers/useTheme';
import { getCurrentDate } from 'graphQL/questions/helper';
import { useQuestionOfDay } from 'graphQL/questions/hooks';

import { ReactComponent as ChevronLeft } from 'assets/icons/ChevronLeft.svg';
import { EMPTY_QUESTION_CARD } from 'constants/common';
import { COLORS } from 'styles/constants';

import {
  StyledWrap,
  StyledTitle,
  StyledSliderWrap,
  StyledSlide,
  StyledSliderButton,
  StyledCardsWrapper,
} from './styled';

const QuestionOfDay: FC = () => {
  const { track } = useAnalytics();

  const [count, setCount] = useState(0);
  const isDark = useTheme();

  const { getQuestions, questionCards, loadMore, loading } = useQuestionOfDay();
  const fontColor = isDark ? COLORS.white[100] : COLORS.brown.dark[100];

  const [array, setArray] = useState([getCurrentDate(count - 1), getCurrentDate(count)]);

  useEffect(() => {
    const { dateFilter } = getCurrentDate(count);
    getQuestions(dateFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    track(ANALYTICS_EVENTS.SparkAction);
    track(ANALYTICS_EVENTS.OpenAllQuestionPage);
  }, [track]);

  const prevBtnClassName = `prev-${v4()}`;
  const nextBtnClassName = `next-${v4()}`;

  return (
    <StyledWrap>
      <StyledTitle isDark={isDark}>Question of the day</StyledTitle>
      <StyledSliderWrap>
        <StyledSliderButton color={fontColor} className={prevBtnClassName}>
          <ChevronLeft />
        </StyledSliderButton>
        <Swiper
          navigation={{
            prevEl: `.${prevBtnClassName}`,
            nextEl: `.${nextBtnClassName}`,
          }}
          onSlideChangeTransitionEnd={(swiper) => {
            if (swiper.isBeginning) {
              setArray((prev) => [getCurrentDate(count - 1), ...prev]);
              swiper.slideTo(1, 0, false);
            }
          }}
          onSlidePrevTransitionStart={() => setCount((prev) => prev - 1)}
          onSlideNextTransitionStart={() => setCount((prev) => prev + 1)}
          speed={600}
          slidesPerView={1}
          initialSlide={1}
          modules={[Mousewheel, Navigation]}
          runCallbacksOnInit={false}
        >
          {array.map(({ dateTitle }) => (
            <SwiperSlide key={dateTitle}>
              <StyledSlide>{dateTitle}</StyledSlide>
            </SwiperSlide>
          ))}
        </Swiper>
        <StyledSliderButton isDisabled={count === 0} color={fontColor} className={nextBtnClassName} shouldRotate>
          <ChevronLeft />
        </StyledSliderButton>
      </StyledSliderWrap>
      <StyledCardsWrapper>
        {!loading && (
          <>
            {questionCards.length === 0 && <QuestionCard card={EMPTY_QUESTION_CARD} isEmpty />}

            {questionCards.map((card) => (
              <QuestionCard key={card.id} card={card} />
            ))}
          </>
        )}
      </StyledCardsWrapper>
      {loading ? <LoaderContent isDark={isDark} isFullScreen /> : <Waypoint onEnter={loadMore} />}
    </StyledWrap>
  );
};

export default QuestionOfDay;
