import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';

import config from 'constants/config';
import getAuthUser from 'helpers/getAuthUser';

export const useFeaturedFlagEnabled = (flag: string): boolean => {
  const { username } = getAuthUser();
  const postHog = usePostHog();

  postHog?.setPersonPropertiesForFlags({ username }, false);
  const flagEnabled = useFeatureFlagEnabled(flag);

  return !config.postHogKey || !!flagEnabled;
};
