import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { CardTypeOptions } from 'constants/graphqlTypes';
import { useContentNotFound } from 'helpers/routingHelper';
import useTheme from 'helpers/useTheme';
import Wrap from 'components/UI/Wrap';
import Header from 'components/GuestList/Header';
import Section from 'components/GuestList/Section/Section';
import LoaderContent from 'components/UI/LoaderContent/LoaderContent';

import { useGuestListHeader, useGuestListSections } from 'graphQL/guestList/hooks';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { StyledSections } from './styled';

const GuestList: FC = () => {
  const { track } = useAnalytics();

  const { id } = useParams<{ id: string }>();
  const isDark = useTheme();
  const handlerContentNotFound = useContentNotFound();

  const { sections, loading, loadMore } = useGuestListSections(id);
  const { card, loading: headerLoading } = useGuestListHeader(id, CardTypeOptions.GuestList);

  if ((!sections && !loading) || (!card && !headerLoading)) {
    handlerContentNotFound();
  }

  useEffect(() => {
    track(ANALYTICS_EVENTS.GuestListAction);
    track(ANALYTICS_EVENTS.GuestListOpen);
  }, [track]);

  return (
    <>
      {card && <Header card={card} isDark={isDark} />}
      <Wrap paddingTop={0}>
        {loading && !sections.length ? (
          <LoaderContent isDark={isDark} isFullScreen />
        ) : (
          <StyledSections>
            {sections.map((section) => (
              <Section key={section.id} section={section} isDark={isDark} />
            ))}
            {loading ? <LoaderContent isDark={isDark} isCentered /> : <Waypoint onEnter={loadMore} />}
          </StyledSections>
        )}
      </Wrap>
    </>
  );
};

export default GuestList;
