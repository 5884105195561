import { useApolloClient } from '@apollo/client';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { EQueryFetchPolicy, REACTIONS_NUMBER_STEP } from 'constants/common';
import { IRefItems } from 'graphQL/models';
import {
  useCreateReactionMutation,
  useGetEmojisQuery,
  useGetRecentReactionsQuery,
  useRemoveReactionMutation,
  ReactionGroupSchema,
  ReactionSymbolSchema,
  useReactToMessageMutation,
  GetChatMessageReactionsDocument,
  GetCommentReactionsListDocument,
  GetDetailListReactionsDocument,
  GetChatIdDocument,
  useGetCardDetailReactionsQuery,
} from 'constants/graphqlTypes';
import useToast from 'helpers/useToast';
import usePageType from 'helpers/usePageType';
import { removeFromItems } from 'graphQL/cardOptions/helpers';
import { useAnimationContext } from 'components/Reactions/Animations/hooks';
import { useReactionContext } from 'components/Reactions/hooks';
import { getCachedUser } from 'graphQL/follows/helpers';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import { useMessagesChatData } from 'components/Messages/helpers/hooks';
import { IGetCommentReactionsList } from 'graphQL/card/comments/models';
import { getCodeFromEmoji } from '../../components/Reactions/helpers/helpers';

export interface IUseSendReactionArgs {
  emoji?: string;
}

export interface IUseRemoveReactionArgs {
  reactionId: string;
  commentId?: string | null;
  cardId?: string | null;
}

export const useSendReaction = (emoji?: string): (() => void) => {
  const { track } = useAnalytics();

  const [send] = useCreateReactionMutation({});
  const { toggleAnimation } = useAnimationContext();
  const { setToast } = useToast();
  const { pickerId, thoughtId: commentId, cardId, messageId, commentatorId } = useReactionContext();
  const { isListDetail } = usePageType();

  const { cache } = useApolloClient();

  const [reactToMessage] = useReactToMessageMutation();
  const { chatId } = useMessagesChatData();

  if (messageId && emoji) {
    return () => {
      reactToMessage({
        variables: { messageId: messageId ?? '', reactionCode: getCodeFromEmoji(emoji) },
        onCompleted: () => {
          toggleAnimation?.(emoji);
        },
        refetchQueries: [
          {
            query: GetChatMessageReactionsDocument,
            variables: {
              chatId,
              messageId,
            },
          },
        ],
      });
    };
  }

  return () => {
    if (!emoji || (!cardId && !commentId)) {
      return;
    }
    send({
      variables: {
        reactionCode: getCodeFromEmoji(emoji),
        pickId: cardId ?? '',
        ...(commentId && { commentId }),
      },
      onCompleted: (data) => {
        const username = pickerId
          ? generateFullName(getCachedUser(cache, pickerId))
          : generateFullName(data?.createReaction?.comments?.items?.[0]?.user);
        toggleAnimation?.(emoji);
        const reactionType = data?.createReaction?.type === 'COLLECTION' ? 'list' : 'pick';

        if (!(isListDetail && reactionType === 'list')) {
          setToast({
            isToastOpen: true,
            toastItemStart: 'Reacted to ',
            toastItemName: `${username}'s`,
            toastItemAltEnding: `${!commentId ? reactionType : `thought`}`,
            linesCount: 1,
            toastEmoji: emoji,
          });
        }
        track(ANALYTICS_EVENTS.ReactionsAction);
        if (commentId) {
          track(ANALYTICS_EVENTS.ThoughtReaction);
        } else {
          track(
            data?.createReaction?.type === 'COLLECTION' ? ANALYTICS_EVENTS.ListReaction : ANALYTICS_EVENTS.CardReaction
          );
        }
      },
      refetchQueries: commentId
        ? [
            {
              query: GetCommentReactionsListDocument,
              variables: {
                pickId: cardId,
                commentId,
                reactionsLimit: REACTIONS_NUMBER_STEP,
                reactionsOffset: 0,
              },
            },
            commentatorId
              ? {
                  query: GetChatIdDocument,
                  variables: { userId: commentatorId, offset: 0 },
                }
              : '',
          ]
        : [
            {
              query: GetDetailListReactionsDocument,
              variables: {
                id: cardId,
                reactionsLimit: REACTIONS_NUMBER_STEP,
                reactionsOffset: 0,
              },
            },
          ],
    });
  };
};

export const useGetRecentReactions = (): ReactionSymbolSchema[] | null | undefined => {
  const { data } = useGetRecentReactionsQuery({ fetchPolicy: EQueryFetchPolicy.CacheAndNetwork });
  return data?.recentReactions;
};

export const useGetEmojis = (): ReactionGroupSchema[] | null => {
  const { data } = useGetEmojisQuery();

  return data?.allEmojis?.items ?? [];
};

export const useRemoveReaction = (): { removeReactionHandler: (args: IUseRemoveReactionArgs) => void } => {
  const [removeReaction] = useRemoveReactionMutation();

  const removeReactionHandler = ({ reactionId, commentId, cardId }: IUseRemoveReactionArgs) => {
    removeReaction({
      variables: { reactionId },
      update: (cache, { data }) => {
        const { id } = data?.removeReaction ?? {};

        cache.modify({
          id: cache.identify({
            id: cardId ? id : commentId,
            __typename: cardId ? 'CardSchema' : 'CardCommentSchema',
          }),
          fields: {
            reactions: (prev: IRefItems) => ({
              ...prev,
              totalCount: prev.totalCount - 1,
              items: removeFromItems(prev.items, reactionId),
            }),
          },
        });
      },
    });
  };

  return { removeReactionHandler };
};

export const useGetCardDetailReactions = (pickId: string, skip?: boolean): IGetCommentReactionsList => {
  const { data, loading, fetchMore, refetch } = useGetCardDetailReactionsQuery({
    variables: { pickId, reactionsOffset: 0, reactionsLimit: REACTIONS_NUMBER_STEP },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: skip || !pickId,
  });

  const reactions = data?.allCards?.items?.[0]?.reactions;
  const fetchedReactions = reactions?.items || [];
  const totalCount = reactions?.totalCount ?? 0;

  const loadMoreReactions = () =>
    fetchedReactions.length < totalCount &&
    fetchMore({
      variables: { offset: fetchedReactions.length, reactionsLimit: fetchedReactions.length + REACTIONS_NUMBER_STEP },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!prev.allCards?.items?.[0]?.reactions?.items || !fetchMoreResult?.allCards?.items[0]?.reactions?.items) {
          return prev;
        }

        return {
          ...prev,
          allCards: {
            ...prev.allCards,
            totalCount: prev.allCards?.totalCount ?? 0,
            items: [
              {
                ...prev.allCards?.items[0],
                reactions: {
                  ...prev.allCards?.items[0].reactions,
                  items: [
                    ...(prev.allCards?.items?.[0]?.reactions?.items ?? []),
                    ...(fetchMoreResult?.allCards?.items[0]?.reactions?.items ?? []),
                  ],
                },
              },
            ],
          },
        };
      },
    });

  const refetchReactions = () => refetch();

  return {
    reactions,
    loading,
    loadMoreReactions,
    refetchReactions,
  };
};
