import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { Waypoint } from 'react-waypoint';
import TruncateMarkup from 'react-truncate-markup';

import Modal from 'components/Modal';
import Button from 'components/UI/Button';

import { useGoBack } from 'helpers/routingHelper';
import { useGetUserTopics, useFollowUserByTopics, useUnfollowUser } from 'graphQL/follows/hooks';
import { TagSchema } from 'constants/graphqlTypes';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { TEST_PROFILE_TOPICS_MODAL_DONE_BTN } from 'constants/aqa/profile';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { generateFullName } from 'components/Profile/ProfileTop/helpers';
import ConfirmationModal from 'components/UI/ConfirmationModal';
import LoaderContent from 'components/UI/LoaderContent';

import { StyledSubtitle } from 'components/UI/ContentSubtitle/styled';
import {
  TEST_ID_CONNECTION_MODAL_UNFOLLOW_CANCEL_BTN,
  TEST_ID_CONNECTION_MODAL_UNFOLLOW_CONFIRM_BTN,
} from 'constants/aqa/profileConnections';
import { ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION } from 'routes';
import Topic from './Topic';
import { StyledTitle, StyledWrap, StyledList, StyledButtonWrap, StyledSubtitleWrap, StyledTitleWrap } from './styled';
import { IUserTopicsLocation } from './models';
import { useTopicsModalText } from './hooks';
import { updateSelectedIds } from './helpers';

const TopicsModal: FC = () => {
  const goBack = useGoBack();
  const { track } = useAnalytics();
  const { push } = useHistory();

  const { state } = useLocation<IUserTopicsLocation>();
  const [isUnfollowInProcess, setIsUnfollowInProcess] = useState(false);
  const { user, isAfterFollow, isOnboardingNetworkingUserSuggestion } = state?.variable ?? {};
  const { id: userId, isFollowedByMe } = user;

  const { topics, loading, loadMore } = useGetUserTopics(userId);

  const { pathname } = useLastLocation() ?? {};
  const { adjustTopics } = useFollowUserByTopics(userId);

  const navigateBack = () =>
    isOnboardingNetworkingUserSuggestion ? push(ROUTE_ONBOARDING_NETWORKING_USER_SUGGESTION) : goBack();

  const hasFade = !pathname?.includes('thoughts') && !pathname?.includes('connections');
  const { unfollowUser, loading: unfollowLoading } = useUnfollowUser(user, navigateBack);
  const [showUnfollowModal, setShowUnfollowModal] = useState(false);

  const { title, subtitle } = useTopicsModalText({ loading, topicsLength: topics.length });

  const [idsToAdd, setIdsToAdd] = useState<string[]>([]);
  const [idsToRemove, setIdsToRemove] = useState<string[]>([]);

  const handlePrimaryButton = () => {
    if (isAfterFollow && !idsToAdd.length && !idsToRemove.length) {
      topics.forEach((item) => {
        track(ANALYTICS_EVENTS.UserFollowingTags, {
          tag: item?.name ?? '',
        });
      });
      navigateBack();
    } else {
      adjustTopics(idsToAdd, idsToRemove).then(() => {
        if (idsToAdd.length) {
          idsToAdd.forEach((item) => {
            const name = topics.find((i) => i.id === item)?.name;
            track(ANALYTICS_EVENTS.UserFollowingTags, {
              tag: name ?? '',
            });
          });
        }
        if (idsToRemove.length) {
          idsToRemove.forEach((item) => {
            const name = topics.find((i) => i.id === item)?.name;
            track(ANALYTICS_EVENTS.UserUnfollowingTags, { tag: name ?? '' });
          });
        }
        navigateBack();
      });
    }
  };

  const handleAccept = () => {
    setIsUnfollowInProcess(true);
    unfollowUser();
  };

  const showPrimaryBtn = !loading && (isAfterFollow || !!idsToAdd.length || !!idsToRemove.length);

  return (
    <Modal
      fadeIn={hasFade}
      fadeOut={hasFade}
      handleClose={isUnfollowInProcess ? () => {} : navigateBack}
      buttonText={isFollowedByMe && !isAfterFollow ? 'Unfollow' : undefined}
      handleDone={isFollowedByMe && !isAfterFollow ? () => setShowUnfollowModal(true) : undefined}
      loading={unfollowLoading}
      isBtnTransparent
    >
      <StyledTitleWrap>
        <TruncateMarkup lines={2}>
          <StyledTitle isDark>{title}</StyledTitle>
        </TruncateMarkup>
      </StyledTitleWrap>
      <StyledSubtitleWrap>
        <StyledSubtitle isDark>{subtitle}</StyledSubtitle>
      </StyledSubtitleWrap>
      <StyledWrap>
        <StyledList>
          {topics.map((item: TagSchema) => (
            <Topic
              key={item.id}
              topic={item}
              updateSelectedIdsList={(topicId, isSelected) =>
                updateSelectedIds({ topicId, isSelected, topics, setIdsToAdd, setIdsToRemove })
              }
            />
          ))}
          {loading ? <LoaderContent isDark isFullScreen={!topics.length} /> : <Waypoint onEnter={loadMore} />}
        </StyledList>
        {showPrimaryBtn && (
          <StyledButtonWrap>
            <Button
              dataTestId={TEST_PROFILE_TOPICS_MODAL_DONE_BTN}
              handler={handlePrimaryButton}
              text={isAfterFollow ? 'Done' : 'Save'}
            />
          </StyledButtonWrap>
        )}
      </StyledWrap>
      {showUnfollowModal && (
        <ConfirmationModal
          handleCancel={() => setShowUnfollowModal(false)}
          handleAccept={handleAccept}
          buttonText="Confirm"
          titleText={`Unfollow ${generateFullName(user)}?`}
          subTitle=""
          dataTestIdConfirmBtn={TEST_ID_CONNECTION_MODAL_UNFOLLOW_CONFIRM_BTN}
          dataTestIdCancelBtn={TEST_ID_CONNECTION_MODAL_UNFOLLOW_CANCEL_BTN}
        />
      )}
    </Modal>
  );
};

export default TopicsModal;
