import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import getValidationError from 'helpers/getValidationError';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';

import { generateName } from 'components/Profile/ProfileTop/helpers';
import { useUpdateUserData } from 'graphQL/profile/update/hooks';
import { EOnboardingStepOptions, useUpdateUserMutation } from 'constants/graphqlTypes';
import { changeLocalStorageUsername } from 'pages/Onboarding/Username/Username';
import usePageType from 'helpers/usePageType';
import config from 'constants/config';

interface IUserChangeProfileName {
  name: string;
  handleChange: Dispatch<SetStateAction<string>>;
  handleError: Dispatch<SetStateAction<string>>;
  handleDone: () => void;
  loadingUserUpdate: boolean;
  error: string;
}

const useChangeProfileName = (handleNext: () => void): IUserChangeProfileName => {
  const { track } = useAnalytics();
  const { isOnboarding } = usePageType();
  const userData = useUpdateUserData();
  const { userInfo } = userData ?? {};
  const { firstName, lastName } = userInfo ?? {};
  const { isNewOnboardingEnabled } = config;
  const fullName = generateName(undefined, firstName, lastName);

  const [updateUser, { loading: loadingUserUpdate }] = useUpdateUserMutation({
    onCompleted: (data) => {
      if (data?.updateUser?.username) {
        changeLocalStorageUsername(data?.updateUser?.username);
        handleNext();
      }
      if (isOnboarding) {
        track(ANALYTICS_EVENTS.OnboardingProfileParametersSave, {
          screen: 'username',
        });
      } else {
        track(ANALYTICS_EVENTS.EditProfile);
        track(ANALYTICS_EVENTS.ChangeName);
      }
    },
  });

  const [name, setName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (fullName) {
      setName(fullName);
    }
  }, [fullName]);

  const handleDone = () => {
    if (!name) {
      setError('Required');
    }
    if (error || !name) {
      return;
    }

    const currentName = name.trim();
    const currentLastName = currentName.substring(name.indexOf(' ') + 1);
    const nameArray = name.split(' ');

    updateUser({
      variables: {
        userInfo: {
          firstName: nameArray[0] ?? '',
          lastName: currentLastName === nameArray[0] && nameArray.length < 2 ? '' : currentLastName,
        },
        onboardingStep: isOnboarding && !isNewOnboardingEnabled ? EOnboardingStepOptions.FullName : undefined,
      },
    }).catch((e) => setError(getValidationError(e)));
  };

  return { name, handleChange: setName, handleError: setError, handleDone, loadingUserUpdate, error };
};

export default useChangeProfileName;
