import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import Truncate from 'react-truncate-markup';
import { useLocation, useParams } from 'react-router-dom';
import Swiper from 'swiper';

import { getWeeklyDateFormat } from 'helpers/getDateFormat';
import { CardSchema, ECardLayoutOptions } from 'constants/graphqlTypes';
import { COLORS } from 'styles/constants';
import {
  TEST_PICK_DETAILS_TITLE_LABEL,
  TEST_PICK_DETAILS_SOURCE_LABEL,
  TEST_PICK_DETAILS_TYPE_SUBJECT_LABEL,
  TEST_PICK_DETAILS_COMMENT_VIEW,
} from 'constants/aqa/pickDetails';
import { EPageTypes, ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import useFixedViewport from 'helpers/useFixedViewport';
import getStyles from 'helpers/getStyles';
import getImages from 'helpers/getImages';
import useDocumentTitle from 'helpers/useDocumentTitle';
import Source from 'components/Card/Common/Source';
import Grain from 'components/UI/Grain';
import ReactionsModal from 'components/Reactions/ReactionsModal';
import InteractionBlock from 'components/InteractionBlock/InteractionBlock';
import { useReactionMenu } from 'components/Reactions/helpers/hooks';
import { useIsUserAllowedToReact } from 'components/Card/helpers/hooks';
import ReactionMenu from 'components/Reactions/ReactionMenu';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';
import getAuthUser from 'helpers/getAuthUser';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { isVideoCard } from 'helpers/isVideoCard';
import { getBrandedIcon } from 'components/Card/helpers/helpers';
import { StyledTweet } from 'components/Card/Common/styled';
import { StyledBio } from 'components/UI/Highlight/styled';
import { REACTION_MENU_CLASSNAME } from 'components/Reactions/ReactionMenu/ReactionMenu';
import Actions from '../Card/Actions';

import {
  StyledContent,
  StyledInfo,
  StyledType,
  StyledWrap,
  StyledTitle,
  StyledDescription,
  StyledImage,
  StyledDetailsWrapper,
  StyledDetailsContent,
  StyledVideoImage,
  StyledImageWrap,
  StyledFooter,
  StyledActionsWrap,
  StyledTweetWrap,
  StyledSourceWrap,
  StyledVideoSourceWrapLink,
  StyledSavedWrap,
  PULSE_ANIMATION,
} from './styled';
import { getDescLines, getTitleLines } from './helpers/helpers';
import { useOpenThoughtSearchParams } from './helpers/hooks';

const CardDetails: FC<{ item: CardSchema; isAnimationStarted?: boolean; swiperInstance?: Swiper | null }> = ({
  item,
  isAnimationStarted,
  swiperInstance,
}) => {
  const { track } = useAnalytics();
  const { state } = useLocation<{ pageType?: EPageTypes }>();
  const { pickId } = useParams<{ pickId: string }>();
  const ref = useRef<HTMLImageElement>(null);

  const aspectRatio = ref.current && ref.current.naturalWidth / ref.current.naturalHeight;
  const hasFullWidth = !!aspectRatio && aspectRatio > 1;

  const {
    id,
    title,
    cardStyle,
    mediaTags,
    tags,
    description,
    sourceName,
    extraData,
    externalCreators,
    linkText,
    isSavedByMe,
    createdAt,
  } = item;
  const { creatorUsername, creatorName } = externalCreators?.items?.[0] ?? {};
  const { userId } = getAuthUser();
  const { bgColor, layout } = getStyles(cardStyle);
  const { imageUrl } = getImages(cardStyle, true);

  const mediaTag = mediaTags?.items[0]?.name;
  const tag = tags?.items?.[0]?.name;
  const isVideo = isVideoCard(item);
  const isTweet = mediaTag === 'tweets';
  const Icon = getBrandedIcon(sourceName);
  const isTiktokVideo = isVideo && sourceName === 'tiktok';
  const plainDescription = description || linkText;
  const isSlider = !!swiperInstance;
  const isCurrentCardInFocus = pickId === id;

  useFixedViewport();
  useOpenThoughtSearchParams(id);
  useDocumentTitle(title, pickId !== id);

  const {
    isReactionsModalOpen,
    reactionCoordinates,
    setReactionCoordinates,
    isQuickReactionOpen,
    setIsQuickReactionOpen,
    setIsReactionsModalOpen,
    infoRef,
    minusTop,
  } = useReactionMenu();

  const {
    thoughtId,
    setThoughtId,
    pickerId,
    setPickerId,
    cardId,
    setCardId,
    user,
    setUser,
  } = useReactionContextState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isCurrentUserPick = useMemo(() => pickerId === userId, [pickerId]);

  const isCardAllowedToReact = useIsUserAllowedToReact(extraData?.items?.[0]?.user);
  const isFallbackAllowedToReact = useIsUserAllowedToReact(user);
  const isCardBlocked = (!thoughtId && !isCardAllowedToReact) || !extraData?.items?.[0]?.user;
  const isFallbackBlocked = !!thoughtId && !isFallbackAllowedToReact;

  const disableLongTap = isCurrentUserPick || isFallbackBlocked || isCardBlocked;

  const editedName = sourceName === 'mastodon' ? creatorName?.split('&')[0] : creatorName;

  useEffect(() => {
    if (isAnimationStarted && isQuickReactionOpen) {
      setIsQuickReactionOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimationStarted, isQuickReactionOpen]);

  useEffect(() => {
    setCardId(id);
    track(ANALYTICS_EVENTS.OpenCardDetail, {
      media_tag: mediaTag,
      source: sourceName,
      navigate_from: state?.pageType,
    });
    track(ANALYTICS_EVENTS.CardAction);
    tags?.items?.forEach((i) => track(ANALYTICS_EVENTS.OpenCardDetailWithTag, { tag: i?.name ?? '' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const srcUrl = item.externalSources?.items?.[0].sourceUrl ?? '#';

  const gotoSrc = () => window.open(srcUrl);

  const goToNextCard = () => {
    if (swiperInstance) {
      swiperInstance?.slideNext(300);
    }
  };

  const savedDate = getWeeklyDateFormat(createdAt);
  const shouldShowImageInDetails = imageUrl && layout !== ECardLayoutOptions.Plain && !isVideo;

  const [isReactionButtonMenuShown, setIsReactionButtonMenuShown] = useState(false);

  const titleFontSize = !imageUrl && !!title && title?.length < 80 ? 32 : 22;

  return (
    <ReactionsProvider
      value={{
        isQuickReactionOpen,
        setThoughtId,
        thoughtId,
        setPickerId,
        pickerId,
        cardId,
        setCardId,
        user,
        setUser,
      }}
    >
      <StyledWrap className="detail" bgColor={bgColor} onClick={() => !isQuickReactionOpen && goToNextCard()}>
        <StyledContent ref={infoRef} disableUserSelect={isQuickReactionOpen}>
          <Grain />
          {reactionCoordinates && reactionCoordinates.x && reactionCoordinates.y && (
            <ReactionMenu
              isShown={isQuickReactionOpen}
              reactionCoordinates={reactionCoordinates}
              handleClickOutside={() => setIsQuickReactionOpen(false)}
              minusTop={minusTop}
              setIsQuickReactionOpen={setIsQuickReactionOpen}
              setIsReactionsModalOpen={setIsReactionsModalOpen}
            />
          )}
          <StyledInfo isCurrentCardInFocus={isCurrentCardInFocus} disableLinks={isQuickReactionOpen}>
            {isVideo &&
              imageUrl &&
              (isSlider ? (
                <div className={PULSE_ANIMATION}>
                  <StyledVideoImage src={imageUrl} isTiktokVideo={isTiktokVideo} />
                </div>
              ) : (
                <StyledVideoSourceWrapLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href={srcUrl}
                  onClick={(e) => (isQuickReactionOpen ? e.preventDefault() : false)}
                  className={PULSE_ANIMATION}
                >
                  <StyledVideoImage src={imageUrl} isTiktokVideo={isTiktokVideo} />
                </StyledVideoSourceWrapLink>
              ))}
            {mediaTag && (
              <StyledType data-testid={TEST_PICK_DETAILS_TYPE_SUBJECT_LABEL} isVideo={isVideo}>
                {isTweet && 'post'}
                {!isTweet && mediaTag} {tag && (!Icon || mediaTag === 'video') ? `· ${tag}` : ''}
              </StyledType>
            )}
            {!!Icon && (
              <StyledTweetWrap>
                <StyledTweet>
                  <Icon viewBox="0 0 24 24" height="13" width="13" />
                  {editedName}
                  {creatorUsername && <StyledBio>@{creatorUsername.replace('@', '')}</StyledBio>}
                </StyledTweet>
              </StyledTweetWrap>
            )}
            <InteractionBlock
              tapCallback={() => {
                setIsQuickReactionOpen(false);
              }}
              longTapCallback={() =>
                !isReactionButtonMenuShown && !isQuickReactionOpen && !disableLongTap && setIsQuickReactionOpen(true)
              }
              getTapCoordinates={(x, y) => !isReactionButtonMenuShown && setReactionCoordinates({ x, y })}
            >
              <StyledDetailsWrapper>
                <StyledDetailsContent>
                  <StyledTitle isVideo={isVideo} titleFontSize={titleFontSize}>
                    <Truncate
                      lines={getTitleLines({
                        height: window.innerHeight,
                        hasImage: !!imageUrl,
                        hasText: !!description,
                        fontSize: titleFontSize,
                      })}
                      ellipsis={isTweet ? '[...]' : '...'}
                    >
                      <span data-testid={TEST_PICK_DETAILS_TITLE_LABEL}>{title}</span>
                    </Truncate>
                  </StyledTitle>
                  <StyledSourceWrap
                    key={`StyledSourceWrap-${isQuickReactionOpen}`}
                    showPointer={!isQuickReactionOpen}
                    onClick={() => !isSlider && !isQuickReactionOpen && gotoSrc()}
                    className={shouldShowImageInDetails ? PULSE_ANIMATION : undefined}
                  >
                    {shouldShowImageInDetails && (
                      <StyledImageWrap isImageSmall={!isVideo}>
                        <StyledImage ref={ref} src={imageUrl} hasFullWidth={hasFullWidth} />
                      </StyledImageWrap>
                    )}
                    {(layout === ECardLayoutOptions.Plain || !imageUrl) && plainDescription && (
                      <StyledDescription>
                        <Truncate lines={getDescLines(window.innerHeight)}>
                          <span>{plainDescription}</span>
                        </Truncate>
                      </StyledDescription>
                    )}
                  </StyledSourceWrap>
                  <StyledFooter color={COLORS.white[100]} isVideo={isVideo}>
                    <Source dataTestId={TEST_PICK_DETAILS_SOURCE_LABEL} card={item} stopPropagationOnClick={isSlider} />
                  </StyledFooter>
                  {isSavedByMe && (
                    <StyledSavedWrap>
                      You saved this pick {savedDate}. It has not yet been shared to your profile. Use the ‘+’ button to
                      share this pick.
                    </StyledSavedWrap>
                  )}
                  {!isSavedByMe && (
                    <StyledActionsWrap
                      data-testid={TEST_PICK_DETAILS_COMMENT_VIEW}
                      disableLinks={isQuickReactionOpen}
                      onClickCapture={(e) => {
                        if (
                          (isQuickReactionOpen || isReactionButtonMenuShown) &&
                          e.target instanceof HTMLElement &&
                          !e.target.closest(`.${REACTION_MENU_CLASSNAME}`)
                        ) {
                          e.stopPropagation();
                          e.preventDefault();
                          setIsReactionButtonMenuShown(false);
                        }
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Actions
                        isReactionButtonMenuShown={isReactionButtonMenuShown}
                        setIsReactionButtonMenuShown={setIsReactionButtonMenuShown}
                        setIsReactionsModalOpen={setIsReactionsModalOpen}
                        isReactionsModalOpen={isReactionsModalOpen}
                        enableConstantHeight
                        item={item}
                        fontColor={COLORS.white[100]}
                        isDetail
                      />
                    </StyledActionsWrap>
                  )}
                </StyledDetailsContent>
              </StyledDetailsWrapper>
            </InteractionBlock>
          </StyledInfo>
        </StyledContent>
        {isReactionsModalOpen && <ReactionsModal id={id} handleClose={() => setIsReactionsModalOpen(false)} />}
      </StyledWrap>
    </ReactionsProvider>
  );
};

export default CardDetails;
