import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { getImageUrl } from 'helpers/getImages';
import { useThought } from 'graphQL/card/comments/hooks';
import { useMoveToList } from 'graphQL/addContent/addToList/hooks';
import { usePickMutation, useUpdateStyle } from 'graphQL/cardOptions/hooks';
import {
  useGetCoverPickPreviewQuery,
  CardSchema,
  ColorSchema,
  ECardLayoutOptions,
  ImageTypeOptions,
} from 'constants/graphqlTypes';
import { useMoveSavedToPicks } from 'graphQL/savedPicks/hooks';
import { useLink } from 'helpers/routingHelper';

import {
  ROUTE_ADD_CONTENT,
  ROUTE_ADD_LIST,
  ROUTE_ADD_PICK,
  ROUTE_ADD_SPARK,
  ROUTE_ADD_SPARK_STYLE,
  ROUTE_ADD_STYLE,
  ROUTE_ADD_STYLE_AND_THOUGHT,
  ROUTE_ADD_THOUGHT,
  ROUTE_ADD_TO_LIST,
  ROUTE_ANSWER_ADD_STYLE,
  ROUTE_ANSWER_ADD_THOUGHT,
  ROUTE_CREATE_LIST,
  ROUTE_NICE_FIND,
  ROUTE_REPORT_STYLE_ISSUE,
  ROUTE_REPORT_STYLE_OTHER,
  ROUTE_SPARK_RESP_ADD_THOUGHT,
  ROUTE_STYLE_DESCRIPTION,
} from 'routes';
import usePageType from 'helpers/usePageType';
import useNavigateWithStateVars from 'helpers/useNavigateWithStateVars';
import {
  IAddContentLocation,
  IStyleItem,
  IUseAddContentLinks,
  IUseExtractData,
  IUseFinishAddContent,
  IUseOfferReportLinks,
  IUseStyleItems,
  ISetExtractData,
  IUseFinishAddContentArgs,
  ListRoutingParams,
} from './models';
import { convertCardStyleToCardStyleInput, createStyleItems } from './helpers';

export const useAddContentLinks = (): IUseAddContentLinks => ({
  createList: useLink(ROUTE_CREATE_LIST),
  addToList: useLink(ROUTE_ADD_TO_LIST),
  addPick: useLink(ROUTE_ADD_PICK),
  addList: useLink(ROUTE_ADD_LIST),
  addStyle: useLink(ROUTE_ADD_STYLE),
  addThought: useLink(ROUTE_ADD_THOUGHT),
  home: useLink(ROUTE_ADD_CONTENT),
  addSpark: useLink(ROUTE_ADD_SPARK),
  niceFind: useLink(ROUTE_NICE_FIND),
  addSparkStyle: useLink(ROUTE_ADD_SPARK_STYLE),
  addStyleAndThought: useLink(ROUTE_ADD_STYLE_AND_THOUGHT),
});

export const useOfferReportLinks = (): IUseOfferReportLinks => ({
  styleDescription: useLink(ROUTE_STYLE_DESCRIPTION),
  reportStyleIssue: useLink(ROUTE_REPORT_STYLE_ISSUE),
  reportStyleOther: useLink(ROUTE_REPORT_STYLE_OTHER),
});

export const useStyleItems = (itemId: number, color: string, item?: CardSchema): IUseStyleItems => {
  const { linkImageSets } = item ?? {};

  const showImages = linkImageSets?.items?.some(({ images }) => images?.[0]?.url);
  const items = createStyleItems([
    ...(showImages && linkImageSets?.items ? linkImageSets.items : []),
    ...(linkImageSets?.items[0]?.colors?.sourceGradient?.items ?? []),
  ]);

  const [currentItem, setCurrentItem] = useState<IStyleItem | undefined>();
  const [itemColors, setItemColors] = useState<ColorSchema[]>([]);
  const [currentColor, setCurrentColor] = useState<ColorSchema | undefined>();

  useEffect(() => {
    if (items.length) {
      setCurrentItem(items.find(({ id }) => id === itemId) ?? items[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, items.length]);

  useEffect(() => {
    setItemColors(currentItem?.colors ?? []);
  }, [currentItem]);

  useEffect(() => {
    setCurrentColor(itemColors.find(({ colorValue }) => colorValue === color) ?? itemColors[0]);
  }, [itemColors, color]);

  return {
    currentItem,
    items: items.length ? items : Array(3).fill({ image: { images: [{ url: '#' }] } }),
    itemColors,
    currentColor,
  };
};

export const useFinishAddContent = ({
  pickId,
  cardId,
  thought,
  thoughtId,
  isUpdateThought,
  listTitle,
  close,
  listCardId,
  item,
  onLockedError,
  shouldRedirectToList,
  listRouteId,
  isPrivateList,
  isSavedByMe,
}: IUseFinishAddContentArgs): IUseFinishAddContent => {
  const { track } = useAnalytics();
  const { state } = useLocation<IAddContentLocation | undefined>();
  const { isDetail, pickId: statePickId, cardId: stateCardId } = state?.variable ?? {};

  const { cardStyle, isPickedByMe } = item ?? {};

  const { thumbnailImageSet, layout } = cardStyle ?? {};
  const image = getImageUrl({
    images: thumbnailImageSet?.images,
    type: ImageTypeOptions.Preview,
  });

  const { handleAddThought, update, loading: thoughtLoading } = useThought({
    commentId: thoughtId,
    text: thought,
    pickId,
    onLockedError,
    currentUserPickId: pickId,
  });

  const { pickCard, loading: pickLoading } = usePickMutation({
    pickId,
    cardId,
    listId: listCardId && !isDetail ? listCardId : undefined,
    listTitle,
    image: layout === ECardLayoutOptions.Plain ? undefined : image,
    shouldRedirectToList,
    listRouteId,
    shouldHideShareButton: isPrivateList,
  });

  const { updateCard, updateLoading } = useUpdateStyle({ pickId, cardId, item, onLockedError });

  const thoughtCallback = () =>
    isUpdateThought
      ? update().then(({ errors }) => !errors && close())
      : handleAddThought().then(({ errors }) => !errors && close());

  const { moveToList, loading: moveToListLoading } = useMoveToList({
    pickId,
    cardId,
    listTitle,
    listCardId,
    image: layout === ECardLayoutOptions.Plain ? undefined : image,
    onCompleted: thought || isUpdateThought ? thoughtCallback : close,
    onLockedError,
  });

  const { moveSavedToPicks, loading: savedLoading } = useMoveSavedToPicks({
    listCardId,
    listId: listRouteId,
    listTitle,
    cardImageUrl: image,
    data: [
      {
        cardId,
        cardStyle: convertCardStyleToCardStyleInput(cardStyle),
        commentText: thought || undefined,
      },
    ],
  });

  const updateCallback = () => {
    if (isSavedByMe) {
      moveSavedToPicks();
      return;
    }

    if (isPickedByMe && listCardId && isDetail) {
      moveToList({ variables: { pickId, currentListId: listCardId, listCardId } });
    } else {
      pickCard()
        .then(() => updateCard())
        .then(({ data }) => {
          const pickIdFromMutation = data?.updateCard?.id;
          if (isUpdateThought) {
            update().then(({ errors }) => !errors && close(pickIdFromMutation));
          } else if (thought) {
            handleAddThought({
              text: thought.trim(),
              pickId: pickIdFromMutation ?? pickId,
              currentUserPickId: pickIdFromMutation ?? pickId,
            }).then(({ errors }) => !errors && close(pickIdFromMutation));
          } else {
            close(pickIdFromMutation);
          }

          if (statePickId && stateCardId) {
            track(ANALYTICS_EVENTS.AddCard);
            track(ANALYTICS_EVENTS.AddPage, {
              media_tag: data?.updateCard?.mediaTags?.items?.[0].name,
              source: data?.updateCard?.sourceName,
            });
          }
        });
    }
  };

  const loading = pickLoading || updateLoading || moveToListLoading || thoughtLoading || savedLoading;

  return { handleFinish: updateCallback, loading };
};

export const useExtractData = (): IUseExtractData => {
  const [pickId, setPickId] = useState('');
  const [cardId, setCardId] = useState('');
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');

  const setExtractData = ({ extractCardId, extractPickId, extractTitle, extractImage }: ISetExtractData) => {
    if (extractPickId) {
      setPickId(extractPickId);
    }

    if (extractCardId) {
      setCardId(extractCardId);
    }

    if (extractTitle) {
      setTitle(extractTitle);
    }

    if (extractImage) {
      setImage(extractImage);
    }
  };

  return { setExtractData, pickId, cardId, image, title };
};

export const useNavigateFromCreatedList = (
  setListTitle: Dispatch<SetStateAction<string>>,
  setListToAdd: Dispatch<SetStateAction<string>>,
  setlistRoutingParams?: Dispatch<SetStateAction<ListRoutingParams>>
): ((collectionId?: string, createdListTitle?: string | null) => void) => {
  const {
    isPickAddStyle,
    isPickAddThought,
    isAnswerAddStyle,
    isAnswerAddThought,
    isAddSparkResponseStyle,
    isAddSparkResponseThought,
  } = usePageType();

  const { state, pathname } = useLocation<IAddContentLocation | undefined>();
  const { variable } = state ?? {};
  const { isPickFlow, isMovePick, isSavedPick, isFromNiceFind } = variable ?? {};

  const [currentStep, setCurrentStep] = useState<null | 'addStyle' | 'addThought'>(null);

  const isCurrentStepStyle = isPickAddStyle || isAnswerAddStyle || isAddSparkResponseStyle;
  const isCurrentStepThought = isPickAddThought || isAnswerAddThought || isAddSparkResponseThought;

  useEffect(() => {
    if (isCurrentStepStyle) {
      setCurrentStep('addStyle');
    } else if (isCurrentStepThought || (isPickFlow && !isSavedPick)) {
      setCurrentStep('addThought');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, currentStep, isPickAddStyle, isAnswerAddStyle, isPickFlow, isPickAddThought, isAnswerAddThought]);

  const { isAddAnswer, isAddSparkResponse } = usePageType();
  const navigateFromCreateList = useNavigateWithStateVars();

  return (listCardId?: string, createdListTitle?: string | null, id = '', isPrivateList = false) => {
    setListTitle(createdListTitle || '');
    setListToAdd(listCardId ?? '');
    if (setlistRoutingParams) {
      setlistRoutingParams({ id, isPrivateList });
    }

    const getRoute = () => {
      if (isAddAnswer) return currentStep === 'addThought' ? ROUTE_ANSWER_ADD_THOUGHT : ROUTE_ANSWER_ADD_STYLE;

      if (isAddSparkResponse) return ROUTE_SPARK_RESP_ADD_THOUGHT;

      return currentStep === 'addThought' || isMovePick ? ROUTE_ADD_THOUGHT : ROUTE_ADD_STYLE;
    };

    if (isFromNiceFind) {
      navigateFromCreateList(ROUTE_ADD_STYLE_AND_THOUGHT, { isFromNiceFind: true, prevPath: ROUTE_ADD_TO_LIST });
    } else {
      navigateFromCreateList(getRoute());
    }
  };
};

export const useGetCoverPickPreview = (pickId: string): { item?: CardSchema; loading: boolean } => {
  const { data, loading } = useGetCoverPickPreviewQuery({
    variables: {
      id: pickId,
    },
    skip: !pickId,
  });
  const item = data?.allCards?.items[0];

  return { item, loading };
};
