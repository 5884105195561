import React, { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import ListGrid from 'components/List/Grid';
import getAuthUser from 'helpers/getAuthUser';
import useDocumentTitle from 'helpers/useDocumentTitle';
import useReferrerId from 'helpers/useReferrerId';
import ListHeader from 'components/List/Header';
import ListContent from 'components/List/Content';
import ImmersiveCover from 'components/ImmersiveCover';

import { useContentNotFound } from 'helpers/routingHelper';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { useGetDetailList, useGetListItems } from 'graphQL/list/hooks';
import { useReadActivity } from 'graphQL/activity/hooks';
import { DEFAULT_GRADIENT, getListStyles } from 'components/Collection/helpers';
import { getCoverGradient } from 'components/List/Header/helper';
import { EQueryFetchPolicy } from 'constants/common';
import { AnimationProvider, useAnimationToggle } from 'components/Reactions/Animations/hooks';
import { ReactionsProvider, useReactionContextState } from 'components/Reactions/hooks';
import { useIsUserAllowedToReact } from 'components/Card/helpers/hooks';
import { EViewTypeOptions } from 'constants/graphqlTypes';

const ListDetail: FC = () => {
  const { track } = useAnalytics();
  const { listId } = useParams<{ listId: string }>();
  const { goBack } = useHistory();
  const handlerContentNotFound = useContentNotFound();

  const { card, loading } = useGetDetailList({
    id: listId,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    isDetail: true,
  });
  const { cardStyle, creators, isCollaborative, title, description, isPrivateList, invite, reactions } = card ?? {};
  const { user } = creators?.items[0] ?? {};
  const { userId } = getAuthUser();
  const { items, loadMore, loading: loadingItems, totalCount } = useGetListItems({ id: listId });
  const { images, titles } = getListStyles(card, true);
  const { viewType } = cardStyle ?? {};

  const isMyList = user?.id === userId;
  const bgColor = getCoverGradient(items, !!images.length) ?? DEFAULT_GRADIENT;
  const isUserAllowedToReact = useIsUserAllowedToReact(user);
  const reffererId = useReferrerId();

  useDocumentTitle(title);
  useReadActivity();

  if (!listId) {
    goBack();
  }

  if (!card && !loading) {
    handlerContentNotFound();
  }
  const { isPlaying, toggleAnimation } = useAnimationToggle();

  const { cardId, setCardId, setPickerId, pickerId } = useReactionContextState();
  const hasReactions = !isMyList && isUserAllowedToReact && !isCollaborative;

  useEffect(() => {
    setPickerId(creators?.items?.[0]?.user?.id ?? reffererId ?? null);
    setCardId(listId ?? null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (card && !loading) {
      track(ANALYTICS_EVENTS.ListAction);
      track(ANALYTICS_EVENTS.OpenList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card, loading]);

  const avatars =
    creators?.items.reduce((acc: string[], item) => {
      const url = item?.user?.userStyle?.thumbnailImageSet?.images?.[0]?.url;

      return [...acc, ...(url ? [url] : [])];
    }, []) ?? [];

  return card ? (
    <ReactionsProvider value={{ cardId, setCardId, setPickerId, pickerId }}>
      <AnimationProvider value={{ isPlaying, toggleAnimation }}>
        {viewType === EViewTypeOptions.Immersive ? (
          <>
            {listId && (
              <ImmersiveCover
                avatars={avatars}
                loading={loading}
                totalAvatars={creators?.items.length ?? 0}
                id={listId}
                title={title}
                description={description}
                total={totalCount}
                bgItems={images}
                firstItemId={items?.[0]?.id}
                hasReaction={hasReactions}
              />
            )}
          </>
        ) : (
          <>
            <ListHeader titles={titles} images={images} bgColor={bgColor} hasReactions={hasReactions} isCursorGrab />
            {!loadingItems && (
              <ListContent
                listCardId={card.cardId}
                title={title}
                totalCount={totalCount}
                user={user}
                description={description}
                isPrivate={isPrivateList}
                isCollaborative={isCollaborative}
                creators={creators}
                invite={invite}
                reactions={reactions}
              />
            )}
            <ListGrid
              hideActionsInfo={!isCollaborative && isMyList}
              showActivity={isCollaborative}
              layout={cardStyle?.layoutCollection}
              items={items}
              loadMore={loadMore}
              loading={loadingItems}
              isInCollaborativeList={!!card.isCollaborative}
            />
          </>
        )}
      </AnimationProvider>
    </ReactionsProvider>
  ) : null;
};

export default ListDetail;
