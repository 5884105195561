import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Location } from 'history';

import getAuthUser from 'helpers/getAuthUser';
import useToast from 'helpers/useToast';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';

import { transformRoute } from 'helpers/routingHelper';
import { ROUTE_LIST_INVITE, ROUTE_OFF_PLATFORM_SHARING, ROUTE_REPORT, ROUTE_WELCOME } from 'routes';
import { ALL_PICKS, EQueryFetchPolicy, ESearchParams } from 'constants/common';
import { IRefItems } from 'graphQL/models';
import { updatePickedCards, updatePickedCard } from 'graphQL/profile/helper';
import {
  CardTypeOptions,
  CardSchema,
  usePickCardMutation,
  useUnpickCardMutation,
  useDeleteItemMutation,
  useReportMutation,
  useGetReportsQuery,
  useUpdateCardMutation,
  GetAllPicksCoverDocument,
  GetDetailCardDocument,
  GetExtractionCardDocument,
  GetExploreFollowingsDocument,
  GetExploreEveryoneDocument,
  GetDetailListDocument,
  GetSparkCardsDocument,
  GetSparkDetailCardsDocument,
  MessageType,
  GetUserProfileDocument,
  GetUserRecentCardDocument,
} from 'constants/graphqlTypes';
import { IFollowingKey } from 'providers/helpers/models';
import { FollowingTabKeyContext } from 'providers/helpers/contexts';

import { useGetTotalCountCards } from 'graphQL/profile/hooks';
import { addItemToList } from 'graphQL/addContent/addToList/helpers';
import { ApolloError } from '@apollo/client';
import { getPathName } from 'components/Card/helpers/helpers';
import {
  checkHasErrorWithCode,
  getNotifications,
  getReportType,
  removeFromItems,
  updateMessageOnListDeletion,
} from './helpers';
import { IInviteModalState, IPickData, IReportData, IUseReports, IUseUpdateStyleArgs } from './models';
import { setStyleInput } from '../card/update/helpers';

export const useUpdateStyle = ({ pickId, cardId, item, onLockedError }: IUseUpdateStyleArgs) => {
  const { userId } = getAuthUser();
  const { state } = useLocation<{
    backgroundModal: { pathname: string };
    variable?: { listPickId: string };
  }>();
  const { listPickId } = state?.variable ?? {};

  const [updateCard, { loading: updateLoading }] = useUpdateCardMutation({
    variables: { id: cardId, cardStyle: setStyleInput(item) },
    refetchQueries: [
      { query: GetAllPicksCoverDocument, variables: { id: userId } },
      {
        query: GetDetailCardDocument,
        variables: { id: pickId, refListId: listPickId },
      },
      { query: GetExploreFollowingsDocument, variables: {} },
      { query: GetExploreEveryoneDocument, variables: {} },
      listPickId
        ? {
            query: GetDetailListDocument,
            variables: { id: listPickId },
          }
        : '',
    ],
    onError: (error: ApolloError) => {
      if (checkHasErrorWithCode(error, 423)) {
        onLockedError?.();
      }
    },
  });

  return { updateCard, updateLoading };
};

export const usePickMutation = ({
  pickId,
  cardId,
  listId,
  listTitle,
  image,
  shouldRedirectToList,
  listRouteId,
  shouldHideShareButton,
}: IPickData) => {
  const { track } = useAnalytics();
  const { resetFollowingTabKey } = useContext<IFollowingKey>(FollowingTabKeyContext);
  const { setToast } = useToast();
  const { userId, username } = getAuthUser();
  const { push, replace } = useHistory();
  const { state } = useLocation<{
    backgroundModal: { pathname: string };
    variable?: { listPickId: string };
  }>();
  const isPickFromProfile = state?.backgroundModal?.pathname.includes(username);
  const { listPickId } = state?.variable ?? {};

  const { totalCount: cardsCount = 0 } = useGetTotalCountCards(userId, { type: CardTypeOptions.Card });
  const { totalCount: listsCount = 0 } = useGetTotalCountCards(
    userId,
    { type: CardTypeOptions.Collection },
    EQueryFetchPolicy.NoCache
  );

  const hasThreeCards = cardsCount >= 3;
  const toastType = isPickFromProfile ? undefined : CardTypeOptions.Card;
  const onlyPickMessage = hasThreeCards ? ALL_PICKS : 'Profile';
  const hasAllPicks = !!(hasThreeCards && listsCount);
  const onlyPickType = hasAllPicks ? CardTypeOptions.Card : toastType;

  const handleShareContent = (id?: string) =>
    id &&
    push({
      pathname: ROUTE_OFF_PLATFORM_SHARING,
      search: `?${ESearchParams.Type}=${MessageType.Pick}&${ESearchParams.Id}=${id}`,
    });

  const listUrl = getPathName(listRouteId, true, hasAllPicks);

  const [pickCard, { loading, data: response }] = usePickCardMutation({
    ...getNotifications({
      action: `Added to ${hasThreeCards || listId ? '' : 'your'}`,
      message: listId && listTitle ? listTitle : onlyPickMessage,
      image,
      type: listId ? CardTypeOptions.Collection : onlyPickType,
      onCompleted: () => {
        setTimeout(() => resetFollowingTabKey?.(), 1000);
      },
      toastLinkText: shouldHideShareButton ? undefined : 'Share',
      handleToastButton: () => handleShareContent(response?.pickCard?.id),
      toastHandler: shouldRedirectToList ? () => replace(listUrl) : undefined,
    }),
    variables: { cardId, pickId, userId, listId },
    refetchQueries: [
      listId
        ? {
            query: GetDetailListDocument,
            variables: {
              id: listId,
              coverLimit: undefined,
              isDetail: true,
            },
          }
        : '',
      { query: GetUserRecentCardDocument, variables: { userId } },
    ],
    update: (cache, { data }) => {
      if (listId) {
        setToast({ toastId: listId });
      }
      if (listPickId && !data?.pickCard?.isPickedByUsersInThisList?.totalCount) {
        addItemToList({ cache, listId: listPickId, pickId });
      }
      updatePickedCard({ cache, pickId, isPick: true, data });

      const pickedCardId = data?.pickCard?.id;

      // update of user.pickedCards
      updatePickedCards(cache, userId, true, pickedCardId);
      if (data?.pickCard?.tags?.items?.length) {
        data?.pickCard?.tags?.items.forEach((item) =>
          track(ANALYTICS_EVENTS.AddPickWithTags, { tag: item?.name ?? '' })
        );
      }
    },
  });

  return { pickCard, loading };
};

export const useUnpickMutation = (pickId: string, sparkId?: string, sparkCardId?: string): (() => void) => {
  const { userId } = getAuthUser();
  const { track } = useAnalytics();

  const [mutation] = useUnpickCardMutation({
    ...getNotifications({
      message: `Pick Removed`,
      onCompleted: () => {
        track(ANALYTICS_EVENTS.CardUnpick);
      },
    }),
    refetchQueries: [
      GetExtractionCardDocument,
      { query: GetAllPicksCoverDocument, variables: { id: userId } },
      { query: GetExploreEveryoneDocument, variables: {} },
      { query: GetSparkCardsDocument },
      ...(sparkId && sparkCardId ? [{ query: GetSparkDetailCardsDocument, variables: { sparkId, sparkCardId } }] : []),
      { query: GetUserProfileDocument, variables: { id: userId } },
    ],
    variables: { pickId },
    update: (cache) => {
      updatePickedCard({ cache, pickId, isPick: false });
      cache.modify({
        id: cache.identify({ id: userId, __typename: 'UserObjectType' }),
        fields: {
          pickedCards: (prev: IRefItems) => ({
            ...prev,
            items: removeFromItems(prev.items, pickId),
            totalCount: prev.totalCount ? prev.totalCount - 1 : prev.totalCount,
          }),
        },
      });
    },
  });

  return () => mutation();
};

export const useDeleteItem = (cardId: string, card?: CardSchema) => {
  const { track } = useAnalytics();

  const { userId } = getAuthUser();

  const [deleteItem, { loading }] = useDeleteItemMutation({
    ...getNotifications({
      message: `List deleted`,
      onCompleted: () => {
        track(ANALYTICS_EVENTS.ListAction);
        track(ANALYTICS_EVENTS.DelList);
      },
    }),
    variables: { cardId },
    update: (cache) => {
      updateMessageOnListDeletion(cache, card);
      return updatePickedCards(cache, userId, false);
    },
  });

  return { deleteItem, loading };
};

export const useReports = (variable: IReportData, onCompletedAfterReport?: () => void): IUseReports => {
  const { track } = useAnalytics();
  const { userCardId, userId, cardId, commentId, inviteId, chatId, reportComment } = variable;
  const { setToast } = useToast();
  const { replace } = useHistory();

  const id = inviteId ? cardId : userCardId || cardId;

  const { data } = useGetReportsQuery({
    variables: {
      reportType: getReportType(variable),
    },
  });

  const [sendReport] = useReportMutation();

  const options =
    data?.reportQuestions?.reduce(
      (acc: { id: string; title: string }[], option) =>
        option?.id && option.title ? acc.concat({ id: option.id, title: option.title }) : acc,
      []
    ) ?? [];

  const sendLeaveReport = (leaveQuestionIds?: string[], leaveComment?: string) => {
    if (leaveQuestionIds || leaveComment) {
      sendReport({
        variables: { ...(leaveComment && { leaveComment }), ...(leaveQuestionIds?.length && { leaveQuestionIds }) },
      })
        .then(() => replace(ROUTE_WELCOME))
        .catch((e) =>
          setToast({
            isToastOpen: true,
            toastItemName: e.message,
            toastAction: '',
            toastImg: null,
            toastError: true,
          })
        );
    }
  };

  const report = (questionId: string) => {
    if (id || chatId) {
      sendReport({
        variables: { cardId: id, userId, commentId, inviteId, questionId, chatId, reportComment },
        onCompleted: onCompletedAfterReport,
      })
        .then(() =>
          setToast({
            isToastOpen: true,
            toastItemName: `Report sent${reportComment || variable.type === 'EXTRACT_REPORT' ? ' to guide:human' : ''}`,
            toastAction: '',
            toastImg: null,
          })
        )
        .then(() => {
          if (inviteId) {
            track(ANALYTICS_EVENTS.InvitationReport);
          }
        })
        .catch((e) =>
          setToast({
            isToastOpen: true,
            toastItemName: e.message,
            toastAction: '',
            toastImg: null,
            toastError: true,
          })
        );
    }
  };

  return {
    options,
    report: (questionId: string, leaveQuestionIds?: string[], leaveComment?: string) =>
      variable.isLeaveReport ? sendLeaveReport(leaveQuestionIds, leaveComment) : report(questionId),
  };
};

export const useNavigateToReport = (variable?: IReportData): (() => void) => {
  const location = useLocation<{ backgroundModal: Location<undefined> }>();

  const { replace } = useHistory();
  const backgroundModal = location?.state?.backgroundModal ?? location;

  return () => {
    replace({
      pathname: ROUTE_REPORT,
      state: {
        ...location.state,
        backgroundModal,
        ...(variable && { variable }),
      },
    });
  };
};

export const useNavigateToInvite = (variable: IInviteModalState): (() => void) => {
  const location = useLocation();
  const { push } = useHistory();

  return () => {
    push({
      pathname: transformRoute(ROUTE_LIST_INVITE, { inviteId: variable.inviteId }),
      state: {
        ...location.state,
        backgroundModal: location,
        variable,
      },
    });
  };
};
