import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { ROUTE_QUESTION_OF_THE_DAY } from 'routes';

import { StyledQuestionSlide, StyledBrowseAllQuestions } from './styled';

const BrowseAll: FC<{ isDark: boolean }> = ({ isDark }) => {
  const { track } = useAnalytics();
  const { push } = useHistory();

  const handlerAll = () => {
    track(ANALYTICS_EVENTS.FeaturedOpenAllQuestionPage);
    track(ANALYTICS_EVENTS.SparkAction);
    push(ROUTE_QUESTION_OF_THE_DAY);
  };

  return (
    <StyledQuestionSlide>
      <StyledBrowseAllQuestions className="question-card" isDark={isDark} onClick={handlerAll}>
        Browse all questions
      </StyledBrowseAllQuestions>
    </StyledQuestionSlide>
  );
};

export default BrowseAll;
