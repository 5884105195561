import React, { FC, useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import AnimatedBackground from 'components/AnimatedBackground';

import { StyledWrapper } from './styled';
import { useWelcomeRedirect } from './hooks';

const Welcome: FC = () => {
  const { track } = useAnalytics();

  useEffect(() => {
    track(ANALYTICS_EVENTS.OnboardingInviteCodeSuccess);
  }, [track]);

  useWelcomeRedirect();

  return (
    <AnimatedBackground>
      <StyledWrapper>
        <TypeAnimation wrapper="span" cursor={false} sequence={[500, 'You’re in!']} speed={35} />
      </StyledWrapper>
    </AnimatedBackground>
  );
};

export default Welcome;
