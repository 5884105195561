import React, { createContext, useContext, useEffect, useCallback } from 'react';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { AnalyticsCaptureType } from 'constants/analyticsEvents';
import getAuthUser from 'helpers/getAuthUser';
import config from 'constants/config';
import type IAnalyticsProvider from 'types/AnalyticsProvider';
import RudderStackProvider from './RudderstackProvider';

interface AnalyticsContextType {
  track: (eventCapture: AnalyticsCaptureType, data?: Record<string, unknown>) => void;
  reset: () => void;
  identify: () => void;
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

// Add this new class for non-hook usage
class AnalyticsService {
  private static provider: IAnalyticsProvider | undefined;

  private static userId?: string;

  private static username?: string;

  private static email?: string;

  static initialize(provider: IAnalyticsProvider, userId?: string, username?: string, email?: string) {
    this.provider = provider;
    this.userId = userId;
    this.username = username;
    this.email = email;
  }

  static track(eventCapture: AnalyticsCaptureType, data?: Record<string, unknown>) {
    if (this.provider) {
      this.provider.track(eventCapture[0], {
        ...data,
        ...eventCapture[1],
        userId: this.userId,
        username: this.username,
        email: this.email,
      });
    }
  }

  static reset() {
    this.provider?.reset();
  }

  static identify() {
    this.provider?.identify();
  }
}

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { userId, username, email } = getAuthUser();
  const { analyticsProvider, rudderstackWriteKey, rudderstackDataPlaneUrl } = config;
  const [provider, setProvider] = React.useState<IAnalyticsProvider | undefined>();

  useEffect(() => {
    const setupProvider = (): IAnalyticsProvider | undefined => {
      if (analyticsProvider === 'rudderstack' && rudderstackWriteKey && rudderstackDataPlaneUrl) {
        const analyticsInstance = new RudderAnalytics();
        const rudderProvider = new RudderStackProvider(analyticsInstance);
        rudderProvider.initialize(rudderstackWriteKey, rudderstackDataPlaneUrl, userId, username, email);
        // Initialize the service when the provider is created
        AnalyticsService.initialize(rudderProvider, userId, username, email);
        return rudderProvider;
      }
      return undefined;
    };

    const newProvider = setupProvider();
    setProvider(newProvider);

    return () => {
      if (newProvider) {
        newProvider.cleanup();
      }
    };
  }, [analyticsProvider, rudderstackWriteKey, rudderstackDataPlaneUrl, userId, username]);

  const track = useCallback(
    (eventCapture: AnalyticsCaptureType, data?: Record<string, unknown>) => {
      if (provider) {
        provider.track(eventCapture[0], {
          ...data,
          ...eventCapture[1],
          userId,
          username,
          email,
        });
      }
    },
    [provider, userId, username, email]
  );

  const reset = useCallback(() => {
    provider?.reset();
  }, [provider]);

  const identify = useCallback(() => {
    provider?.identify();
  }, [provider]);

  return <AnalyticsContext.Provider value={{ track, reset, identify }}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalyticsContext must be used within an AnalyticsProvider');
  }
  return context;
};

export { AnalyticsService };
