import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CardSchema } from 'constants/graphqlTypes';

import { useAutoScrollContext } from 'components/DetailSlider/hooks';
import { useReactionContext } from 'components/Reactions/hooks';
import HiddenWordsAlert from 'components/HiddenWordsAlert';

import usePageType from 'helpers/usePageType';
import useGuideTour from 'helpers/useGuideTour';
import { usePausebleTimer } from 'helpers/usePausebleTimer';

import { getFilteredActions } from './helpers';
import ActionContent from './ActionContent';
import Action from './Action';

import {
  StyledActionHeightContainer,
  StyledAlertWrap,
  StyledRealActionContainer,
  StyledVirtualActionContainer,
} from './styled';

export interface ICommentProps {
  item: CardSchema;
  fontColor: string;
  isDetail?: boolean;
  isSmall?: boolean;
  isSpotlight?: boolean;
  isSpotlightInFocus?: boolean;
  withoutPadding?: boolean;
  enableConstantHeight?: boolean;
  isReactionButtonMenuShown?: boolean;
  setIsReactionButtonMenuShown?: Dispatch<SetStateAction<boolean>>;
  setIsReactionsModalOpen?: Dispatch<SetStateAction<boolean>>;
  isReactionsModalOpen?: boolean;
}

const Actions: FC<ICommentProps> = ({
  fontColor,
  item,
  isDetail,
  isSmall,
  isSpotlight,
  isSpotlightInFocus,
  withoutPadding,
  enableConstantHeight,
  isReactionButtonMenuShown,
  setIsReactionButtonMenuShown,
  setIsReactionsModalOpen,
  isReactionsModalOpen,
}) => {
  const { pickId } = useParams<{ pickId: string }>();
  const [index, setIndex] = useState(0);
  const [isHidden, setIsHidden] = useState(false);
  const [isReactionTokenModalOpen, setIsReactionTokenModalOpen] = useState(false);

  const { id, extraData } = item;

  const { setAutoScrollStarted } = useAutoScrollContext();
  const { isDiscovery } = usePageType();
  const { isGuideTour } = useGuideTour();

  const filteredActions = getFilteredActions(extraData, isDiscovery);
  const action = filteredActions[!isGuideTour ? index : 0];

  const user = action?.user;

  const handleTimerEnd = () => {
    if (index < filteredActions.length - 1) {
      setIndex((prev) => prev + 1);
    } else {
      setIndex(0);
      setAutoScrollStarted?.(true);
    }
  };
  const { isQuickReactionOpen, setThoughtId, setCardId, setUser } = useReactionContext();

  usePausebleTimer({
    onCompleted: handleTimerEnd,
    shouldPause: isQuickReactionOpen || isReactionButtonMenuShown || isReactionsModalOpen || isReactionTokenModalOpen,
    duration: 10000,
    disable: item.id !== pickId && isDetail,
  });

  useEffect(() => {
    if (action && action.__typename === 'CardCommentSchema' && filteredActions.length === 1) {
      setIsHidden(!!action.isSuspicious);
    }
  }, [action, action?.id, filteredActions]);

  useEffect(() => {
    if (action?.__typename === 'CardCommentSchema' && !action?.isSuspicious) {
      setUser?.(user ?? null);
      setThoughtId?.(action.id);
    }
    if (action?.__typename === 'CardPickSchema' && !isDiscovery) {
      setIsHidden(false);
      setCardId?.(id);
    }
    if (action?.__typename === 'CardPickSchema' && isDiscovery && action?.id) {
      setThoughtId?.(null);
      setCardId?.(action?.id);
      setIsHidden(false);
    }
    if (action?.__typename === 'CollectionCardLinkSchema' && action?.card?.id) {
      setThoughtId?.(null);
      setCardId?.(action?.cardPickId ?? null);
    }
    return () => setIsHidden(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, action?.__typename, user]);

  return isHidden && isDetail ? (
    <StyledAlertWrap>
      <HiddenWordsAlert showHiddenContent={() => setIsHidden(false)} isDark />
    </StyledAlertWrap>
  ) : (
    <StyledActionHeightContainer>
      {/*  StyledVirtualActionContainers are 1px-wide, static, invisible, and non-clickable div elements.
           They enable their parent div to adjust it's height to match it's tallest child.   */}
      {enableConstantHeight &&
        filteredActions.map(
          (filteredAction) =>
            filteredAction.user && (
              <StyledVirtualActionContainer key={filteredAction.id}>
                <div>
                  <Action
                    isSmall={isSmall}
                    isSpotlight={isSpotlight}
                    user={filteredAction.user}
                    fontColor={fontColor}
                    isDetail={isDetail}
                    isSpotlightInFocus={isSpotlightInFocus}
                    hasAnimation={(!isGuideTour && filteredActions.length > 1) || !!isDetail || !!isSpotlight}
                    withoutPadding={withoutPadding}
                    setIsReactionButtonMenuShown={setIsReactionButtonMenuShown}
                    isReactionButtonMenuShown={isReactionButtonMenuShown}
                    isVirtualAction
                  >
                    <ActionContent
                      isVirtualAction
                      action={filteredAction}
                      item={item}
                      isDetail={isDetail}
                      isSmall={isSmall}
                      isReactionButtonMenuShown={isReactionButtonMenuShown}
                    />
                  </Action>
                </div>
              </StyledVirtualActionContainer>
            )
        )}

      <StyledRealActionContainer enableConstantHeight={enableConstantHeight}>
        {user && (
          <Action
            key={index}
            isSmall={isSmall}
            isSpotlight={isSpotlight}
            user={user}
            fontColor={fontColor}
            isDetail={isDetail}
            hasAnimation={isSpotlight || (!isGuideTour && filteredActions.length > 1) || !!isDetail}
            withoutPadding={withoutPadding}
            action={action}
            id={id}
            isSpotlightInFocus={isSpotlightInFocus}
            isReactionButtonMenuShown={isReactionButtonMenuShown}
            setIsReactionButtonMenuShown={setIsReactionButtonMenuShown}
            setIsReactionsModalOpen={setIsReactionsModalOpen}
            setIsReactionTokenModalOpen={setIsReactionTokenModalOpen}
            isReactionTokenModalOpen={isReactionTokenModalOpen}
          >
            <ActionContent
              action={action}
              item={item}
              isDetail={isDetail}
              isSmall={isSmall}
              isReactionButtonMenuShown={isReactionButtonMenuShown}
            />
          </Action>
        )}
      </StyledRealActionContainer>
    </StyledActionHeightContainer>
  );
};

export default Actions;
