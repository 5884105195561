import React, { Dispatch, FC, SetStateAction } from 'react';

import TextInput from 'components/UI/TextInput';
import Modal from 'components/Modal';
import ContentTitle from 'components/UI/ContentTitle';
import useDisableScroll from 'helpers/useDisableScroll';

import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';
import { StyledWrapper } from './styles';

export interface IEditTextFieldModalDataTestIds {
  inputDataTestId?: string;
  cancelBtnDataTestId?: string;
  doneBtnDataTestId?: string;
}

export interface IEditItem {
  label: string;
  text: string;
  loading: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  setText: Dispatch<SetStateAction<string>>;
  placeholder?: string;
  handleDone: () => void;
  handleClose: () => void;
  handlePrev?: () => void;
  inputHeight?: number;
  maxLength?: number;
  isSubmitDisabled?: boolean;
  customCloseText?: string;
  autoCapitalize?: EAutoCapitalize;
  isTextRequired?: boolean;
  dataTestIds?: IEditTextFieldModalDataTestIds;
}

const EditTextFieldModal: FC<IEditItem> = ({
  inputHeight,
  label,
  maxLength,
  text,
  customCloseText,
  placeholder,
  isSubmitDisabled,
  setText,
  handleDone,
  handleClose,
  handlePrev,
  error,
  loading,
  setError,
  autoCapitalize,
  isTextRequired,
  dataTestIds,
}) => {
  const { cancelBtnDataTestId, doneBtnDataTestId, inputDataTestId } = dataTestIds ?? {};

  const handleText = (value: string) => {
    setError(isTextRequired && !value ? 'Required' : '');
    const editedText = value.replaceAll(/\s{2,}/g, ' ');

    if (maxLength && editedText.length >= maxLength) {
      return setText(editedText.slice(0, maxLength));
    }
    return setText(editedText);
  };

  useDisableScroll();

  return (
    <Modal
      fadeIn={false}
      fadeOut={false}
      disabled={isSubmitDisabled}
      handleClose={handleClose}
      buttonText="Done"
      handleDone={handleDone}
      customCloseText={customCloseText ?? 'Cancel'}
      loading={loading}
      handlePrev={handlePrev}
      dataTestIdPrevButton={cancelBtnDataTestId}
      dataTestIdCloseButton={cancelBtnDataTestId}
      dataTestIdDoneButton={doneBtnDataTestId}
    >
      <StyledWrapper>
        <ContentTitle isDark>{label}</ContentTitle>
        <TextInput
          inputHeight={inputHeight}
          placeholder={placeholder}
          text={text}
          counterNumber={maxLength}
          setText={handleText}
          maxLength={maxLength}
          error={error}
          autoCapitalize={autoCapitalize}
          rows={0}
          dataTestId={inputDataTestId}
          shouldPrevent2ndSpace
        />
      </StyledWrapper>
    </Modal>
  );
};

export default EditTextFieldModal;
