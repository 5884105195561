import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useTheme from 'helpers/useTheme';
import AvatarGH from 'components/UI/AvatarGH';
import Layout from 'components/Onboarding/Layout';

import { ROUTE_ONBOARDING_NETWORKING } from 'routes';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ENewOnboardingStepOptions } from 'constants/graphqlTypes';
import { TEST_ID_USER_INFO_VIEW_START } from 'constants/aqa/signup';
import { useNotifications } from 'graphQL/profile/notifications/hooks';
import { useUpdateCachedOnboardingStepV2, useUpdateOnboardingStepV2 } from 'graphQL/onboarding/hooks';
import { StyledText, StyledTextContainer, StyledContent, StyledWrap } from './styled';

const Notifications: FC = () => {
  const { track } = useAnalytics();

  useUpdateOnboardingStepV2(ENewOnboardingStepOptions.Notifications);
  const { push } = useHistory();

  const { updateNotificationSetting } = useNotifications();
  const isDark = useTheme();
  const updateCache = useUpdateCachedOnboardingStepV2(ENewOnboardingStepOptions.Networking);

  const handleNext = (isEnableAll?: boolean) => {
    if (isEnableAll) {
      updateNotificationSetting({ isEnableAll });
    }
    updateCache();
    push(ROUTE_ONBOARDING_NETWORKING);
  };

  useEffect(() => {
    track(ANALYTICS_EVENTS.OnboardingNotificationsScreen);
  }, [track]);

  return (
    <Layout
      heading="Are you happy for us to send you notifications?"
      text="We’ll let you know what others are thinking about the things you’re into."
      primaryBtnText="Enable"
      handlePrimaryBtn={() => handleNext(true)}
      secondaryBtnText="Skip"
      handleSecondaryBtn={handleNext}
      isSecondaryBtnTransparent
      pageTestId={TEST_ID_USER_INFO_VIEW_START}
      isWideContent
      maxWidth={528}
    >
      <StyledWrap>
        <StyledContent isDark={isDark}>
          <AvatarGH dimension={44} isDark />
          <StyledTextContainer isDark={isDark}>
            <StyledText>New thoughts added to your pick</StyledText>
            <br />
            <StyledText isSecondary>See what Jim West and Priya Swift think</StyledText>
          </StyledTextContainer>
        </StyledContent>
      </StyledWrap>
    </Layout>
  );
};

export default Notifications;
