import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { ENavElements, EActionType, addActions } from 'graphQL/card/helpers/helpers';
import {
  AddThoughtMutation,
  CardCommentFilterOptionsEnum,
  CardCommentHiddenWordsFilterOptionsEnum,
  SelectedUsersInputFilter,
  UpdateThoughtMutation,
  useAddThoughtMutation,
  useGetCommentsQuery,
  useUpdateThoughtMutation,
  useGetCommentReactionsListQuery,
} from 'constants/graphqlTypes';
import useReferrerId from 'helpers/useReferrerId';
import { EQueryFetchPolicy, REACTIONS_NUMBER_STEP } from 'constants/common';
import { checkHasErrorWithCode } from 'graphQL/cardOptions/helpers';
import { ApolloError } from '@apollo/client';
import {
  IUseGetComments,
  IUseThought,
  IGetCommentReactionsList,
  IUseThoughtArgs,
  IHandleAddThoughtArgs,
} from './models';
import { useGetCardPicks } from '../helpers/hooks';

export const useGetCommentReactionsList = (pickId?: string, commentId?: string): IGetCommentReactionsList => {
  const { data, loading, fetchMore, refetch } = useGetCommentReactionsListQuery({
    variables: { pickId, commentId, reactionsOffset: 0, reactionsLimit: REACTIONS_NUMBER_STEP },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: !pickId || !commentId,
  });

  const reactions = data?.allCards?.items?.[0]?.comments?.items[0]?.reactions;
  const fetchedReactions = reactions?.items || [];
  const totalCount = reactions?.totalCount ?? 0;

  const loadMoreReactions = () =>
    fetchedReactions.length < totalCount &&
    fetchMore({
      variables: { offset: fetchedReactions.length, reactionsLimit: fetchedReactions.length + REACTIONS_NUMBER_STEP },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          ...fetchMoreResult,
        };
      },
    });

  const refetchReactions = () => refetch();

  return {
    reactions,
    loading,
    loadMoreReactions,
    refetchReactions,
  };
};

export const useGetComments = (
  id?: string,
  selectedUsers?: SelectedUsersInputFilter,
  criterion?: CardCommentFilterOptionsEnum,
  selectByHidden?: CardCommentHiddenWordsFilterOptionsEnum
): IUseGetComments => {
  const { data, loading, fetchMore } = useGetCommentsQuery({
    variables: { id, selectedUsers, criterion, selectByHidden, offset: 0, limit: selectedUsers ? 8 : 10 },
    skip: !id,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const items = data?.allCards?.items?.[0]?.comments?.items || [];
  const totalCount = data?.allCards?.items?.[0]?.comments?.totalCount ?? 0;

  const loadMore = () =>
    items?.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        const card = prev?.allCards?.items?.[0];
        const comments = card?.comments?.items;
        const total = card?.comments?.totalCount ?? 0;
        const newItems = fetchMoreResult?.allCards?.items?.[0]?.comments?.items;

        return addActions({
          prev,
          type: EActionType.Comments,
          field: ENavElements.Comments,
          card,
          items: comments,
          totalCount: total,
          newItems,
        });
      },
    });

  return { comments: data?.allCards?.items?.[0]?.comments, loading, loadMore, totalCount };
};

const updateThoughtFields = (data: (UpdateThoughtMutation & AddThoughtMutation) | null | undefined) => ({
  comments: () => data?.updateComment?.comments,
  extraData: () => data?.updateComment?.extraData,
});

export const useThought = ({
  pickId,
  commentId,
  text,
  refListId,
  onLockedError,
  currentUserPickId,
}: IUseThoughtArgs): IUseThought => {
  const { track } = useAnalytics();
  const { pickId: userPickId } = useGetCardPicks(pickId);
  const referrerId = useReferrerId();
  const variables = { commentId, text: text.trim(), referrerId, refListId };

  const [update, { loading: updateLoading }] = useUpdateThoughtMutation({
    variables,
    update: (cache, { data }) =>
      cache.modify({
        id: cache.identify({ id: pickId, __typename: 'CardSchema' }),
        fields: updateThoughtFields(data),
      }),
    onCompleted: () => {
      track(ANALYTICS_EVENTS.EditThought);
      track(ANALYTICS_EVENTS.CardAction);
    },
    onError: (error: ApolloError) => {
      if (checkHasErrorWithCode(error, 423)) {
        onLockedError?.();
      }
    },
  });

  const [add, { loading: addLoading }] = useAddThoughtMutation({
    variables: { text: text.trim(), pickId, currentUserPickId: currentUserPickId ?? userPickId },
  });

  const handleAddThought = (args?: IHandleAddThoughtArgs) =>
    add({
      variables: {
        text: text.trim(),
        pickId,
        currentUserPickId: currentUserPickId ?? userPickId,
        ...args,
      },
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify({ id: data?.addComment?.id, __typename: 'CardSchema' }),
          fields: updateThoughtFields(data),
        });

        cache.modify({
          id: cache.identify({ id: pickId, __typename: 'CardSchema' }),
          fields: updateThoughtFields(data),
        });
      },
      onCompleted: () => {
        track(ANALYTICS_EVENTS.AddThougth);
        track(ANALYTICS_EVENTS.CardAction);
      },
      onError: (error: ApolloError) => {
        if (checkHasErrorWithCode(error, 423)) {
          onLockedError?.();
        }
      },
    });

  return { update, handleAddThought, loading: updateLoading || addLoading };
};
