import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';

import { useAnalytics } from 'contexts/AnalyticsContext';
import { useUpdateListDescription } from 'graphQL/list/update/hooks';
import { transformRoute } from 'helpers/routingHelper';
import EditTextFieldModal from 'components/EditTextFieldModal';
import { ROUTE_LIST_EDIT } from 'routes';
import { LIST_DESCRIPTION_LENGTH } from 'constants/common';
import { DESCRIPTION_INPUT_HEIGHT } from 'components/AddContent/CreateList/CreateList';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { EAutoCapitalize } from 'components/UI/TextInput/helpers/constants';

const EditListDescription: FC<{ navigateToEditList: () => void }> = ({ navigateToEditList }) => {
  const { track } = useAnalytics();

  const { listId } = useParams<{ listId: string }>();
  const { state } = useLocation<{ variable: { listCardId: string; description: string; isSingleModal?: boolean } }>();
  const { listCardId, description, isSingleModal } = state?.variable ?? {};
  const { replace, goBack } = useHistory();
  const [descriptionText, setDescriptionText] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setDescriptionText(description ?? '');
  }, [description]);

  const onCompleted = () => {
    replace({
      pathname: transformRoute(ROUTE_LIST_EDIT, { listId }),
      state: {
        ...state,
        variable: { ...state?.variable, description: descriptionText.trim() },
      },
    });
    track(ANALYTICS_EVENTS.EditList);
    track(ANALYTICS_EVENTS.EditListDescr);
  };

  const handleClose = (): void => {
    if (isSingleModal) {
      goBack();
    } else {
      navigateToEditList();
    }
  };

  const { updateDescription, loading, descriptionError } = useUpdateListDescription(
    listId,
    listCardId,
    descriptionText,
    isSingleModal ? handleClose : onCompleted
  );

  useEffect(() => {
    if (descriptionError) {
      setError(descriptionError);
    }
  }, [descriptionError]);

  return (
    <EditTextFieldModal
      inputHeight={DESCRIPTION_INPUT_HEIGHT}
      text={descriptionText}
      setText={setDescriptionText}
      label="Add a description"
      maxLength={LIST_DESCRIPTION_LENGTH}
      handleClose={handleClose}
      handleDone={updateDescription}
      error={error}
      loading={loading}
      setError={setError}
      autoCapitalize={EAutoCapitalize.On}
    />
  );
};

export default EditListDescription;
