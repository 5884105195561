import React, { FC, useEffect, useState } from 'react';
import { LastLocationProvider } from 'react-router-last-location';
import { v4 } from 'uuid';

import { ProfileProvider, useProfileContextState } from 'helpers/useProfileProvider';
import {
  AdminFeedFiltersProvider,
  useAdminFeedFiltersContextState,
} from 'components/CustomFeeds/FilterFeedModal/hooks';
import { EExploreTabs } from 'components/Explore/ExploreTabs/helpers/constants';
import { AnalyticsService } from 'contexts/AnalyticsContext';
import getAuthUser from 'helpers/getAuthUser';
import {
  IsModalOpenProvider,
  NavigationProvider,
  ThemeProvider,
  GuideTourProvider,
  FollowingTabKeyProvider,
  DiscoveryTabProvider,
  OpenHiddenCardsProvider,
} from './helpers/contexts';
import { IIsGuideTour, IIsModalOpen, INavigation } from './helpers/models';

const Providers: FC = ({ children }) => {
  // Initialize analytics identification
  useEffect(() => {
    const { userId } = getAuthUser();
    if (userId) {
      AnalyticsService.identify();
    }
  }, []);

  // useTheme
  const themeQuery = window.matchMedia('(prefers-color-scheme: dark)');
  const { matches } = themeQuery;
  const [isDark, setIsDark] = useState(matches);

  // useNavigation
  const [navColorState, setNavColorState] = useState<INavigation>({});
  const setNavColor = ({ navColor, sectionHeight }: INavigation) =>
    setNavColorState((prev) => ({ ...prev, navColor, sectionHeight }));
  const setIsBottomMenuHidden = (value: boolean) => {
    setNavColorState((prev) => ({ ...prev, isBottomMenuHidden: value }));
  };
  const setIsScrolled = (value: boolean) => {
    setNavColorState((prev) => ({ ...prev, isScrolled: value }));
  };

  // useModal
  const [isModalOpenState, setIsModalOpenState] = useState<IIsModalOpen>({});
  const setIsOpen = (isOpen: boolean) => setIsModalOpenState({ ...isModalOpenState, isOpen });

  // useFollowingTabKey
  const [followingTabKey, setFollowingTabKey] = useState('initial-key');
  const resetFollowingTabKey = () => setFollowingTabKey(v4());

  // useDiscoveryTab
  const [discoveryTabState, setDiscoveryTabState] = useState<EExploreTabs | string>(EExploreTabs.Featured);
  const setDiscoveryTab = (tab: EExploreTabs | string) => setDiscoveryTabState(tab);

  // useOpenHiddenCards
  const [openHiddenCardsState, setOpenHiddenCardsState] = useState<string[]>([]);
  const openCard = (id: string) => setOpenHiddenCardsState((prev) => [...prev, id]);

  // useGuideTour
  const [isGuideTourState, setIsGuideTourState] = useState<IIsGuideTour>({ guideTourStep: 0 });
  const setIsGuideTour = (isGuideTour: boolean) => setIsGuideTourState((prevState) => ({ ...prevState, isGuideTour }));
  const setGuideTourStep = (step: number) =>
    setIsGuideTourState((prevState) => ({ ...prevState, guideTourStep: step }));

  const adminFiltersContextState = useAdminFeedFiltersContextState();

  useEffect(() => {
    const handler = ({ matches: newTheme }: MediaQueryListEvent) => setIsDark(newTheme);

    themeQuery.addEventListener('change', handler);

    return () => themeQuery.removeEventListener('change', handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const profileContextState = useProfileContextState();

  return (
    <LastLocationProvider>
      <ThemeProvider value={isDark}>
        <NavigationProvider value={{ ...navColorState, setNavColor, setIsBottomMenuHidden, setIsScrolled }}>
          <GuideTourProvider value={{ ...isGuideTourState, setIsGuideTour, setGuideTourStep }}>
            <IsModalOpenProvider value={{ ...isModalOpenState, setIsOpen }}>
              <DiscoveryTabProvider value={{ discoveryTab: discoveryTabState, setDiscoveryTab }}>
                <OpenHiddenCardsProvider value={{ ids: openHiddenCardsState, openCard }}>
                  <FollowingTabKeyProvider value={{ followingTabKey, resetFollowingTabKey }}>
                    <AdminFeedFiltersProvider value={adminFiltersContextState}>
                      <ProfileProvider value={profileContextState}>{children}</ProfileProvider>
                    </AdminFeedFiltersProvider>
                  </FollowingTabKeyProvider>
                </OpenHiddenCardsProvider>
              </DiscoveryTabProvider>
            </IsModalOpenProvider>
          </GuideTourProvider>
        </NavigationProvider>
      </ThemeProvider>
    </LastLocationProvider>
  );
};

export default Providers;
