import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { StyledCloseButton } from 'components/Modal/styled';
import { ROUTE_WELCOME } from 'routes';
import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { useNavigateBackOnboarding } from 'components/Onboarding/hooks';
import usePageType from 'helpers/usePageType';
import useTheme from 'helpers/useTheme';
import config from 'constants/config';
import { useNavBehavior } from '../helpers/hooks';

const OnboardingButton: FC = () => {
  const { track } = useAnalytics();
  const { push } = useHistory();
  const isDark = useTheme();
  const {
    isOnboardingWelcome,
    isOnboardingVerification,
    isOnboardingPreview,
    isOnboardingProfileIntro,
    isOnboardingProfileInterests,
  } = usePageType();

  const closeOnboarding = () => {
    localStorage.clear();
    push(ROUTE_WELCOME);
  };

  const navigateBackOnboarding = useNavigateBackOnboarding();
  const { fontColor } = useNavBehavior();

  const handleClick = () => {
    if (isOnboardingProfileInterests) {
      track(ANALYTICS_EVENTS.OnboardingProfileParameters, {
        screen: 'topics',
      });
    }
    if (config.isNewOnboardingEnabled && (isOnboardingVerification || isOnboardingPreview || isOnboardingWelcome)) {
      closeOnboarding();
    }
    navigateBackOnboarding();
  };

  const isPrevButton =
    !isOnboardingVerification && !isOnboardingPreview && !isOnboardingProfileIntro && !isOnboardingProfileInterests;

  return (
    <StyledCloseButton
      color={fontColor}
      isDark={isOnboardingVerification || isOnboardingPreview || isOnboardingWelcome || isDark}
      isPrev={isPrevButton}
      onClick={handleClick}
    />
  );
};

export default OnboardingButton;
