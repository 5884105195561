import { useAnalytics } from 'contexts/AnalyticsContext';
import { ANALYTICS_EVENTS } from 'constants/analyticsEvents';
import { EMPTY_ITEMS, EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import { REF_PLACEHOLDER } from 'components/Explore/Item/helpers';
import {
  useSearchPicksQuery,
  useSearchListsQuery,
  useGetRecentSearchesQuery,
  useSearchUsersQuery,
  useAddToRecentSearchesMutation,
  ESearchTypeOptions,
  useRemoveFromRecentSearchesMutation,
  useGetSuggestionSearchesQuery,
  useSearchTotalCountQuery,
  ESearchCardType,
  useGetSuggestionUsersQuery,
  useSearchChatMembersQuery,
  SearchUserFilter,
} from 'constants/graphqlTypes';
import {
  IAddToRecentSearches,
  IRemoveFromRecentSearches,
  ISuggestionSearches,
  IUseRecentSearches,
  IUseSearchCards,
  IUseSearchUsers,
  IUseSearchTotalCount,
  ISuggestionUsersSearches,
  IUseSearchChatMembers,
} from './models';
import { getSearchCards, getSearchUsers, updateRecentSearchesCache, updateSearchResult } from './helpers';
import getAuthUser from '../../helpers/getAuthUser';

export const useSearchLists = (searchString: string): IUseSearchCards => {
  const { data, loading, fetchMore } = useSearchListsQuery({
    variables: { searchString },
    skip: searchString.length < 1,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.search ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items?.length },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...(prev.search && { search: updateSearchResult(prev.search, fetchMoreResult?.search) }),
      }),
    });

  return {
    items: getSearchCards(items),
    loading,
    loadMore,
  };
};

export const useSearchPicks = (searchString: string): IUseSearchCards => {
  const { data, loading, fetchMore } = useSearchPicksQuery({
    variables: { searchString, referrerId: REF_PLACEHOLDER },
    skip: searchString.length < 1,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.search ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items?.length },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...(prev.search && { search: updateSearchResult(prev.search, fetchMoreResult?.search) }),
      }),
    });

  return {
    items: getSearchCards(items),
    loading,
    loadMore,
  };
};

export const useSearchChatMembers = (searchString: string, filters: SearchUserFilter): IUseSearchChatMembers => {
  const { data, loading, fetchMore } = useSearchChatMembersQuery({
    variables: { searchString, limit: 10, filters },
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const { items, totalCount } = data?.searchUsers ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    !loading &&
    fetchMore({
      variables: { offset: items?.length },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...(prev.searchUsers && {
          searchUsers: {
            items: [...prev.searchUsers.items, ...(fetchMoreResult.searchUsers?.items ?? [])],
            totalCount,
          },
        }),
      }),
    });

  return {
    items,
    loading,
    loadMore,
  };
};

export const useSearchUsers = (searchString: string, limit?: number, skipLength = 1): IUseSearchUsers => {
  const { data, loading, fetchMore } = useSearchUsersQuery({
    variables: { searchString, limit: limit || 10 },
    skip: searchString.length < skipLength,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.search ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items?.length },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...(prev.search && { search: updateSearchResult(prev.search, fetchMoreResult?.search) }),
      }),
    });

  return {
    items: getSearchUsers(items),
    loading,
    loadMore,
  };
};

export const useGetSearchSuggestedUsers = ({
  searchString,
  limit,
  skipLength = 2,
  withoutBlockedAndBlockers,
}: ISuggestionUsersSearches): IUseSearchUsers => {
  const { userId } = getAuthUser();

  const { data, loading, fetchMore } = useGetSuggestionUsersQuery({
    variables: { searchString, limit, withoutBlockedAndBlockers, currentUserId: userId },
    skip: searchString.length < skipLength,
    ...SHOW_FETCH_MORE_LOADER,
  });

  const { items, totalCount } = data?.allUsers ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items?.length },
      updateQuery: (prev, { fetchMoreResult }) => ({
        ...prev,
        ...(prev.allUsers && {
          allUsers: {
            ...prev.allUsers,
            items: [...(prev.allUsers?.items ?? []), ...(fetchMoreResult?.allUsers?.items ?? [])],
          },
        }),
      }),
    });

  return {
    items: getSearchUsers(items),
    loading,
    loadMore,
  };
};

export const useGetRecentSearches = (): IUseRecentSearches => {
  const { data, loading } = useGetRecentSearchesQuery({
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  return {
    items: data?.recentSearches?.items ?? [],
    loading,
  };
};

export const useAddToRecentSearches = (): IAddToRecentSearches => {
  const [addToRecentSearches] = useAddToRecentSearchesMutation();

  return {
    addToRecentSearches: (searchQuery: string, searchType: ESearchTypeOptions) =>
      addToRecentSearches({ variables: { searchQuery, searchType } }),
  };
};

export const useRemoveFromRecentSearches = (searchId: string): IRemoveFromRecentSearches => {
  const [removeFromRecentSearches] = useRemoveFromRecentSearchesMutation({
    variables: { searchId },
    update: (cache) => {
      updateRecentSearchesCache(cache, searchId);
    },
  });

  return { removeFromRecentSearches };
};

export const useGetSuggestionSearches = (query: string, skip?: boolean, feedId?: string): ISuggestionSearches => {
  const { track } = useAnalytics();
  const { data, loading } = useGetSuggestionSearchesQuery({ variables: { query, feedId }, skip });
  const suggestionSearches = data?.suggestionSearches?.items ?? [];

  track(ANALYTICS_EVENTS.SearchAction);
  track(ANALYTICS_EVENTS.SuggestSearch);

  if (query.includes('type:')) {
    track(ANALYTICS_EVENTS.TypeSearch);
  }
  if (query.includes('time:')) {
    track(ANALYTICS_EVENTS.TimeSearch);
  }
  if (query.includes('user:')) {
    track(ANALYTICS_EVENTS.UserSearch);
  }
  return { suggestionSearches, loading };
};

export const useSearchTotalCount = (
  searchString: string,
  searchItemType: ESearchCardType,
  limit?: number,
  skipLength = 1
): IUseSearchTotalCount => {
  const { data, loading } = useSearchTotalCountQuery({
    variables: { searchString, limit, searchItemType },
    skip: searchString.length < skipLength,
  });

  const { totalCount } = data?.search ?? EMPTY_ITEMS;

  return {
    totalCount,
    loadingCount: loading,
  };
};
