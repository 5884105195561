import React, { ChangeEvent, KeyboardEvent, FC, useState, useEffect, ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import GraphemeSplitter from 'grapheme-splitter';
import { TEST_ID_TEXT_INPUT_ERROR_HINT, TEST_ID_TEXT_INPUT_HINT } from 'constants/aqa/common';
import { getInputStyle, preventSecondSpace, preventSpaceAtStart } from './helpers/helpers';
import {
  DOUBLE_DASH,
  EAutoCapitalize,
  FIRST_LOWERCASE_LETTER_IN_STRING,
  FIRST_LOWERCASE_LETTER_IN_WORD,
  LONG_DASH,
} from './helpers/constants';
import { useComputedRef, useInputHeight } from './helpers/hooks';

import { StyledCounter, StyledError, StyledHint, StyledTextarea } from './styled';

interface ITextInput {
  text: string;
  placeholder?: string;
  autoCapitalize?: EAutoCapitalize;
  counterNumber?: number;
  error?: string | boolean | ReactNode;
  hints?: string[];
  inputHeight?: number;
  isDark?: boolean;
  isMultiLine?: boolean;
  disabled?: boolean;
  maxLength?: number;
  type?: string;
  setText: (value: string) => void;
  handleEnter?: () => void;
  rows?: number;
  dataTestId?: string;
  shouldPrevent2ndSpace?: boolean;
  longDash?: boolean;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
}

const editTextLength = (text: string, splitter: GraphemeSplitter, maxLength?: number): string => {
  const graphemesTextLength = splitter.countGraphemes(text);
  if (!maxLength || graphemesTextLength < maxLength) {
    return text;
  }

  const graphemes = splitter.splitGraphemes(text);
  return graphemes.slice(0, maxLength).join('');
};

const TextInput: FC<ITextInput> = ({
  text,
  placeholder,
  autoCapitalize = EAutoCapitalize.Off,
  counterNumber,
  error,
  hints,
  inputHeight,
  isDark = true,
  isMultiLine = false,
  disabled,
  maxLength,
  type,
  setText,
  handleEnter,
  rows,
  dataTestId,
  shouldPrevent2ndSpace,
  longDash = true,
  autoFocus,
}) => {
  const splitter = new GraphemeSplitter();

  const inputId = uuidv4();
  const inputStyle = getInputStyle(type, isDark);

  const { ref, handleRef, scrollHeight } = useComputedRef();

  const [position, setPosition] = useState<{ start: number; end: number } | null>(null);
  const graphemesTextLength = splitter.countGraphemes(text ?? '');

  const handleChange = ({ currentTarget }: ChangeEvent<HTMLTextAreaElement>) => {
    const { value, selectionStart, selectionEnd } = currentTarget;
    const trimmedValue = value
      .trimStart()
      .replaceAll(/\u2026/g, '...')
      .replaceAll(longDash ? DOUBLE_DASH : LONG_DASH, longDash ? LONG_DASH : DOUBLE_DASH);

    const updatedText =
      autoCapitalize === EAutoCapitalize.Off
        ? trimmedValue
        : trimmedValue.replace(
            autoCapitalize === EAutoCapitalize.Words
              ? FIRST_LOWERCASE_LETTER_IN_WORD
              : FIRST_LOWERCASE_LETTER_IN_STRING,
            (char) => char.toLocaleUpperCase()
          );

    const lengthEditedText = editTextLength(updatedText, splitter, maxLength);

    setText(lengthEditedText);
    setPosition({ start: selectionStart, end: selectionEnd });
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !isMultiLine) {
      e.preventDefault();
      handleEnter?.();
    }
    if (shouldPrevent2ndSpace) {
      preventSecondSpace(e);
    }
    preventSpaceAtStart(e);
  };

  useInputHeight(text, inputHeight, ref.current, scrollHeight);

  useEffect(() => {
    if (text?.length) {
      ref.current?.setSelectionRange(text?.length, text?.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ref.current && position) {
      ref.current.setSelectionRange(position.start, position.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, position]);

  useEffect(() => {
    const element = document.getElementById(inputId);

    const rejectPrevHandlers = (e: Event) => e.stopImmediatePropagation();

    if (element) {
      element?.addEventListener('wheel', rejectPrevHandlers);
      element?.addEventListener('scroll', rejectPrevHandlers);
      element?.addEventListener('touchmove', rejectPrevHandlers);
    }

    return () => {
      element?.removeEventListener('wheel', rejectPrevHandlers);
      element?.removeEventListener('scroll', rejectPrevHandlers);
      element?.removeEventListener('touchmove', rejectPrevHandlers);
    };
  }, [inputId]);

  const isLengthLimitReached = graphemesTextLength === counterNumber;

  return (
    <>
      <StyledTextarea
        disabled={disabled}
        id={inputId}
        ref={handleRef}
        autoFocus={autoFocus ?? !disabled}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        isDark={isDark}
        placeholder={placeholder}
        rows={rows ?? 1}
        value={text}
        autoCapitalize={autoCapitalize}
        inputStyle={inputStyle}
        data-testid={dataTestId}
      />
      {counterNumber && (
        <StyledCounter isDark={isDark} isLengthLimitReached={isLengthLimitReached}>
          {`${graphemesTextLength}/${counterNumber}`}
        </StyledCounter>
      )}
      {hints &&
        hints.map((hint) => (
          <StyledHint data-testid={TEST_ID_TEXT_INPUT_HINT} isDark={isDark} key={uuidv4()}>
            {hint}
          </StyledHint>
        ))}
      {error && <StyledError data-testid={TEST_ID_TEXT_INPUT_ERROR_HINT}>{error}</StyledError>}
    </>
  );
};

export default TextInput;
