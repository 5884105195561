import React, { FC, Suspense, lazy, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { Location } from 'history';
import { useApolloClient } from '@apollo/client';

import config from 'constants/config';
import AccountManagement from 'pages/Settings/AccountManagement';
import Notifications from 'pages/Settings/Notifications';
import Settings from 'pages/Settings';
import PrivacyAndData from 'pages/Settings/PrivacyAndData/PrivacyAndData';
import AuthRedirect from 'pages/AuthRedirect';
import CheckEmailPage from 'pages/CheckEmailPage';
import ListItem from 'pages/ListItem';
import AllPicksItem from 'pages/AllPicksItem';
import AllPicks from 'pages/AllPicks';
import Profile from 'pages/Profile';
import LegalPage from 'pages/Legal';
import Search from 'pages/Search';
import Activity from 'pages/Activity';
import UnAuthorized from 'pages/UnAuthorized';
import ListDetail from 'pages/ListDetail';
import CardDetail from 'pages/CardDetail';
import Explore from 'pages/Explore';
import Welcome from 'pages/Welcome';
import Landing from 'pages/Landing';
import Onboarding from 'pages/Onboarding';
import NewOnboarding from 'pages/OnboardingV2';
import TopicDetail from 'pages/TopicDetail';
import TopicItem from 'pages/TopicItem';
import ContentNotFound from 'pages/ContentNotFound';
import Reactions from 'pages/Reactions';
import FollowingItem from 'pages/FollowingItem';
import EveryoneItem from 'pages/EveryoneItem';
import Help from 'pages/Help';
import PrivacyApproach from 'pages/Help/PrivacyApproach';
import AboutUs from 'pages/AboutUs';
import ExitForm from 'pages/ExitForm';
import QuestionDetail from 'pages/QuestionDetail';
import QuestionOfDay from 'pages/QuestionOfDay';
import GuestList from 'pages/GuestList';
import GuestListSeries from 'pages/GuestList/GuestListSeries';
import Messages from 'pages/Messages';
import OffPlatformSharing from 'pages/OffPlatformSharing/OffPlatformSharing';
import ShareSheet from 'pages/OffPlatformSharing/ShareSheet';
import AllSparks from 'pages/AllSparks';
import SparkDetail from 'pages/SparkDetail';
import SavedPicks from 'pages/SavedPicks';
import ListsChannels from 'pages/ListsChannels';
import FeedsSection from 'pages/FeedsSection/FeedsSection';

import AddContent from 'components/AddContent';
import Providers from 'providers';
import Connections from 'components/Connections';
import ListSettings from 'components/ListSettings/ListSettings';
import ChangeLayout from 'components/List/ChangeLayout';
import MagicLink from 'components/Authentication/MagicLink';
import InviteModal from 'components/InviteModal';
import TopicsModal from 'components/TopicsModal';
import Report from 'components/Report/Report';
import ProfileSettings from 'components/ProfileSettings';
import Thoughts from 'components/Thoughts';
import GuideTour from 'components/GuideTour';
import AddAnswer from 'components/AddAnswer';
import AddResponseToSpark from 'components/Sparks/Routes/AddResponseToSpark';
import ChatSettingsModal from 'components/Messages/ChatSettings/ChatSettingsModal';
import FilterFeedModal from 'components/CustomFeeds/FilterFeedModal';

import {
  getDefaultRouteForBg,
  transformRoute,
  useGetSearchParam,
  useOffPlatformSharingRedirect,
} from 'helpers/routingHelper';
import getAuthUser from 'helpers/getAuthUser';
import useUpdateOpenAppCounter from 'helpers/useUpdateOpenAppCounter';
import useContextPusher from 'helpers/useContextPusher';
import withLayout from 'helpers/withLayout';
import useKeyboardOffset from 'helpers/useKeyboardOffset';
import { useShowBottomMenu } from 'components/Navigation/helpers/hooks';
import { useSignOut } from 'graphQL/profile/hooks';

import { useCheckOnboardingStatus } from 'graphQL/onboarding/hooks';
import GlobalStyle from 'styles';

import SelectChatModal from 'components/SelectChatModal';
import UnknownError from 'pages/UnknownError';
import Sparks from 'pages/Sparks';
import Archive from 'pages/SavedPicks/Archive';
import CreateFeed from 'components/CustomFeeds/CreateFeed/Routes/CreateFeed';
import EditFeed from 'components/CustomFeeds/EditFeed/Routes/EditFeed';
import ProfilePreviewModal from 'components/ProfilePreviewModal';
import AddSavedContentRoutes from 'components/AddSavedContentRoutes';
import { EMessagePrefixes, sendMessageToExtension } from 'helpers/browserExtension';
import usePageType from 'helpers/usePageType';
import FeedDetail from 'pages/FeedDetail';
import FeedItem from 'pages/FeedItem';

import { useAnalytics } from 'contexts/AnalyticsContext';
import {
  ROUTE_DISCOVERY,
  ROUTE_WELCOME,
  ROUTE_LANDING,
  ROUTE_EXIT_FORM,
  ROUTE_PROFILE,
  ROUTE_UNAUTHORIZED,
  ROUTE_USER_PROFILE,
  ROUTE_SEARCH,
  ROUTE_SIGNIN,
  ROUTE_TERMS,
  ROUTE_PRIVACY,
  ROUTE_COMMUNITY,
  ROUTE_ACTIVITY,
  ROUTE_LIST_DETAIL,
  ROUTE_CARD_DETAIL,
  ROUTE_ADD_CONTENT,
  ROUTE_PROFILE_SETTINGS,
  ROUTE_USER_FOLLOWERS,
  ROUTE_USER_FOLLOWINGS,
  ROUTE_USER_TOPICS,
  ROUTE_USER_ALL_PICKS,
  ROUTE_USER_ALL_PICKS_LAYOUT,
  ROUTE_LIST_EDIT,
  ROUTE_LIST_ITEM,
  ROUTE_USER_ALL_PICKS_ITEM,
  ROUTE_LIST_INVITE,
  ROUTE_AUTH_REDIRECT,
  ROUTE_CHECK_EMAIL,
  ROUTE_REPORT,
  ROUTE_ONBOARDING,
  ROUTE_SETTINGS,
  ROUTE_ACCOUNT_MANAGEMENT,
  ROUTE_PRIVACY_AND_DATA,
  ROUTE_NOTIFICATION_SETTING,
  ROUTE_CARD_DETAIL_THOUGHTS,
  ROUTE_ACTIVITY_REACTIONS,
  ROUTE_TOPIC_DETAIL,
  ROUTE_TOPIC_ITEM_DETAIL,
  ROUTE_FOLLOWING_CARD_DETAIL,
  ROUTE_EVERYONE_CARD_DETAIL,
  ROUTE_UI,
  ROUTE_HELP,
  ROUTE_PRIVACY_APPROACH,
  ROUTE_ABOUT,
  ROUTE_ADD_ANSWER,
  ROUTE_QUESTION_DETAIL,
  ROUTE_QUESTION_OF_THE_DAY,
  ROUTE_GUEST_LIST_DETAIL,
  ROUTE_GUEST_LIST_SERIES,
  ROUTE_MESSAGES,
  ROUTE_OFF_PLATFORM_SHARING,
  ROUTE_MESSAGES_SETTINGS,
  ROUTE_SELECT_CHAT_MODAL,
  ROUTE_INVITE_USER_SHARING,
  ROUTE_UNKNOWN_ERROR,
  ROUTE_USER_ALL_SPARKS,
  ROUTE_SPARK_DETAIL,
  ROUTE_SPARK_RESP_ADD_PICK,
  ROUTE_SPARKS,
  ROUTE_CREATE_FEED,
  ROUTE_EDIT_FEED,
  ROUTE_FILTER_FEED,
  ROUTE_SAVED_PICKS,
  ROUTE_ARCHIVE,
  ROUTE_ONBOARDING_WELCOME,
  ROUTE_ADD_SAVED_CONTENT,
  ROUTE_USER_PROFILE_PREVIEW,
  ROUTE_CHANNELS_LISTS,
  ROUTE_CUSTOM_FEEDS,
  ROUTE_CUSTOM_FEED_DETAIL,
  ROUTE_FEEDS_BY_TOPIC,
  ROUTE_FEEDS_BY_TYPE,
  ROUTE_FEED_ITEMS,
} from './routes';

const UI = withLayout(lazy(() => import('./pages/UI')));

const App: FC = () => {
  const { replace, listen } = useHistory();
  const { userId, username, token } = getAuthUser();
  const { checkCurrentPusherSession } = useContextPusher();
  const withModalBg = useGetSearchParam('withModalBg');
  const signOut = useSignOut(ROUTE_DISCOVERY);

  const routerLocation = useLocation<{ background: Location<undefined>; backgroundModal: Location<undefined> }>();
  const client = useApolloClient();
  const {
    shouldRedirectToOnboarding,
    shouldRedirectFromOnboarding,
    isQueryCalled,
    loading,
    user,
  } = useCheckOnboardingStatus();
  const { isOnboarding } = usePageType();

  const background =
    routerLocation?.state?.backgroundModal ||
    (routerLocation.pathname.includes(ROUTE_SIGNIN) && getDefaultRouteForBg(ROUTE_WELCOME));

  useEffect(() => {
    if (userId) {
      sendMessageToExtension(EMessagePrefixes.LOGIN, `Bearer ${token}`);
      checkCurrentPusherSession();
      localStorage.setItem('refId', userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    if (userId && username !== user?.username && !loading && isQueryCalled && !isOnboarding) {
      signOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQueryCalled, loading, user, userId]);

  useEffect(() => {
    if (withModalBg && username) {
      const profileRoute = transformRoute(ROUTE_USER_PROFILE, { profileName: username });
      replace({
        pathname: routerLocation.pathname,
        state: {
          backgroundModal: getDefaultRouteForBg(profileRoute),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, withModalBg]);

  useKeyboardOffset();
  useShowBottomMenu();
  useUpdateOpenAppCounter();
  useAnalytics();
  useOffPlatformSharingRedirect();

  useEffect(() => {
    return listen(({ pathname }, action) => {
      const isOnboardingVerification = pathname === ROUTE_ONBOARDING;
      const isOnboardingWelcome = pathname === ROUTE_ONBOARDING_WELCOME;

      if (action === 'POP' && (isOnboardingVerification || isOnboardingWelcome)) {
        client.clearStore().then(() => {
          localStorage.clear();
          window.location.replace(ROUTE_WELCOME);
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Providers>
      <GlobalStyle />
      <Switch location={background || routerLocation}>
        {username && <Redirect from={ROUTE_WELCOME} to={ROUTE_DISCOVERY} />}
        {username && <Redirect from={ROUTE_CHECK_EMAIL} to={ROUTE_DISCOVERY} />}
        {username && <Redirect from={ROUTE_PROFILE} to={`/@${username}`} />}
        {username && <Redirect from={ROUTE_EXIT_FORM} to={ROUTE_DISCOVERY} />}
        <Route path={ROUTE_AUTH_REDIRECT} component={AuthRedirect} />
        <Route exact path={ROUTE_LANDING} component={Landing} />
        <Route exact path={ROUTE_WELCOME} component={Welcome} />
        <Route exact path={ROUTE_EXIT_FORM} component={ExitForm} />
        <Route exact path={ROUTE_CHECK_EMAIL} render={withLayout(CheckEmailPage)} />
        <Route exact path={ROUTE_ABOUT} render={withLayout(AboutUs)} />
        <Route exact path={ROUTE_PRIVACY} render={withLayout(LegalPage)} />
        <Route exact path={ROUTE_TERMS} render={withLayout(LegalPage)} />
        <Route exact path={ROUTE_COMMUNITY} render={withLayout(LegalPage)} />
        <Route exact path={ROUTE_OFF_PLATFORM_SHARING} component={OffPlatformSharing} />
        <Route exact path={ROUTE_INVITE_USER_SHARING} component={withLayout(ShareSheet)} />
        {!username &&
          (!routerLocation.pathname.includes(ROUTE_SIGNIN) ||
            !routerLocation.pathname.includes(ROUTE_AUTH_REDIRECT)) && (
            <Redirect from={ROUTE_DISCOVERY} to={ROUTE_WELCOME} />
          )}
        {shouldRedirectFromOnboarding && <Redirect to={ROUTE_DISCOVERY} />}
        <Route
          path={ROUTE_ONBOARDING}
          render={withLayout(config.isNewOnboardingEnabled ? NewOnboarding : Onboarding)}
        />
        {shouldRedirectToOnboarding && <Redirect to={ROUTE_ONBOARDING} />}
        {!username && <Redirect to={ROUTE_LANDING} />}
        <Route exact path={ROUTE_QUESTION_OF_THE_DAY} component={withLayout(QuestionOfDay)} />
        <Route exact path={ROUTE_SPARKS} component={withLayout(Sparks)} />
        <Route exact path={ROUTE_TOPIC_DETAIL} render={withLayout(TopicDetail)} />
        <Route exact path={ROUTE_TOPIC_ITEM_DETAIL} render={withLayout(TopicItem)} />
        <Route exact path={ROUTE_DISCOVERY} render={withLayout(Explore)} />
        <Route exact path={ROUTE_CARD_DETAIL} render={withLayout(CardDetail)} />
        <Route exact path={ROUTE_LIST_DETAIL} render={withLayout(ListDetail)} />
        <Route exact path={ROUTE_LIST_ITEM} render={withLayout(ListItem)} />
        <Route path={ROUTE_SEARCH} render={withLayout(Search)} />
        <Route exact path={ROUTE_USER_PROFILE} render={withLayout(Profile)} />
        <Route path={ROUTE_UNAUTHORIZED} render={withLayout(UnAuthorized)} />
        <Route path={ROUTE_UNKNOWN_ERROR} component={UnknownError} />
        <Route exact path={ROUTE_SETTINGS} render={withLayout(Settings)} />
        <Route exact path={ROUTE_SAVED_PICKS} render={withLayout(SavedPicks)} />
        <Route exact path={ROUTE_CUSTOM_FEEDS} render={withLayout(FeedsSection)} />
        <Route exact path={ROUTE_FEEDS_BY_TOPIC} render={withLayout(FeedsSection)} />
        <Route exact path={ROUTE_FEEDS_BY_TYPE} render={withLayout(FeedsSection)} />
        <Route exact path={ROUTE_CUSTOM_FEED_DETAIL} render={withLayout(FeedDetail)} />
        <Route exact path={ROUTE_ARCHIVE} render={withLayout(Archive)} />
        <Route path={ROUTE_ACCOUNT_MANAGEMENT} render={withLayout(AccountManagement)} />
        <Route path={ROUTE_PRIVACY_AND_DATA} render={withLayout(PrivacyAndData)} />
        <Route path={ROUTE_NOTIFICATION_SETTING} render={withLayout(Notifications)} />
        <Route exact path={ROUTE_ACTIVITY} render={withLayout(Activity)} />
        <Route exact path={ROUTE_ACTIVITY_REACTIONS} render={withLayout(Reactions)} />
        <Route exact path={ROUTE_USER_ALL_PICKS} render={withLayout(AllPicks)} />
        <Route exact path={ROUTE_USER_ALL_PICKS_ITEM} render={withLayout(AllPicksItem)} />
        <Route exact path={ROUTE_USER_ALL_SPARKS} render={withLayout(AllSparks)} />
        <Route exact path={ROUTE_SPARK_DETAIL} render={withLayout(SparkDetail)} />
        <Route exact path={ROUTE_FOLLOWING_CARD_DETAIL} render={withLayout(FollowingItem)} />
        <Route exact path={ROUTE_EVERYONE_CARD_DETAIL} render={withLayout(EveryoneItem)} />
        <Route exact path={ROUTE_HELP} render={withLayout(Help)} />
        <Route exact path={ROUTE_PRIVACY_APPROACH} render={withLayout(PrivacyApproach)} />
        <Route path={ROUTE_MESSAGES} component={Messages} />
        <Route exact path={ROUTE_QUESTION_DETAIL} render={withLayout(QuestionDetail)} />
        <Route exact path={ROUTE_GUEST_LIST_DETAIL} render={withLayout(GuestList)} />
        <Route exact path={ROUTE_GUEST_LIST_SERIES} render={withLayout(GuestListSeries)} />
        <Route exact path={ROUTE_CHANNELS_LISTS} render={withLayout(ListsChannels)} />
        <Route exact path={ROUTE_FEED_ITEMS} render={withLayout(FeedItem)} />
        {process.env.NODE_ENV === 'development' && (
          <Route
            exact
            path={ROUTE_UI}
            render={() => (
              <Suspense fallback="loading UI page...">
                <UI />
              </Suspense>
            )}
          />
        )}
        <Route path="*" component={ContentNotFound} />
      </Switch>
      {background && (
        <>
          <Route component={Thoughts} path={ROUTE_CARD_DETAIL_THOUGHTS} />
          <Route path={ROUTE_USER_FOLLOWERS}>
            <Connections isFollowers />
          </Route>
          <Route path={ROUTE_USER_FOLLOWINGS}>
            <Connections />
          </Route>
          <Route component={CreateFeed} path={ROUTE_CREATE_FEED} />
          <Route component={EditFeed} path={ROUTE_EDIT_FEED} />
          <Route component={FilterFeedModal} path={ROUTE_FILTER_FEED} />
          <Route component={AddContent} path={ROUTE_ADD_CONTENT} />
          <Route component={AddSavedContentRoutes} path={ROUTE_ADD_SAVED_CONTENT} />
          <Route component={AddAnswer} path={ROUTE_ADD_ANSWER} />
          <Route component={AddResponseToSpark} path={ROUTE_SPARK_RESP_ADD_PICK} />
          <Route component={ProfileSettings} path={ROUTE_PROFILE_SETTINGS} />
          <Route component={MagicLink} exact path={ROUTE_SIGNIN} />
          <Route component={ListSettings} path={ROUTE_LIST_EDIT} />
          <Route component={ChangeLayout} path={ROUTE_USER_ALL_PICKS_LAYOUT} />
          <Route component={InviteModal} path={ROUTE_LIST_INVITE} />
          <Route component={TopicsModal} path={ROUTE_USER_TOPICS} />
          <Route component={Report} path={ROUTE_REPORT} />
          <Route component={ChatSettingsModal} path={ROUTE_MESSAGES_SETTINGS} />
          <Route component={SelectChatModal} path={ROUTE_SELECT_CHAT_MODAL} />
          <Route component={ProfilePreviewModal} path={ROUTE_USER_PROFILE_PREVIEW} />
        </>
      )}
      <GuideTour />
    </Providers>
  );
};

export default App;
